import styled from "@emotion/styled";
import Modal from "@mui/material/Modal";

const StyledModal = styled(Modal)`
  z-index: 1410;
`;
/** @param {import("@mui/material/Modal").ModalProps} props */
export default function PalModal({ children, ...rest }) {
  return <StyledModal {...rest}>{children}</StyledModal>;
}
