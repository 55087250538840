export default class CryptoHelper {
  // static async generateAndPrint() {
  //   const key = await CryptoHelper.generateKey();
  //   console.log("aes-key", await CryptoHelper.exportKey(key));
  // }
  static async ensureCryptoSubtleReady() {
    return new Promise((resolve, reject) => {
      const checkInterval = 50;
      const maxWaitTime = 1000;
      let elapsedTime = 0;

      function checkCryptoSubtle() {
        if (window.crypto && window.crypto.subtle) {
          resolve(true);
        } else if (elapsedTime >= maxWaitTime) {
          console.warn("CryptoSubtle is not available");
          resolve(false);
        } else {
          elapsedTime += checkInterval;
          setTimeout(checkCryptoSubtle, checkInterval);
        }
      }

      checkCryptoSubtle();
    });
  }
  static async encrypt(stringData) {
    if (!(await CryptoHelper.ensureCryptoSubtleReady())) return "";

    const iv = crypto.getRandomValues(new Uint8Array(12));
    const key = await CryptoHelper.getKey();

    const encoder = new TextEncoder();
    const encodedData = encoder.encode(stringData);

    const encryptedData = await window.crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      key,
      encodedData
    );
    return `${this.arrayBufferToBase64(iv)}:${this.arrayBufferToBase64(encryptedData)}`;
  }

  static async decrypt(encryptedObject = "") {
    const [iv, encrypted] = encryptedObject.split(":");
    if (!iv || !encrypted) {
      console.warn("Invalid encrypted object", encryptedObject);
      return "";
    }
    if (!(await CryptoHelper.ensureCryptoSubtleReady())) return "";
    const key = await CryptoHelper.getKey();

    const decryptedData = await window.crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv: this.base64ToArrayBuffer(iv),
      },
      key,
      this.base64ToArrayBuffer(encrypted)
    );

    const decoder = new TextDecoder();
    return decoder.decode(decryptedData);
  }

  static async generateKey() {
    const key = await window.crypto.subtle.generateKey(
      {
        name: "AES-GCM",
        length: 256,
      },
      true,
      ["encrypt", "decrypt"]
    );

    return key;
  }

  static async getKey() {
    const keyStr = window.pr_config?.appKey;
    if (!window?.crypto?.subtle?.importKey) {
      console.warn("window.crypto.subtle.importKey is not available");
      return null;
    }
    if (!keyStr) {
      console.warn("AES KEY is not available");
      return null;
    }
    const jwkKey = JSON.parse(atob(keyStr));
    return await window.crypto.subtle.importKey("jwk", jwkKey, { name: "AES-GCM", length: 256 }, true, jwkKey.key_ops);
  }

  static arrayBufferToBase64(buffer) {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)));
  }

  static base64ToArrayBuffer(base64) {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }
  static async exportKey(key) {
    const exportedKey = await window.crypto.subtle.exportKey("jwk", key);

    return btoa(JSON.stringify(exportedKey));
  }

  static async calculateHash(input) {
    let inputString = input;
    try {
      if (typeof input === "object") {
        inputString = JSON.stringify(input);
      }

      const encoder = new TextEncoder();
      const data = encoder.encode(inputString);

      const hashBuffer = await crypto.subtle.digest("SHA-256", data);

      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
      return hashHex;
    } catch (error) {
      return CryptoHelper.simpleHash(inputString);
    }
  }

  static simpleHash(inputString) {
    let hash = 0;
    for (let i = 0; i < inputString.length; i++) {
      const char = inputString.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }
    return hash.toString();
  }
}
