import ConstantHelper from "~helpers/ConstantHelper";

export const [intentRecommendType, intentRecommendTypeOptions, intentRecommendTypeMap] = ConstantHelper.typify({
  SAMPLE: "Sample",
  ADD_ANSWER: "Add Answer",
  INTEGRATION: "Integration",
  STATIC: "Static",
  AGENT_FAILS: "Agent Fails",
});

export const [implementationStatus, implementationStatusOptions, implementationStatusMap] = ConstantHelper.typify({
  IMPLEMENTED: "Implemented",
  REJECTED: "Rejected",
  NONE: "None",
});

export const [clusterType, clusterTypeOptions, clusterTypeMap] = ConstantHelper.typify({
  BOT_UNHELPFUL: "Bot Unhelpful",
});
