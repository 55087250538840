import "./init.js";

import React from "react";

import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import IFrameHelper from "~helpers/IFrameHelper";
import ManifestHelper from "~helpers/ManifestHelper.js";

import RenderWrapper from "./components/RenderWrapper";
import store from "./store";

const App = React.lazy(() => import("./App"));
const WebChat = React.lazy(() => import("~components/WebChat"));

const isWebChatMode = IFrameHelper.isWebChatMode();

if (!isWebChatMode) {
  ManifestHelper.ApplyDefaultManifest();
}

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <RenderWrapper>{isWebChatMode ? <WebChat /> : <App />}</RenderWrapper>
  </Provider>
);
if (isWebChatMode) {
  const rootDom = document.getElementById("root");
  rootDom.removeAttribute("style");
  document.documentElement.classList.add("pr-webchat");
}
document.documentElement.setAttribute("translate", "no");
