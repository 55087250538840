// export const [lowCodeModuleType, lowCodeModuleTypeOptions, lowCodeModuleTypeMap] = ConstantHelper.typify({
//   CHATBOT_MODULES: "Chatbot Module",
//   INTEGRATION_MODULES: "Integration Module",
//   SINGLE_RUN_MODULES: "Single Run Module",
//   STATISTICS_MODULES: "Statistics Module",
// });

export const lowCodeModuleType = {
  CHATBOT_MODULES: 0,
  INTEGRATION_MODULES: 1,
  SINGLE_RUN_MODULES: 2,
  STATISTICS_MODULES: 3,
};
