import { combineReducers } from "redux";

import chatbot from "./chatbot/reducer";
import livechat from "./livechat/reducer";

const socketReducer = combineReducers({
  chatbot,
  livechat,
});

export default socketReducer;
