import cookie from "cookie";
import i18n from "i18next";
import i18next from "i18next";

import {
  LS_WEBCHAT_COOKIE_ALIAS,
  LS_WEBCHAT_TOKEN,
  LS_WEBCHAT_TOKEN_EXPIRE,
  apiUrlWebchat,
  popupIconType,
  popupSettingsMapper,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import IFrameHelper from "~helpers/IFrameHelper";
import ManifestHelper from "~helpers/ManifestHelper";
import Network from "~helpers/Network";
import StorageHelper from "~helpers/StorageHelper";
import TokenHelper from "~helpers/TokenHelper";
import Utils from "~helpers/Utils";

import * as at from "./actionTypes";
import { selectOnlineStatus } from "./selectors";

/**
 * @param {typeof import("./reducer").initState.messages[0]} payload - Message
 * @returns {import("redux").AnyAction} Action
 */
export const appendMessage = (payload) => ({
  type: at.APPEND_MESSAGE,
  payload,
});
export const appendRawMessage = (payload) => ({
  type: at.APPEND_RAW_MESSAGE,
  payload,
});
export const appendMessageBegin = (payload) => ({
  type: at.APPEND_MESSAGE_BEGIN,
  payload,
});
export const updateMessage = (old, newly) => ({
  type: at.UPDATE_MESSAGE,
  payload: {
    old,
    newly,
  },
});

export const onBroadcastMessage = (payload) => ({
  type: at.ON_BROADCAST_MESSAGE,
  payload,
});

export const setIsLogged = (payload) => ({
  type: at.SET_IS_LOGGED,
  payload,
});
export const appendNotification = (payload) => ({
  type: at.APPEND_NOTIFICATION,
  payload,
});

export const setConnectionOptions = (payload) => ({
  type: at.SET_CONNECTION_OPTIONS,
  payload,
});

export const setInteractionStatus = (payload) => ({
  type: at.SET_INTERACTION_STATUS,
  payload,
});

export const setRemoteInteractionStatus = (payload) => ({
  type: at.SET_REMOTE_INTERACTION_STATUS,
  payload,
});
export const changeSender = (payload) => ({
  type: at.CHANGE_SENDER,
  payload,
});
export const changeSenderData = (payload) => ({
  type: at.CHANGE_SENDER_DATA,
  payload,
});

export const setLastMessageTime = (payload) => ({
  type: at.SET_LAST_MESSAGE_TIME,
  payload,
});

// export const setBotInfo = (payload) => ({
//   type: at.SET_CHATBOT_INFO,
//   payload,
// });

// export const setAgentInfo = (payload) => ({
//   type: at.SET_AGENT_INFO,
//   payload,
// });

// export const setAgentStatus = (payload) => ({
//   type: at.SET_AGENT_STATUS,
//   payload,
// });

export const setQueueStatus = (payload) => ({
  type: at.SET_QUEUE_STATUS,
  payload,
});

export const setTicketForm = (payload) => ({
  type: at.SET_TICKET_FORM,
  payload,
});

export const clearTicketForm = () => ({
  type: at.CLEAR_TICKET_FORM,
});

export const setTimeoutStatus = (payload) => ({
  type: at.SET_TIMEOUT_STATUS,
  payload,
});

export const setPong = (payload) => ({
  type: at.SET_PONG,
  payload,
});

/**
 * @param {typeof import("./reducer.js").initState.onlineStatus} payload
 * @returns
 */
export const setOnlineStatus = (payload) => ({
  type: at.SET_ONLINE_STATUS,
  payload,
});

/**
 * @param {typeof import("./reducer.js").initState.viewMode} payload - View mode
 * @returns {import("redux").AnyAction} Action
 */
export const setViewMode = (payload) => ({
  type: at.SET_VIEWMODE,
  payload,
});

/**
 * @param {typeof import("./reducer.js").initState.viewMode} payload - View mode
 * @returns {import("redux").AnyAction} Action
 */
export const setViewModeDelayed = (payload) => ({
  type: at.SET_VIEWMODE_DELAYED,
  payload,
});

export const setQueryOptions = (payload) => ({
  type: at.SET_QUERY_OPTIONS,
  payload,
});

export const setMessageList = (payload) => ({
  type: at.SET_MESSAGE_LIST,
  payload,
});
/**
 * @param {typeof import("./reducer.js").initState.sessionStatus} payload - Session status
 * @returns {import("redux").Action} Action
 */
export const setSessionStatus = (payload) => {
  console.log("setSessionStatus", payload);
  return {
    type: at.SET_SESSION_STATUS,
    payload,
  };
};

export const setWelcomeOptions = (payload) => ({
  type: at.SET_WELCOME_OPTIONS,
  payload,
});

export const setMaintenanceStatus = (payload) => ({
  type: at.SET_MAINTENANCE_STATUS,
  payload,
});

export const setSubmitValidationResult = (payload) => ({
  type: at.SET_SUBMIT_VALIDATION_RESULT,
  payload,
});

export const setButtonSelectLoading = (payload) => ({
  type: at.SET_BUTTON_SELECT_LOADING,
  payload,
});
export const setSeenMessageInfo = (payload) => ({
  type: at.SET_SEEN_MESSAGE_INFO,
  payload,
});
export const setInitialized = (payload) => ({
  type: at.SET_INITIALIZED,
  payload,
});
export const setInteractionModal = (payload) => ({
  type: at.SET_INTERACTION_MODAL,
  payload,
});

export const sendDomMessageAction = (payload) => ({
  type: at.DOM_MSG_ACTION,
  payload,
});

export const getWelcomeOptions = (token) => (dispatch, _getState) => {
  const alias = TokenHelper.getChatbotAlias();
  let url = apiUrlWebchat.welcomeForm;
  if (alias) {
    url = apiUrlWebchat.welcomeFormAlias.format(alias);
  }

  //get disable_maintenance from url search
  const urlParams = new URLSearchParams(window?.location?.search);
  const disableMaintenance = urlParams.get("disable_maintenance");
  return Network.request(url, {
    ...(alias
      ? {}
      : {
          params: {
            token,
          },
        }),
    loading: true,
    noBearer: true,
    withCredentials: false,
    onSuccess: async (rawData) => {
      const data = popupSettingsMapper(rawData);

      IFrameHelper.setPropertyToRoot([
        ["--webchat-icon-margin-x", `${data.popup_style_info.popup_margin_x}px`],
        ["--webchat-icon-margin-y", `${data.popup_style_info.popup_margin_y}px`],
        ["--webchat-icon-size", `${data.popup_style_info.popup_icon_size}px`],
        ["--webchat-height", `${data.popup_style_info.chat_height}px`],
      ]);

      if (data.maintenance && !disableMaintenance) {
        const palmateTkn = TokenHelper.getWebchatProjectToken();

        await StorageHelper.remove(LS_WEBCHAT_TOKEN.format(palmateTkn || alias));
        await StorageHelper.remove(LS_WEBCHAT_TOKEN_EXPIRE.format(palmateTkn || alias));
        dispatch(setMaintenanceStatus(true));
        dispatch(setViewMode("maintenance"));
      } else {
        if (data.popup_button_icon_path && data?.popup_style_info.popup_icon_type !== popupIconType.lottie) {
          try {
            const corsSafeOrigin = Utils.replaceOrigin(data.popup_button_icon_path);
            const response = await fetch(corsSafeOrigin);
            if (response.ok) {
              const blob = await response.blob();
              const url = URL.createObjectURL(blob);
              data.popup_button_icon_path = url;
            }
          } catch (error) {
            console.error("Popup image prefetch error", error);
          }
        }

        const defaultLanguage = [...(data?.languages || [])].find((lang) => lang?.default)?.code;

        if (defaultLanguage) {
          i18next.changeLanguage(defaultLanguage.toLowerCase());
        }
        dispatch(setViewMode(data?.start_anonymous_chat ? "chat" : "welcome"));
        ManifestHelper.ApplyDynamicManifest(token, data);
      }

      dispatch(setWelcomeOptions(data));
    },
    onFail: async () => {
      if (!disableMaintenance) {
        const palmateTkn = TokenHelper.getWebchatProjectToken();

        await StorageHelper.remove(LS_WEBCHAT_TOKEN.format(palmateTkn || alias));
        await StorageHelper.remove(LS_WEBCHAT_TOKEN_EXPIRE.format(palmateTkn || alias));
        dispatch(setMaintenanceStatus(true));
        dispatch(setViewMode("maintenance"));
        //Dummy welcome options to show maintenance page

        const defaultOptions = {
          // form_items: [
          //   {
          //     form_label: "Name",
          //     form_key: "full_name",
          //     form_item_type: "AN",
          //     form_item_order: 1,
          //     is_identifier: false,
          //   },
          //   {
          //     form_label: "Email",
          //     form_key: "email",
          //     form_item_type: "EMA",
          //     form_item_order: 2,
          //     is_identifier: true,
          //   },
          // ],

          show_queue_order: true,
          show_thumps_up: true,
          popup_title: "Chatbot",
          popup_primary_color: "#28aae1",
          popup_button_type: "DF",
          popup_button_icon_path: null,
          popup_header_icon_type: "DF",
          popup_header_icon_path: null,
          popup_bot_icon_type: "DF",
          popup_bot_icon_path: "",
          welcome_msg: "",
          popup_placeholder: "Merhaba, nasıl yardımcı olabilirim?",
          popup_baloon_text: [],
          popup_welcome_text: "Sohbete hoş geldiniz! Sohbete başlamadan önce lütfen aşağıdaki formu doldurun.",
          start_anonymous_chat: true,
          popup_style_info: {},
          maintenance: true,
          languages: [
            {
              code: "TR",
              name: "Türkçe",
              default: true,
            },
            {
              code: "EN",
              name: "English (İngilizce)",
              default: false,
            },
          ],
          static_messages: [],
        };
        const mappedOptions = popupSettingsMapper(defaultOptions);
        IFrameHelper.setPropertyToRoot([
          ["--webchat-icon-margin-x", `${mappedOptions.popup_style_info.popup_margin_x}px`],
          ["--webchat-icon-margin-y", `${mappedOptions.popup_style_info.popup_margin_y}px`],
          ["--webchat-icon-size", `${mappedOptions.popup_style_info.popup_icon_size}px`],
          ["--webchat-height", `${mappedOptions.popup_style_info.chat_height}px`],
        ]);
        dispatch(setWelcomeOptions(mappedOptions));
      }
    },
  });
};

export const resumeChatSession = () => ({
  type: at.RESUME_CHAT_SESSION,
});

export const startChatSession = (payload = {}, reason = "") => ({
  type: at.START_CHAT_SESSION,
  payload: { ...payload, reason },
});

export const setWelcomeFormData = (payload) => ({
  type: at.SET_WELCOME_FORM_DATA,
  payload,
});

/** @param {"mobile" | "desktop"} payload - Display mode */
export const setDisplayMode = (payload) => ({
  type: at.SET_DISPLAY_MODE,
  payload,
});

export const sendChatHistoryToEmail = (email, sessionToken, captcha) => (_dispatch, _getState) => {
  const url = apiUrlWebchat.sendHistory;
  return Network.request(url, {
    method: "POST",
    data: {
      email,
      session_token: sessionToken,
      captcha,
    },
    loading: true,
  });
};

export const getTokenAndStartChatSession =
  (projectToken, payload, data = {}) =>
  async (dispatch, getState) => {
    dispatch(setMessageList([]));

    const alias = TokenHelper.getChatbotAlias();
    let url = apiUrlWebchat.newSession;
    if (alias) {
      url = apiUrlWebchat.newSessionAlias.format(alias);
    }
    const { session_type: _, customer_info: __, project_token: ___, ...restPayloadData } = data;

    const getAliasBasedCookies = async () => {
      const targetPrefix = LS_WEBCHAT_COOKIE_ALIAS.format(projectToken || alias);
      //get all cookies key value pairs if key starts with targetPrefix
      const cookies = await IFrameHelper.getCookies();
      const parsedCookies = cookie.parse(cookies);
      const filteredCookies = Object.keys(parsedCookies)
        .filter((key) => key.startsWith(targetPrefix))
        .reduce((obj, key) => {
          obj[key.replace(targetPrefix, "")] = parsedCookies[key];
          return obj;
        }, {});
      return filteredCookies;
    };

    const cookieParams = await getAliasBasedCookies();
    let requestData = {
      session_type: "RTM",
      customer_info: {
        ...payload,
        ...restPayloadData,
        ...cookieParams,
      },
      ...(alias ? {} : { project_token: projectToken }),
    };
    let headers = {};

    const { executed, data: eventData } = await IFrameHelper.execEvent("onBeforeAuth", {
      request: requestData,
      headers,
    });

    if (executed) {
      if (eventData?.headers) {
        headers = {
          ...eventData.headers,
        };
      }
      if (eventData?.request) {
        requestData = {
          ...eventData.request,
          ...requestData,
          customer_info: {
            ...(eventData.request?.customer_info || {}),
            ...requestData.customer_info,
          },
        };
      }
    }

    return await Network.request(url, {
      method: "POST",
      noBearer: true,
      data: requestData,
      onSuccess: (data) => {
        // dispatch(setSessionStatus("connecting"));
        console.log("getTokenAndStartChatSession START_CHAT_SESSION");
        return startChatSession(data, "token_and_start");
      },
      loading: false,
      headers,
    }).catch((e) => {
      const onlineStatus = selectOnlineStatus(getState());
      const axiosResponseCode = e?.response?.status;
      if (onlineStatus !== "disconnected" && axiosResponseCode !== 400) {
        AlertHelper.show(i18n.t("chatbot.getInformations.networkError"), "error");
      }
    });
  };
export const uploadFile = (sessionToken, file) => (_dispatch, _getState) => {
  const form = new FormData();
  form.append("file", file);
  form.append("session_token", sessionToken);

  const alias = TokenHelper.getChatbotAlias();
  let url = apiUrlWebchat.newFile;
  if (alias) {
    url = apiUrlWebchat.newFileAlias.format(alias);
  }

  return Network.request(url, {
    method: "POST",
    data: form,
  });
};
export const setChatDisabledStatus = (payload) => ({
  type: at.SET_CHAT_DISABLED_STATUS,
  payload,
});

export const setMinimizedStatus = (minimized) => ({
  type: at.SET_MINIMIZED_STATUS,
  payload: minimized,
});
export const setMinimizedDoneStatus = (minimized) => ({
  type: at.SET_MINIMIZED_DONE_STATUS,
  payload: minimized,
});

export const setWelcomeMsgShowedStatus = (status) => ({
  type: at.SET_WELCOME_MSG_SHOWED_STATUS,
  payload: status,
});

export const setLastOutgoingMsg = (payload) => ({
  type: at.SET_LAST_OUTGOING_MSG,
  payload,
});

export const setFeedbackInfo = (payload) => ({
  type: at.SET_FEEDBACK_INFO,
  payload,
});

export const setDropzoneAllowStatus = (payload) => ({
  type: at.SET_DROPZONE_ALLOW_STATUS,
  payload,
});
export const setDropzoneFiles = (payload) => ({
  type: at.SET_DROPZONE_FILES,
  payload,
});
export const setDropzoneRef = (payload) => ({
  type: at.SET_DROPZONE_REF,
  payload,
});

export const disconnectRequest = (source) => ({
  type: at.DISCONNECT_REQUEST,
  payload: source,
});

// #region Websocket Communication
export const setSocketInstance = (payload) => ({
  type: at.SET_SOCKET_INSTANCE,
  payload,
});

export const wsConnect = (reason) => ({
  type: at.WS_CONNECT,
  payload: reason,
});
export const wsConnectSuccess = (instance) => ({
  type: at.WS_CONNECT_SUCCESS,
  payload: instance,
});
export const wsConnectFail = () => ({
  type: at.WS_CONNECT_FAIL,
});
export const wsDisconnect = () => ({
  type: at.WS_DISCONNECT,
});

export const wsActionChannelSent = (payload) => ({
  type: at.WS_MSG_SENT,
  payload,
});

export const wsActionChannelReceived = (payload) => ({
  type: at.WS_MSG_RECEIVED,
  payload,
});

// export const wsLogin = (token, uuid) => ({
//   type: at.WS_LOGIN,
//   payload: {
//     token,
//     uuid,
//   },
// });
export const disconnectDone = () => ({
  type: at.DISCONNECT_DONE,
});

/**
 * @param {typeof import("./reducer.js").initState.inputRequestInfo} payload - Session status
 * @returns {import("redux").Action} Action
 */
export const setInputRequestInfo = (payload) => ({
  type: at.SET_INPUT_REQUEST_INFO,
  payload,
});
export const wsLoginSuccess = () => ({
  type: at.WS_LOGIN_SUCCESS,
});
export const wsLoginFail = () => ({
  type: at.WS_LOGIN_FAIL,
});
export const wsOut = {
  login: (token, metadata, queryOptions) => ({
    type: at.WS_OUT_LOGIN,
    payload: {
      type: "login",
      subscribe_type: "all",
      payload: {
        token,
        metadata,
      },
      query_options: queryOptions,
    },
  }),

  ping: () => ({
    type: at.WS_OUT_PING,
    payload: {
      type: "ping",
    },
  }),

  interactionStatus: ({ focused, typing, location = "", time } = {}) => {
    return {
      type: at.WS_OUT_INTERACTION_STATUS,
      payload: {
        type: "interaction_status",
        payload: {
          focused,
          typing,
          location,
          time,
        },
      },
    };
  },
  /**
   * @param {any} data
   * @param {{
   *   type: "text" | "choice" | "file" | "image";
   *   format: "plain" | "html";
   *   choice_value: string;
   *   files: string[];
   * }} param1
   */
  message: (data, { type = "text", name, format = "plain", choice_value, files = [] } = {}) => {
    const msgType = type.toUpperCase();
    return {
      type: at.WS_OUT_MESSAGE,
      payload: {
        type: "message",
        payload: {
          type: msgType,
          format,
          choice_value,
          files,
          ...(["image", "file"].includes(type)
            ? {
                //TODO: Check which 'name' is correct and remove duplicate key variant
                url: data,
                name,
                file_name: name,
              }
            : {
                text: data,
              }),
        },
      },
    };
  },
  action: {
    returnToHome: () => ({
      type: at.WS_OUT_ACTION_RETURN_TO_HOME,
      payload: {
        type: "action",
        payload: {
          type: "return_to_home",
          payload: {},
        },
      },
    }),
    cancelTicket: () => ({
      type: at.WS_OUT_ACTION_CANCEL_TICKET,
      payload: {
        type: "action",
        payload: {
          type: "cancel_ticket",
          payload: {},
        },
      },
    }),
    cancelQueue: () => ({
      type: at.WS_OUT_ACTION_CANCEL_QUEUE,
      payload: {
        type: "action",
        payload: {
          type: "cancel_queue",
          payload: {},
        },
      },
    }),
    terminateSession: () => ({
      type: at.WS_OUT_ACTION_TERMINATE_SESSION,
      payload: {
        type: "action",
        payload: {
          type: "terminate_session",
          payload: {},
        },
      },
    }),
    startIntent: (id) => ({
      type: at.WS_OUT_ACTION_START_INTENT,
      payload: {
        type: "action",
        payload: {
          type: "start_intent",
          payload: {
            id,
          },
        },
      },
    }),
    sendTransaction: (email) => ({
      type: at.WS_OUT_ACTION_SEND_TRANSACTION,
      payload: {
        type: "action",
        payload: {
          type: "send_transaction",
          payload: {
            email,
          },
        },
      },
    }),
    buttonSelect: (buttonValue) => ({
      type: at.WS_OUT_ACTION_BUTTON_SELECT,
      payload: {
        type: "action",
        payload: {
          type: "button_select",
          payload: {
            value: buttonValue,
          },
        },
      },
    }),
    submitTicket: (payload) => ({
      type: at.WS_OUT_ACTION_SUBMIT_TICKET,
      payload: {
        type: "action",
        payload: {
          type: "submit_ticket",
          payload,
        },
      },
    }),
    redirectToTicket: () => ({
      type: at.WS_OUT_ACTION_SUBMIT_TICKET,
      payload: {
        type: "action",
        payload: {
          type: "direct_to_ticket",
        },
      },
    }),
    /**
     * @typedef {{
     *   id: number;
     *   value: number | string;
     * }} AnswerItem
     * @param {AnswerItem[]} answers
     */
    submitFeedback: (answers) => ({
      type: at.WS_OUT_ACTION_SUBMIT_FEEDBACK,
      payload: {
        type: "action",
        payload: {
          type: "submit_feedback",
          payload: {
            /** @type {AnswerItem[]} */
            answers,
          },
        },
      },
    }),
    /** @typedef {string[]} InputRequestDropdownData */
    /** @typedef {number[]} InputRequestFileData */
    /** @typedef {string} InputRequestDateData */
    /** @typedef {InputRequestDateData[]} InputRequestDateRangeData */
    /**
     * @typedef {| InputRequestDropdownData
     *   | InputRequestFileData
     *   | InputRequestDateData
     *   | InputRequestDateRangeData
     *   | number} InputRequestFormValue
     */
    /**
     * @typedef {{
     *   id: number;
     *   value: InputRequestFormValue;
     * }[]} InputRequestFormData
     */
    /**
     * @param {| InputRequestFormData
     *   | InputRequestDropdownData
     *   | InputRequestFileData
     *   | InputRequestDateData
     *   | InputRequestDateRangeData} data
     */
    submitInputRequest: (data) => ({
      type: at.WS_OUT_ACTION_SUBMIT_INPUT_REQUEST,
      payload: {
        type: "action",
        payload: {
          type: "submit_input_request",
          payload: { data },
        },
      },
    }),

    /**
     * @param {number} messageId
     * @param {1 | 2 | 3 | 4} value
     */
    submitMessageFeedback: (messageId, value) => ({
      type: at.WS_OUT_ACTION_SUBMIT_MESSAGE_FEEDBACK,
      payload: {
        type: "action",
        payload: {
          type: "submit_message_feedback",
          payload: {
            message_id: messageId,
            value, // 1,2,3,4,5
          },
        },
      },
    }),
    submitValidation: (requestId, inputData, parameters = {}) => ({
      type: at.WS_OUT_ACTION_SUBMIT_VALIDATION,
      payload: {
        type: "action",
        payload: {
          type: "validate_input",
          payload: {
            request_id: requestId,
            input_data: inputData,
            parameters: parameters,
          },
        },
      },
    }),
    changeLanguage: (lang) => ({
      type: at.WS_OUT_ACTION_SUBMIT_VALIDATION,
      payload: {
        type: "action",
        payload: {
          type: "change_language",
          payload: {
            language: lang, //EN
          },
        },
      },
    }),
  },
};

export const wsIn = {
  notification: (payload) => ({
    type: at.WS_IN_NOTIFICATION,
    payload,
  }),
  message: (payload) => ({
    type: at.WS_IN_MESSAGE,
    payload,
  }),
  pong: (payload) => ({
    type: at.WS_IN_PONG,
    payload,
  }),
  queue: (payload) => ({
    type: at.WS_IN_QUEUE,
    payload,
  }),
  history: (payload) => ({
    type: at.WS_IN_HISTORY,
    payload,
  }),
  interactionStatus: (payload) => ({
    type: at.WS_IN_INTERACTION_STATUS,
    payload,
  }),
  action: {
    botConnect: (payload) => ({
      type: at.WS_IN_ACTION_BOT_CONNECT,
      payload,
    }),
    botDisconnect: (payload) => ({
      type: at.WS_IN_ACTION_BOT_DISCONNECT,
      payload,
    }),
    agentConnect: (payload) => ({
      type: at.WS_IN_ACTION_AGENT_CONNECT,
      payload,
    }),
    agentDisconnect: (payload) => ({
      type: at.WS_IN_ACTION_AGENT_DISCONNECT,
      payload,
    }),
    terminateSession: (payload) => ({
      type: at.WS_IN_ACTION_TERMINATE_SESSION,
      payload,
    }),
    inactivityTimeout: (payload) => ({
      type: at.WS_IN_ACTION_INACTIVITY_TIMEOUT,
      payload,
    }),
    redirectToTicket: (payload) => ({
      type: at.WS_IN_ACTION_REDIRECT_TO_TICKET,
      payload,
    }),
    redirectToQueue: (payload) => ({
      type: at.WS_IN_ACTION_REDIRECT_TO_QUEUE,
      payload,
    }),
    redirectToFeedback: (payload) => ({
      type: at.WS_IN_ACTION_REDIRECT_TO_FEEDBACK,
      payload,
    }),
    // directToOffline: (payload) => ({
    //   type: at.WS_IN_ACTION_DIRECT_TO_OFFLINE,
    //   payload,
    // }),
    messageFeedback: (payload) => ({
      type: at.WS_IN_ACTION_MESSAGE_FEEDBACK,
      payload,
    }),
    submitMessageFeedback: (payload) => ({
      type: at.WS_IN_ACTION_SUBMIT_MESSAGE_FEEDBACK,
      payload,
    }),
    inputRequest: (payload) => ({
      type: at.WS_IN_ACTION_INPUT_REQUEST,
      payload,
    }),
    submitInputRequest: (payload) => ({
      type: at.WS_IN_ACTION_SUBMIT_INPUT_REQUEST,
      payload,
    }),
    submitTicket: (payload) => ({
      type: at.WS_IN_ACTION_SUBMIT_TICKET,
      payload,
    }),
    submitFeedback: (payload) => ({
      type: at.WS_IN_ACTION_SUBMIT_FEEDBACK,
      payload,
    }),
    buttonSelect: (payload) => ({
      type: at.WS_IN_ACTION_BUTTON_SELECT,
      payload,
    }),
    returnToHome: (payload) => ({
      type: at.WS_IN_ACTION_RETURN_TO_HOME,
      payload,
    }),
    cancelTicket: (payload) => ({
      type: at.WS_IN_ACTION_CANCEL_TICKET,
      payload,
    }),
    cancelQueue: (payload) => ({
      type: at.WS_IN_ACTION_CANCEL_QUEUE,
      payload,
    }),
    submitValidation: (payload) => ({
      type: at.WS_IN_ACTION_SUBMIT_VALIDATION,
      payload,
    }),
    submitValidationResult: (payload) => ({
      type: at.WS_IN_ACTION_SUBMIT_VALIDATION_RESULT,
      payload,
    }),
    changeLanguage: (payload) => ({
      type: at.WS_IN_ACTION_CHANGE_LANGUAGE,
      payload,
    }),
    transferAgent: (payload) => ({
      type: at.WS_IN_ACTION_TRANSFER_AGENT,
      payload,
    }),
  },
};

// #endregion
