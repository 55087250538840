import * as Sentry from "@sentry/react";
import { call, put, select } from "redux-saga/effects";

import { audioList, chatbotLivechatPageSize, chatbotMainSessionStatusList, chatbotSessionStatus } from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import AudioHelper from "~helpers/AudioHelper";
import DateHelper from "~helpers/DateHelper";
import Utils from "~helpers/Utils";

import {
  appendMessageToSession,
  sendListenAction,
  setAgentInfo,
  setConnectionOptions,
  setPong,
  setLivechatLoadingStatus,
  setOtherSessionsInfo,
  setSelectedSession,
  setSessions,
  updateInteractionStatus,
  updateSessionStatus,
  wsLoginFail,
  wsLoginSuccess,
  setSessionCountDelta,
} from "../actions";
import { throttleReloadOtherSessions } from "../saga";
import {
  selectAgentInfo,
  selectLivechatOptions,
  selectOtherSessionsInfo,
  selectSelectedSession,
  selectSessions,
} from "../selectors";

// eslint-disable-next-line
import { convertSocketMsgToChatMessage } from "~components/Session/SessionUtils.js";

function historyToMessages(messageList = []) {
  return messageList.map((message) => {
    return {
      type: message.history_type?.toLowerCase(),
      time: message.created,
      payload: message.history_object,
      historyId: message.history_id,
    };
  });
}
function* handleSessionStatus({ reason, ...rest }) {
  const livechatOptions = yield select(selectLivechatOptions);
  if (reason === "new_session") {
    const { count, page } = yield select(selectOtherSessionsInfo);
    const safePage = Math.min(page, Math.ceil(count / chatbotLivechatPageSize));
    yield put(setOtherSessionsInfo({ count: count + 1 }));
    // If the page is 1, add the new session to the top of the list
    if (safePage === 1) {
      const { id, status } = rest;
      const sessions = yield select(selectSessions);
      const nonMainSessions = sessions.filter(
        (session) => !chatbotMainSessionStatusList.includes(session.session_status)
      );

      const removeIdList = [];

      const existingSession = sessions.find((session) => session.id === id);
      const newSessions = [...sessions].filter((session) => session.id !== id);
      if (nonMainSessions.length >= 10) {
        const toBeRemoveSession = newSessions.pop();
        removeIdList.push(toBeRemoveSession.id);
      }
      newSessions.unshift({
        ...existingSession,
        ...rest,
        session_status: status,
        messages: [],
      });
      yield put(setSessions(newSessions));
      yield put(sendListenAction(id, "status_only"));
      if (removeIdList?.length) {
        yield put(sendListenAction(removeIdList, "offline"));
      }
    }
  } else if (reason === "session_status") {
    const { id, status, last_status } = rest;
    const sessions = yield select(selectSessions);
    const selectedSession = yield select(selectSelectedSession);
    let matchedSession = sessions.find((session) => session.id === id);

    if (matchedSession) {
      yield put(
        updateSessionStatus(id, {
          session_status: status,
          last_status: last_status,
        })
      );

      if (selectedSession?.id === id) {
        if (matchedSession.listen_type !== "all") {
          yield put(sendListenAction(id, "all"));
        }
      }
      // Agent waiting must be listen as "all"
      else if ([chatbotSessionStatus.AGENT_WAIT, chatbotSessionStatus.BOT_CHAT_AGENT_WAIT].includes(status)) {
        if (matchedSession.listen_type !== "all") {
          yield put(sendListenAction(id, "all"));
        }
      } else if (
        //when agent waiting status changed to agent chat with bot, listen as "status_only"
        [chatbotSessionStatus.AGENT_WAIT, chatbotSessionStatus.BOT_CHAT_AGENT_WAIT].includes(last_status) &&
        ![chatbotSessionStatus.AGENT_WAIT, chatbotSessionStatus.BOT_CHAT_AGENT_WAIT].includes(status) &&
        chatbotSessionStatus.AGENT_CHAT !== status
      ) {
        if (matchedSession.listen_type !== "status_only") {
          const selectedSession = yield select(selectSelectedSession);
          if (selectedSession?.id !== id) {
            yield put(sendListenAction(id, "status_only"));
          }
        }
      }
    }

    const waitingTypes = [chatbotSessionStatus.AGENT_WAIT, chatbotSessionStatus.BOT_CHAT_AGENT_WAIT];
    if (waitingTypes.includes(status) && !waitingTypes.includes(last_status) && livechatOptions.alert) {
      AudioHelper.get(audioList.agentWaitingAlert).then((audio) => audio.replay());
    }
  } else if (reason === "new_listen") {
    const {
      history: historyProp,
      history_count,
      id,
      customer,
      platform,
      queue_join_time,
      session_status,
      last_status,
      last_agent,
    } = rest || {};
    const history = historyProp || [];
    const sessions = yield select(selectSessions);
    let newSessions = [...sessions];
    const mappedMessages = historyToMessages(history);

    let newSession = {
      ...rest,
      id,
      session_status,
      last_status,
      customer,
      platform,
      created: queue_join_time,
      updated: queue_join_time,
      last_agent,
    };

    const isPartialHistory = !isNaN(history_count) && history_count > mappedMessages.length;
    const existingSession = sessions.find((session) => session.id === id);
    let existingSessionListenType = existingSession?.listen_type;
    if (!existingSession) {
      newSessions.push(newSession);
    } else {
      newSessions = newSessions.map((session) => {
        if (session.id === id) {
          newSession = {
            ...session,
            ...newSession,
            messages: isPartialHistory
              ? session.messages?.length
                ? session.messages
                : mappedMessages
              : mappedMessages,

            listen_type: existingSessionListenType,
          };
          const waitingTypes = [chatbotSessionStatus.AGENT_WAIT, chatbotSessionStatus.BOT_CHAT_AGENT_WAIT];
          if (waitingTypes.includes(session_status) && !waitingTypes.includes(last_status) && livechatOptions.alert) {
            AudioHelper.get(audioList.agentWaitingAlert).then((audio) => audio.replay());
          }
          return newSession;
        } else {
          return session;
        }
      });
    }

    yield put(setSessions(newSessions));

    if (
      [chatbotSessionStatus.AGENT_WAIT, chatbotSessionStatus.BOT_CHAT_AGENT_WAIT].includes(newSession.session_status)
    ) {
      if (newSession.listen_type !== "all") {
        yield put(sendListenAction(id, "all"));
      }
    } else if (
      //when agent waiting status changed to agent chat with bot, listen as "status_only"
      [chatbotSessionStatus.AGENT_WAIT, chatbotSessionStatus.BOT_CHAT_AGENT_WAIT].includes(newSession.last_status) &&
      ![chatbotSessionStatus.AGENT_WAIT, chatbotSessionStatus.BOT_CHAT_AGENT_WAIT].includes(
        newSession.session_status
      ) &&
      chatbotSessionStatus.AGENT_CHAT !== newSession.session_status
    ) {
      if (newSession.listen_type !== "status_only") {
        const selectedSession = yield select(selectSelectedSession);
        if (selectedSession?.id !== id) {
          yield put(sendListenAction(id, "status_only"));
        }
      }
    }
  }
}

/**
 * Messages & Actions stores in redux as raw data. Conversion process is executed in
 *
 * @param {object} incomingPayload
 * @see convertSocketMsgToChatMessage function
 */
export default function* socketActionsHandler(incomingPayload) {
  const { payload } = incomingPayload;

  yield put(setPong(DateHelper.getDateTime()));

  switch (payload.type) {
    case "log":
      const format = "HH:mm:ss.SSS";
      const time = (
        payload.message_time || payload.time
          ? DateHelper.getDateTime(payload.message_time || payload.time)
          : DateHelper.getDateTime()
      ).format(format);

      const { name = "", color, object } = payload.payload || {};
      let logText = `${time} - ${name}: `;
      Utils.log(logText, object, color);
      break;
    case "session_count_delta":
      const livechatOptions = yield select(selectLivechatOptions);
      const waitingAgentCount = payload.payload?.agent_waiting_count;
      if (livechatOptions?.alert && waitingAgentCount > 0) {
        AudioHelper.get(audioList.agentWaitingAlert).then((audio) => audio.replay());
      }

      yield put(setSessionCountDelta(payload.payload));
      break;
    case "pong":
      // yield put(setPong(DateHelper.getDateTime()));
      break;
    case "status_update":
      const agentInfo = yield select(selectAgentInfo);
      yield put(setAgentInfo({ ...agentInfo, status: payload.payload.status === "AVAILABLE" ? "AV" : "UN" }));
      break;
    case "session":
      yield call(handleSessionStatus, payload.payload);
      break;
    case "interaction_status":
      yield put(updateInteractionStatus(payload.payload));
      break;
    case "action":
    case "message":
      if (payload.payload.type) {
        payload.payload.type = payload.payload.type.toLowerCase();
      }
      const payloadType = payload.payload.type;
      const payloadData = payload?.payload?.payload;
      const sessionId = payload?.payload?.session_id;
      if (payloadType === "text") {
        const livechatOptions = yield select(selectLivechatOptions);
        if (livechatOptions?.alert) {
          const selectedSession = yield select(selectSelectedSession);
          const agentInfo = yield select(selectAgentInfo);
          const sessions = yield select(selectSessions);
          const targetSession = sessions.find((s) => s.id === sessionId);
          // const relatedAssignedSession = sessions.filter((s) => s.agent?.id === agentInfo?.id);
          // const isAssignedToAgent = relatedAssignedSession.some((s) => s.id === sessionId);
          const isHidden = document.visibilityState !== "visible";
          const sessionStatus = targetSession?.session_status;
          if (
            targetSession?.agent?.id === agentInfo?.id &&
            chatbotSessionStatus.AGENT_CHAT === sessionStatus &&
            !(selectedSession?.id === sessionId && !isHidden)
          ) {
            {
              AudioHelper.get(audioList.agentNewMessage).then((audio) => audio.replay());
            }
          }
        }
      } else if (payloadType === "direct_to_queue") {
        const sessionId = payload.payload?.session_id;
        const sessions = yield select(selectSessions);

        const isBotJoinedQueue = payloadData?.bot_joined;
        const queueType = payloadData?.queue_type;
        const isAgentQueue = queueType === "AGENT";
        let replacedSession;
        const newSessions = sessions.map((session) => {
          if (session.id === sessionId) {
            replacedSession = {
              ...session,
              session_status: isAgentQueue
                ? isBotJoinedQueue
                  ? chatbotSessionStatus.BOT_CHAT_AGENT_WAIT
                  : chatbotSessionStatus.AGENT_WAIT
                : chatbotSessionStatus.BOT_WAIT,
              last_status: session.session_status,
            };
            return replacedSession;
          } else {
            return session;
          }
        });
        yield put(setSessions(newSessions));

        if (replacedSession && !isAgentQueue && replacedSession?.listen_type !== "status_only") {
          yield put(sendListenAction(sessionId, "status_only"));
        }
      } else if (payloadType === "bot_connect" || payloadType === "agent_connect" || payloadType === "transfer_agent") {
        const selectedSession = yield select(selectSelectedSession);

        const sessionId = payload.payload?.session_id;
        const botId = payloadData?.id;
        const botName = payloadData?.name;
        const sessions = yield select(selectSessions);
        const agentInfo = yield select(selectAgentInfo);
        let modifiedSession;

        const newStatus =
          payloadType === "bot_connect" ? chatbotSessionStatus.BOT_CHAT : chatbotSessionStatus.AGENT_CHAT;
        const agentId = payloadType === "transfer_agent" ? payloadData?.agent_id : payload?.payload?.sender_id;

        if (payloadType === "transfer_agent" && agentInfo?.id === agentId) {
          const livechatOptions = yield select(selectLivechatOptions);
          if (livechatOptions?.alert) {
            AudioHelper.get(audioList.agentTransferred).then((audio) => audio.replay());
            AlertHelper.show(`${payload?.payload?.sender_name} has transferred a session to you.`, "info", {
              autoHideDuration: 5000,
            });
          }
        }

        const toBeFullListened = [];
        const toBeStatusOnlyListened = [];
        const newSessions = sessions.map((session) => {
          if (session.id === sessionId) {
            modifiedSession = {
              ...session,
              ...(payloadType === "bot_connect"
                ? {
                    bot: botId,
                    bot_name: botName,
                  }
                : {
                    last_agent: session.agent,
                    agent: {
                      name: payloadData?.name,
                      //TODO: id should be come from 'payload?.payload?.payload?.id' but it is coming like '4300034' instead of '4'
                      id: agentId,
                    },
                  }),
              last_status: session.session_status,
              session_status: newStatus,
            };
            if (selectedSession?.id === sessionId) {
              if (session.listen_type !== "all") {
                toBeFullListened.push(sessionId);
              }
            } else if (
              [chatbotSessionStatus.AGENT_WAIT, chatbotSessionStatus.BOT_CHAT_AGENT_WAIT].includes(newStatus)
            ) {
              if (session.listen_type !== "all") {
                toBeFullListened.push(sessionId);
              }
            } else if (newStatus === chatbotSessionStatus.AGENT_CHAT) {
              if (agentInfo?.id === agentId) {
                if (session.listen_type !== "all") {
                  toBeFullListened.push(sessionId);
                }
              } else if (agentInfo?.id !== agentId) {
                if (session.listen_type !== "status_only") {
                  toBeStatusOnlyListened.push(sessionId);
                }
              }
            } else if (newStatus === chatbotSessionStatus.BOT_CHAT) {
              if (session.listen_type !== "status_only") {
                toBeStatusOnlyListened.push(sessionId);
              }
            }
            return modifiedSession;
          } else {
            return session;
          }
        });

        const toBeFullListenedIdList = toBeFullListened.map((id) => id);
        const toBeStatusOnlyListenedIdList = toBeStatusOnlyListened.map((id) => id);

        if (toBeFullListenedIdList?.length) {
          yield put(sendListenAction(toBeFullListenedIdList, "all"));
        }

        if (toBeStatusOnlyListenedIdList?.length) {
          yield put(sendListenAction(toBeStatusOnlyListenedIdList, "status_only"));
          console.log(
            "wsSendListen from toBeStatusOnlyListenedIdList",
            "status_only",
            toBeStatusOnlyListenedIdList,
            payloadType
          );
        }

        yield put(setSessions(newSessions));
      } else if (payloadType === "session_info") {
        const selectedSession = yield select(selectSelectedSession);
        const refreshedSessions = payloadData.sessions.map((session) => {
          const isSelectedSession = selectedSession?.id === session.id;

          const mappedMessages = historyToMessages(session.history);
          const isPartialHistory = !isNaN(session.history_count) && session.history_count > mappedMessages.length;

          return {
            ...(isSelectedSession && selectedSession),
            ...session,
            messages: isSelectedSession
              ? selectedSession.messages
              : !isPartialHistory
              ? mappedMessages
              : session?.messages?.length
              ? session.messages
              : mappedMessages || [],
          };
        });

        const count = payloadData.count;
        const sessions = yield select(selectSessions);

        const currentMainSessionList = [];
        const currentNonMainSessionList = [];
        for (const s of sessions) {
          if (chatbotMainSessionStatusList.includes(s.session_status)) {
            currentMainSessionList.push(s); // AGENT_CHAT, AGENT_WAIT, AGENT_CHAT_BOT_WAIT
          } else {
            currentNonMainSessionList.push(s); // Others except of above
          }
        }
        const stillExistingNonMainSessions = [];
        const removedNonMainSessions = [];
        let newNonMainSessions = [];

        for (const s of currentNonMainSessionList) {
          const matchedSession = refreshedSessions.find((session) => session.id === s.id);
          if (matchedSession) {
            // const matchedSessionMessagesFromHistory =matchedSession.history && historyToMessages(matchedSession.history);
            stillExistingNonMainSessions.push({
              ...s,
              ...matchedSession,
              messages: matchedSession.messages?.length ? matchedSession.messages : s.messages,
            });
          } else {
            removedNonMainSessions.push(s);
          }
        }

        for (const s of refreshedSessions) {
          if (!currentNonMainSessionList.some((session) => session.id === s.id)) {
            newNonMainSessions.push(s);
          }
        }

        const removedSessionIdList = removedNonMainSessions.map((s) => s.id);

        const newAddedSessionIdListExceptSelected = newNonMainSessions
          .map((s) => s.id)
          .filter((id) => selectedSession?.id !== id);

        const stillExistingNonMainSessionIdListExceptSelected = stillExistingNonMainSessions
          .filter((s) => s.listen_type !== "status_only" && selectedSession?.id !== s.id)
          .map((s) => s.id);

        const statusOnlyListenedIdList = [
          ...newAddedSessionIdListExceptSelected,
          ...stillExistingNonMainSessionIdListExceptSelected,
        ];

        let newSessions = [...currentMainSessionList, ...stillExistingNonMainSessions, ...newNonMainSessions];
        //TODO sometimes sessions are coming duplicate due async request (ask_session_info + agent_connect). If there is a duplicate session, only keep the one with the highest history count.
        newSessions = newSessions.filter((s, index, self) => {
          const otherSameSessions = self.filter((ss) => ss.id === s.id);
          if (otherSameSessions.length === 1) return true;

          const highestHistoryCountSession = otherSameSessions.reduce((prev, current) =>
            prev?.history?.length > current?.history?.length ? prev : current
          );
          const highestHistoryCountSessionIndex = self.findIndex((ss) => ss.id === highestHistoryCountSession?.id);
          return highestHistoryCountSessionIndex === index;
        });

        //sort by last message's created time or created time if there is no message
        newSessions.sort((a, b) => {
          const aLastMessageTime = a.history?.length ? a.history[a.history?.length - 1].created : a.created;
          const bLastMessageTime = b.history?.length ? b.history[b.history?.length - 1].created : b.created;

          const aDate = DateHelper.getDateTime(aLastMessageTime);
          const bDate = DateHelper.getDateTime(bLastMessageTime);
          return bDate.isAfter(aDate) ? 1 : -1;
        });

        yield put(setOtherSessionsInfo({ count }));
        yield put(setSessions(newSessions));

        if (removedSessionIdList?.length) {
          yield put(sendListenAction(removedSessionIdList, "offline"));
        }
        if (statusOnlyListenedIdList?.length) {
          yield put(sendListenAction(statusOnlyListenedIdList, "status_only"));
        }

        //# region re-listen for viewing page sessions to fetch latest message history. Remove this block when session_info sessions are coming with history.
        // const toBeStatusOnlyAgainList = [];

        // for (const s of refreshedSessions) {
        //   if (selectedSession?.id === s.id) continue;
        //   toBeStatusOnlyAgainList.push(s.id);
        // }
        // yield put(sendListenAction(toBeStatusOnlyAgainList, "offline"));
        // yield put(sendListenAction(toBeStatusOnlyAgainList, "status_only"));
        //#endregion

        yield put(setLivechatLoadingStatus(false));
        return;
      }

      yield put(appendMessageToSession(payload?.payload?.session_id, payload));
      break;
    case "notification":
      const { payload: notificationPayload } = payload;
      if (notificationPayload.source === "login") {
        if (notificationPayload.status === "success") {
          //TODO: Bug - Filter changes are coming from login event. Should be come from different event like 'filter_update'.
          const isFilterUpdateEvent = notificationPayload?.status_message === "Subscription type updated";

          const sessionCounts = notificationPayload?.data?.session_counts;
          if (sessionCounts) {
            yield put(setSessionCountDelta(sessionCounts, true));
          }
          yield put(
            setConnectionOptions({
              ping_interval: notificationPayload?.data?.ping_interval,
              expires_in: notificationPayload?.data?.expires_in,
            })
          );
          const s = notificationPayload?.data?.sessions || {};
          let sessions = [...(s.agent_waiting || []), ...(s.assigned || []), ...(s.other || [])];
          sessions = sessions.map((s) => {
            // return s;
            return { ...s, messages: [] };
          });
          const currentAgentChattingSessions = [];
          const otherAgentChattingSessions = [];
          const waitingSessions = [];

          // Do not necessary to this list for guarantee to handle all sessions.
          // Normally only comes 3 different session_type on initial connect.
          const others = [];

          const agentInfo = yield select(selectAgentInfo);
          for (const session of sessions) {
            if (
              session.session_status === chatbotSessionStatus.AGENT_WAIT ||
              session.session_status === chatbotSessionStatus.BOT_CHAT_AGENT_WAIT
            ) {
              waitingSessions.push(session);
            } else if (session.session_status === chatbotSessionStatus.AGENT_CHAT) {
              if (session?.agent?.id === agentInfo?.id) {
                currentAgentChattingSessions.push(session);
              } else {
                otherAgentChattingSessions.push(session);
              }
            } else {
              others.push(session);
            }
          }

          const fullListenSessions = [...currentAgentChattingSessions];
          const nonMainSessions = [...otherAgentChattingSessions, ...waitingSessions, ...others];

          const newSessions = [...fullListenSessions, ...nonMainSessions];
          if (newSessions?.length || isFilterUpdateEvent) {
            if (isFilterUpdateEvent) {
              const sessions = yield select(selectSessions);
              //Exclude non main sessions. Because they are will be updated by 'session_info' event.
              const nonMainSessions = sessions.filter((s) => !chatbotMainSessionStatusList.includes(s.session_status));
              if (nonMainSessions?.length) {
                newSessions.push(...nonMainSessions);
              }
            }
            yield put(setSessions(newSessions));
          }
          if (isFilterUpdateEvent) {
            if (!newSessions?.length) {
              yield put(setSelectedSession(null));
            }
            yield call(throttleReloadOtherSessions);
          }

          const fullListenIdList = fullListenSessions.map((s) => s.id).filter(Boolean);
          if (fullListenIdList?.length) {
            yield put(sendListenAction(fullListenIdList, "all"));
          }

          const nonMainIdList = nonMainSessions.map((s) => s.id).filter(Boolean);
          if (nonMainIdList?.length) {
            yield put(sendListenAction(nonMainIdList, "status_only"));
          }

          if (!isFilterUpdateEvent) {
            yield put(wsLoginSuccess());
          }
        } else if (payload.status === "FAIL") {
          yield put(wsLoginFail(payload));
        }
      } else if (notificationPayload.status !== "success") {
        const msg = notificationPayload.status_message;
        AlertHelper.show(msg, "error");

        Sentry.captureMessage(JSON.stringify(notificationPayload), "error");
        yield put(setLivechatLoadingStatus(false));
      }
      break;
  }
}
