import { get } from "lodash";

import { chatbotPopupCollapseExpandMs, popupPosition } from "~constants";

import SanitizeHelper from "./SanitizeHelper";
import Utils from "./Utils";
let latestPopupSizeMode = "icon";
export default class IFrameHelper {
  static getIFrameWindow(iframe) {
    return iframe.contentWindow || iframe.contentDocument.parentWindow;
  }

  static listenForMessage(type, payload, listenValidator) {
    return new Promise((resolve) => {
      const urlQueryObj = new URLSearchParams(window.location.search);
      const postOrigin = SanitizeHelper.safeUrl(urlQueryObj.get("origin") || window.location.origin);
      window.parent.postMessage({ type, payload }, postOrigin);

      const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
      const eventer = window[eventMethod];
      const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

      const callbackType = `${type}-done`;
      eventer(
        messageEvent,
        function (e) {
          const key = e.message ? "message" : "data";
          const data = e[key];
          if (data && typeof data === "object" && data.type === callbackType) {
            if (listenValidator && typeof listenValidator === "function") {
              const validateResult = listenValidator(data.payload);
              if (!validateResult) {
                return;
              }
            }
            resolve(data.payload);
          }
        },
        false
      );
    });
  }

  static events = {};
  static listenForEvents(id) {
    const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    const eventerFunc = function (e) {
      const key = e.message ? "message" : "data";
      const data = e[key];
      if (data && typeof data === "object" && data.type === id) {
        const { id, isEvent, values } = data.payload;
        if (isEvent && IFrameHelper.events[id]) {
          IFrameHelper.events[id]({ id, values });
        }
      }
    };
    eventer(messageEvent, eventerFunc, false);
  }

  /** @returns {"inline" | null} */
  static getMode() {
    const url = new URL(window.location.href);
    const modeQuery = url.searchParams.get("mode");
    return modeQuery;
  }
  static isInIFrame() {
    let inIframe = false;
    try {
      inIframe = window.self !== window.top;
    } catch (e) {
      inIframe = true;
    }

    const url = new URL(window.location.href);
    const modeQuery = url.searchParams.get("mode");
    return inIframe && modeQuery !== "inline";
  }
  static isTryChatbot() {
    const url = new URL(window.location.href);
    const modeQuery = url.searchParams.get("mode");
    return modeQuery === "inline";
  }
  static isInIFrameOrTryChatbotOrPreview() {
    const previewContainerId = "preview-chatbot-container";

    const dom = document.getElementById(previewContainerId);
    if (dom) return true;

    let inIframe = false;
    try {
      inIframe = window.self !== window.top;
    } catch (e) {
      inIframe = true;
    }

    const url = new URL(window.location.href);
    const modeQuery = url.searchParams.get("mode");
    return inIframe || modeQuery === "inline";
  }
  static isWebChatMode() {
    const url = new URL(window.location.href);
    const pathnameChunks = url.pathname.split("---"); //for handling dynamic manifest links
    const firstPathnameChunk = pathnameChunks?.[0] || url.pathname;

    const isWebchatMode =
      firstPathnameChunk === `${process.env.PUBLIC_URL}/webchat` ||
      window.location.href.startsWith(`${url.origin}/webchat/`);
    return isWebchatMode;
  }

  static classListAsync(method, classList = [], isPreviewMode = false) {
    if (!IFrameHelper.isInIFrame() && !isPreviewMode) return;
    const previewContainerId = "preview-chatbot-container";
    if (isPreviewMode) {
      const previewContainer = document.getElementById(previewContainerId);
      if (previewContainer?.classList) {
        previewContainer.classList[method](...classList);
      }
      return Promise.resolve();
    }

    return IFrameHelper.listenForMessage("pr-update-class", { method, classList });
  }

  static setPropertyToRoot(keyValueArray) {
    const setProperty = (key, value) => document.documentElement.style.setProperty(key, value);
    for (const [key, value] of keyValueArray) {
      setProperty(key, value);
    }

    if (!IFrameHelper.isInIFrame()) {
      return;
    }

    return IFrameHelper.listenForMessage("pr-set-property", { keyValueArray });
  }
  static setStyleToPopup(styleData, isPreviewMode) {
    if (!IFrameHelper.isInIFrame() && !isPreviewMode) return;
    const previewContainerId = "preview-chatbot-container";
    if (isPreviewMode) {
      const previewContainer = document.getElementById(previewContainerId);
      if (previewContainer) {
        if (typeof styleData === "string") {
          const cssChunks = styleData.split(";");
          const existingStyleChunks = previewContainer.style.cssText.split(";").reverse();
          if (existingStyleChunks.length > 0) {
            for (const ch of existingStyleChunks) {
              if (!cssChunks.includes(ch)) {
                cssChunks.unshift(ch);
              }
            }
          }
          const cssString = cssChunks.join(";");
          previewContainer.style.cssText = cssString;
        } else {
          Object.assign(previewContainer.style, styleData);
        }
      }
      return Promise.resolve();
    }
    return IFrameHelper.listenForMessage("pr-popup-style", styleData);
  }
  /**
   * @param {"icon" | "iconWelcome" | "chat" | "wide"} mode Popup view mode
   * @param {boolean} isPreviewMode
   */
  static getLatestPopupSizeMode() {
    return latestPopupSizeMode;
  }
  /**
   * @param {"icon" | "iconWelcome" | "chat" | "wide"} mode
   * @param {boolean} isPreviewMode
   * @param {"mobile" | "desktop"} displayMode
   * @param {(typeof popupPosition)[keyof typeof popupPosition]} position
   * @returns {Promise<void>}
   */
  static async setPopupSizeMode(mode, isPreviewMode, displayMode, position = "bottomRight") {
    if (!IFrameHelper.isInIFrame() && !isPreviewMode) return;

    latestPopupSizeMode = mode;

    // --webchat-width: 450px;
    // --webchat-height: 650px;
    // --webchat-icon-size: 62px;
    // --webchat-icon-margin-x: 25px;
    // --webchat-icon-margin-y: 25px;

    // const popupStyleInfo = {
    //   // ...window.popup?.popup_style_info,

    //   ...styleInfo,
    //   /** @deprecated Use popup_margin_x and popup_margin_y instead and remove popup_margin on save */
    //   popup_margin: styleInfo?.popup_margin ?? popupIconMarginDefault,
    //   popup_margin_x: styleInfo?.popup_margin_x ?? popupIconMarginDefault,
    //   popup_margin_y: styleInfo?.popup_margin_y ?? popupIconMarginDefault,
    //   popup_icon_size: styleInfo?.popup_icon_size ?? popupIconSizeDefault,
    //   chat_height: styleInfo?.chat_height ?? popupChatHeightDefault,
    //   popup_position: styleInfo?.popup_position ?? popupPosition.bottomRight,
    // };

    const currentClassStr = (await IFrameHelper.classListAsync("toString", [], isPreviewMode)) || "";
    const currentClassList = typeof currentClassStr === "string" ? currentClassStr.split(" ").filter(Boolean) : "";
    const classPrefix = "PalChatbot";

    const actionModeClassList = [
      `${classPrefix}--mode-icon`,
      `${classPrefix}--mode-iconWelcome`,
      `${classPrefix}--mode-chat`,
      `${classPrefix}--mode-wide`,
    ];
    const actionDeviceClassList = [`${classPrefix}--device-mobile`, `${classPrefix}--device-desktop`];

    const existingClassMode = actionModeClassList.find((c) => currentClassList.includes(c));
    const newClassMode = `${classPrefix}--mode-${mode}`;
    if (!existingClassMode) {
      await IFrameHelper.classListAsync("add", [newClassMode], isPreviewMode);
    } else if (existingClassMode !== newClassMode) {
      await IFrameHelper.classListAsync("replace", [existingClassMode, newClassMode], isPreviewMode);
    }

    const existingClassDevice = actionDeviceClassList.find((c) => currentClassList.includes(c));

    const newClassDevice = `${classPrefix}--device-${displayMode}`;
    if (!existingClassDevice) {
      await IFrameHelper.classListAsync("add", [newClassDevice], isPreviewMode);
    } else if (existingClassDevice && existingClassDevice !== newClassDevice) {
      await IFrameHelper.classListAsync("replace", [existingClassDevice, newClassDevice], isPreviewMode);
    }

    // console.log("setPopupSizeMode", mode, isPreviewMode, position);
    if (mode === "wide") {
      await IFrameHelper.setStyleToPopup(
        ` 
          height: 100%;
          max-height: 100%;
          max-height: 100dvh;
          width: 100%;
          top: 0;
          bottom: 0;
          right: 0;
          border-radius: 0%;
          `,
        isPreviewMode
      );
      return;
    } else if (mode === "chat") {
      // if (Utils.detectDeviceType() === "mobile") {
      if (displayMode === "mobile") {
        await IFrameHelper.setStyleToPopup(
          `
            height: 100%;
            max-height: 100dvh;
            width: 100%;           
            top: 0px;
            bottom: 0px;
            border-radius: 0%;
             ${
               position === popupPosition.bottomLeft
                 ? `
                     left: 0px;
                     right: unset;
                   `
                 : `
                     right: 0px;
                     left: unset;
                   `
             },
          `,
          isPreviewMode
        );
      } else {
        await IFrameHelper.setStyleToPopup(
          `
            height: var(--webchat-height);
            max-height: calc(100vh - (var(--webchat-icon-margin-y) * 2));
            max-height: calc(100dvh - (var(--webchat-icon-margin-y) * 2));
            width: 450px;
            top: unset;
            bottom: var(--webchat-icon-margin-y);
            ${
              position === popupPosition.bottomLeft
                ? `
                  left: var(--webchat-icon-margin-x);
                  right: unset;
                `
                : `
                  left: unset;
                  right: var(--webchat-icon-margin-x);
                `
            }
            border-radius: 24px;
            transition: box-shadow ${chatbotPopupCollapseExpandMs}ms cubic-bezier(0,1,1,1);
            box-shadow: 0.1px 0.1px 0.7px rgba(0, 0, 0, 0.031), 0.3px 0.3px 1.6px rgba(0, 0, 0, 0.045), 0.5px 0.5px 2.8px rgba(0, 0, 0, 0.055), 0.8px 0.8px 4.7px rgba(0, 0, 0, 0.065), 1.3px 1.3px 7.7px rgba(0, 0, 0, 0.075), 2.3px 2.3px 13.4px rgba(0, 0, 0, 0.089), 5px 5px 29px rgba(0, 0, 0, 0.12);
          `,
          isPreviewMode
        );
        //add box shadow after 100ms to ensure that the popup animation is finished
        setTimeout(() => {
          IFrameHelper.setStyleToPopup(
            {
              transition: "none",
            },
            isPreviewMode
          );
        }, chatbotPopupCollapseExpandMs);
      }
    } else if (["icon", "iconWelcome"].includes(mode)) {
      const popupIconWidthHeight = `var(--webchat-icon-size)`;
      const popupWelcomeMsgLineHeight = 20;
      //TODO: automatically detect multiple ballon line count and increase iframe dynamically.
      const maxSupportLineCount = 2;
      // const popupIconWidthForWelcomeMsg = 255 + popupIconWidthHeight / 2;
      const popupIconWidthForWelcomeMsg = `calc(255px + var(--webchat-icon-size) / 2)`;
      const popupBalloonTextHeight = maxSupportLineCount * popupWelcomeMsgLineHeight;
      // const popupIconHeightForWelcomeMsg = 50 + popupIconWidthHeight + popupBalloonTextHeight; // 50 base
      const popupIconHeightForWelcomeMsg = `calc(50px + var(--webchat-icon-size) + ${popupBalloonTextHeight}px)`; // 50 base
      // const iconPadding = Utils.detectDeviceType() === "mobile" ? 10 : popupStyleInfo.popup_margin;
      // const iconPaddingX = Utils.detectDeviceType() === "mobile" ? 10 : popupStyleInfo.popup_margin_x;
      const iconPaddingX =
        displayMode === "mobile" ? `calc(var(--webchat-icon-margin-x) / 2)` : `var(--webchat-icon-margin-x)`;
      const iconPaddingY =
        displayMode === "mobile" ? `calc(var(--webchat-icon-margin-y) / 2)` : `var(--webchat-icon-margin-y)`;

      const textPreviewMode = mode === "iconWelcome";
      let width = textPreviewMode ? popupIconWidthForWelcomeMsg : popupIconWidthHeight;
      let height = textPreviewMode ? popupIconHeightForWelcomeMsg : popupIconWidthHeight;

      if (position === popupPosition.bottomLeft) {
        // width += 10; // 10px for close button's overflow
        width = `calc(${width} + 10px)`;
      }
      if (!isPreviewMode) {
        //TODO: Check for normal usage too and reduce redundant space on top for 'iconWelcome' mode
        // height += iconPadding;
      }
      await IFrameHelper.setStyleToPopup(
        {
          transition: "none",
          boxShadow: "none",
          top: "unset",
          height: `${height}`,
          width: `${width}`,
          bottom: `${iconPaddingY}`,
          ...(position === popupPosition.bottomLeft
            ? {
                left: `${iconPaddingX}`,
                right: "unset",
              }
            : {
                right: `${iconPaddingX}`,
                left: "unset",
              }),
          // ...(!textPreviewMode && {
          borderRadius: "0%",
          borderTopRightRadius: "0px",
          // }),
        },
        isPreviewMode
      );
    }
  }
  static download(url, fileName) {
    if (!IFrameHelper.isInIFrame()) return;
    return IFrameHelper.listenForMessage("pr-download", { url, fileName });
  }

  static getTitle() {
    if (!IFrameHelper.isInIFrame()) {
      return document.title;
    }
    return IFrameHelper.listenForMessage("pr-get-title");
  }

  static setTitle(title) {
    if (!IFrameHelper.isInIFrame()) {
      document.title = title;
      return;
    }
    return IFrameHelper.listenForMessage("pr-set-title", title);
  }

  static async checkStorage() {
    window.__pp_storage_mode = "none";

    let isCurrentValid = false;

    const testKey = "test";
    try {
      const storage = window.localStorage;
      storage.setItem(testKey, "1");
      storage.removeItem(testKey);
      isCurrentValid = true;
    } catch (error) {
      isCurrentValid = false;
    }

    if (!IFrameHelper.isInIFrame()) {
      return isCurrentValid;
    }

    if (isCurrentValid) {
      window.__pp_storage_mode = "current";
      return true;
    }

    const result = await IFrameHelper.listenForMessage("pr-check-storage");
    if (result) {
      window.__pp_storage_mode = "parent";
    }
    return result;
  }

  static getStorage(key) {
    if (!IFrameHelper.isInIFrame() || window.__pp_storage_mode === "current") {
      return localStorage.getItem(key);
    }
    return IFrameHelper.listenForMessage("pr-get-storage", { key });
  }

  static setStorage(key, value) {
    if (!IFrameHelper.isInIFrame() || window.__pp_storage_mode === "current") {
      return localStorage.setItem(key, value);
    }

    return IFrameHelper.listenForMessage("pr-set-storage", { key, value });
  }

  static removeStorage(key) {
    if (!IFrameHelper.isInIFrame() || window.__pp_storage_mode === "current") {
      return localStorage.removeItem(key);
    }

    return IFrameHelper.listenForMessage("pr-remove-storage", { key });
  }

  static clearStorage() {
    if (!IFrameHelper.isInIFrame() || window.__pp_storage_mode === "current") {
      return localStorage.clear();
    }

    return IFrameHelper.listenForMessage("pr-clear-storage");
  }
  /**
   * @returns {Promise<{
   *   href: string;
   *   referrer: string;
   * }>} - Href
   */
  static getLocationInfo() {
    if (!IFrameHelper.isInIFrame()) {
      return {
        href: window.location.href,
        referrer: document.referrer,
      };
    }

    return IFrameHelper.listenForMessage("pr-get-location");
  }
  static getCookies() {
    if (!IFrameHelper.isInIFrame()) {
      return document.cookie;
    }
    return IFrameHelper.listenForMessage("pr-get-cookies");
  }

  static fetch(url, options) {
    if (!IFrameHelper.isInIFrame()) {
      return fetch(url, options);
    }

    return IFrameHelper.listenForMessage("pr-fetch", { url, options });
  }

  /**
   * @param {"onBeforeAuth" | "onBeforeLogin" | "onTerminated"} eventName - Event name
   * @param {...any} args - Arguments to pass
   * @returns {Promise<{
   *   executed: boolean;
   *   data: any;
   * }>} - Executed event response
   */
  static async execEvent(eventName, ...args) {
    if (!IFrameHelper.isInIFrame()) {
      return {
        executed: false,
        data: null,
      };
    }
    const validator = (payload) => {
      return payload?.event === eventName;
    };

    const response = await IFrameHelper.listenForMessage("pr-event-action", { event: eventName, args }, validator);
    if (response.data instanceof Error) {
      console.error(response.data);
    }
    return response;
  }
  static addEventListener(name, callback, selectors = [], options = {}) {
    if (!IFrameHelper.isInIFrame()) {
      return window.addEventListener(name, callback, options);
    }
    const eventId = Utils.getId();
    IFrameHelper.events[eventId] = callback;
    IFrameHelper.listenForEvents(eventId);
    return IFrameHelper.listenForMessage("pr-listen", {
      eventName: name,
      eventOptions: options,
      id: eventId,
      selectors,
    });
  }
  static removeEventListener(name, callback, options) {
    if (!IFrameHelper.isInIFrame()) {
      return window.removeEventListener(name, callback, options);
    }
    const eventId = Object.keys(IFrameHelper.events).find((e) => IFrameHelper.events[e] === callback);
    if (eventId) {
      delete IFrameHelper.events[eventId];
    }
    return IFrameHelper.listenForMessage("pr-stop-listen", { eventName: name, eventOptions: options, id: eventId });
  }
  static getFromWindow(selector) {
    if (!IFrameHelper.isInIFrame()) {
      return Promise.resolve(get(window, selector));
    }
    return IFrameHelper.listenForMessage("pr-window", { selector });
  }

  static listenWebchatDomEvents(callback) {
    if (!IFrameHelper.isInIFrame()) return;
    const eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    /*
    
      _pp_iframe.contentWindow.postMessage(
            {
              type: "pr-dom-action",
              payload: {
                event: attrAction,
                args: [attrActionValue],
              },
            },
            window.location.origin
          );
          
          */

    const eventerFunc = function (e) {
      const key = e.message ? "message" : "data";
      const data = e[key];
      if (e.target?.origin !== window.location.origin) return;
      if (data && typeof data === "object" && data.type === "pr-dom-action") {
        callback(data.payload);
      }
    };
    eventer(messageEvent, eventerFunc, false);
    return () => {
      window.removeEventListener(messageEvent, eventerFunc);
    };
  }
}
