import { createSelector } from "reselect";

import DateHelper from "~helpers/DateHelper";

/** @type {() => typeof import("./reducer").initState} */
export const selectChatbot = (state) => state.socket.chatbot;

// export const selectIsLogged = (state) => selectChatbot(state).isLogged;
// export const selectLogin = (state) => selectChatbot(state).login;
// export const selectBotInfo = (state) => selectChatbot(state).botInfo;
// export const selectAgentInfo = (state) => selectChatbot(state).agentInfo;
// export const selectMessages = (state) => selectChatbot(state).messages;
// export const selectNotifications = (state) => selectChatbot(state).notifications;
// export const selectSender = (state) => selectChatbot(state).sender;
// export const selectLastMessageTime = (state) => {
//   const time = selectChatbot(state).lastMessageTime;
//   return time ? DateHelper.getDateTime(time) : null;
// };

// export const selectWelcomeOptions = (state) => selectChatbot(state).welcomeOptions;

// export const selectSession = (state) => selectChatbot(state).session;
// export const selectViewMode = (state) => selectChatbot(state).viewMode;
// export const selectPreviousViewMode = (state) => selectChatbot(state).previousViewMode;
// export const selectQueueStatus = (state) => selectChatbot(state).queueStatus;
// export const selectChatDisabledStatus = (state) => selectChatbot(state).chatDisabled;

// export const selectMinimized = (state) => selectChatbot(state).minimized;
// export const selectMinimizedDone = (state) => selectChatbot(state).minimizedDone;
// export const selectWelcomeMsgShowed = (state) => selectChatbot(state).welcomeMsgShowed;
// export const selectSessionStatus = (state) => selectChatbot(state).sessionStatus;
// export const selectQueryOptions = (state) => selectChatbot(state).queryOptions;

// export const selectMaintenanceMode = (state) => selectChatbot(state).maintenanceMode;
// export const selectLoginTryCount = (state) => selectChatbot(state).loginTryCount;
// export const selectFeedbackInfo = (state) => selectChatbot(state).feedbackInfo;

// export const selectLastPongTime = (state) => selectChatbot(state).lastPongTime;
// export const selectOnlineStatus = (state) => selectChatbot(state).onlineStatus;
// export const selectLastOutgoingMsg = (state) => selectChatbot(state).lastOutgoingMsg;
// export const selectWelcomeFormData = (state) => selectChatbot(state).welcomeFormData;

// export const selectSocketDirection = (state) => selectChatbot(state).lastSocketDirection;
// export const selectTicketForm = (state) => selectChatbot(state)?.ticketForm;
// export const selectSocketInstance = (state) => selectChatbot(state).socketInstance;

//rewrite the above code to using createSelector
export const selectIsLogged = createSelector(selectChatbot, (chatbot) => chatbot.isLogged);
export const selectLogin = createSelector(selectChatbot, (chatbot) => chatbot.login);
export const selectBotInfo = createSelector(selectChatbot, (chatbot) => chatbot.botInfo);
export const selectAgentInfo = createSelector(selectChatbot, (chatbot) => chatbot.agentInfo);
export const selectMessages = createSelector(selectChatbot, (chatbot) => chatbot.messages);
export const selectRawMessages = createSelector(selectChatbot, (chatbot) => chatbot.rawMessages);
// export const selectNotifications = createSelector(selectChatbot, (chatbot) => chatbot.notifications);
export const selectSender = createSelector(selectChatbot, (chatbot) => chatbot.sender);
export const selectSenderData = createSelector(selectChatbot, (chatbot) => chatbot.senderData);
export const selectLastMessageTime = createSelector(selectChatbot, (chatbot) => {
  const time = chatbot.lastMessageTime;
  return time ? DateHelper.getDateTime(time) : null;
});

export const selectWelcomeOptions = createSelector(selectChatbot, (chatbot) => chatbot.welcomeOptions);
export const selectSession = createSelector(selectChatbot, (chatbot) => chatbot.session);
export const selectViewMode = createSelector(selectChatbot, (chatbot) => chatbot.viewMode);
export const selectPreviousViewMode = createSelector(selectChatbot, (chatbot) => chatbot.previousViewMode);
export const selectQueueStatus = createSelector(selectChatbot, (chatbot) => chatbot.queueStatus);
export const selectChatDisabledStatus = createSelector(selectChatbot, (chatbot) => chatbot.chatDisabled);

export const selectMinimized = createSelector(selectChatbot, (chatbot) => chatbot.minimized);
export const selectMinimizedDone = createSelector(selectChatbot, (chatbot) => chatbot.minimizedDone);
export const selectWelcomeMsgShowed = createSelector(selectChatbot, (chatbot) => chatbot.welcomeMsgShowed);
export const selectSessionStatus = createSelector(selectChatbot, (chatbot) => chatbot.sessionStatus);
export const selectQueryOptions = createSelector(selectChatbot, (chatbot) => chatbot.queryOptions);
export const selectStartChatSessionReason = createSelector(selectChatbot, (chatbot) => chatbot.startChatSessionReason);

export const selectMaintenanceMode = createSelector(selectChatbot, (chatbot) => chatbot.maintenanceMode);
export const selectLoginTryCount = createSelector(selectChatbot, (chatbot) => chatbot.loginTryCount);
export const selectFeedbackInfo = createSelector(selectChatbot, (chatbot) => chatbot.feedbackInfo);

export const selectLastPongTime = createSelector(selectChatbot, (chatbot) => chatbot.lastPongTime);
export const selectOnlineStatus = createSelector(selectChatbot, (chatbot) => chatbot.onlineStatus);
export const selectLastOutgoingMsg = createSelector(selectChatbot, (chatbot) => chatbot.lastOutgoingMsg);
export const selectWelcomeFormData = createSelector(selectChatbot, (chatbot) => chatbot.welcomeFormData);
export const selectButtonSelectLoading = createSelector(selectChatbot, (chatbot) => chatbot.buttonSelectLoading);

export const selectConnectionOptions = createSelector(selectChatbot, (chatbot) => chatbot.connectionOptions);
// export const selectSocketDirection = createSelector(selectChatbot, (chatbot) => chatbot.lastSocketDirection);
export const selectTicketForm = createSelector(selectChatbot, (chatbot) => chatbot?.ticketForm);
export const selectSocketInstance = createSelector(selectChatbot, (chatbot) => chatbot.socketInstance);
export const selectInteractionStatus = createSelector(selectChatbot, (chatbot) => chatbot.interactionStatus);
export const selectRemoteInteractionStatus = createSelector(
  selectChatbot,
  (chatbot) => chatbot.remoteInteractionStatus
);
export const selectSubmitValidationResult = createSelector(selectChatbot, (chatbot) => chatbot.submitValidationResult);
export const selectSeenMessageInfo = createSelector(selectChatbot, (chatbot) => chatbot.seenMessageInfo);
export const selectInitialized = createSelector(selectChatbot, (chatbot) => chatbot.initialized);
export const selectInputRequestInfo = createSelector(selectChatbot, (chatbot) => chatbot.inputRequestInfo);
export const selectInteractionModal = createSelector(selectChatbot, (chatbot) => chatbot.interactionModal);
export const selectDisplayMode = createSelector(selectChatbot, (chatbot) => chatbot.displayMode);
