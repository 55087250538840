import IFrameHelper from "~helpers/IFrameHelper";
import TokenHelper from "~helpers/TokenHelper";
import Utils from "~helpers/Utils";
import store from "~store";

import { broadcastActionTypes } from ".";
import { onBroadcastMessage } from "../actions";

// polyfill if BroadcastChannel is not supported
if (!window.BroadcastChannel) {
  window.BroadcastChannel = function BroadcastChannel() {
    return {
      postMessage: () => {},
      close: () => {},
      addEventListener: () => {},
      removeEventListener: () => {},
    };
  };
}

const initializeTabId = () => {
  if (!window.tabId) {
    window.tabId = Utils.getId();
  }
};
initializeTabId();

const getTabId = () => {
  return window.tabId;
};

export const chatbotBroadcastChannel = new BroadcastChannel("pr-webchat");
// Current initializing multiple iframe for chatbot, so we need to listen only from webchat iframe.
if (IFrameHelper.isWebChatMode()) {
  chatbotBroadcastChannel.addEventListener("message", (event) => {
    //check origin to be sure it's from the same domain
    if (
      event.origin !== window.location.origin ||
      event.data?.id === getTabId() ||
      TokenHelper.getWebchatProjectToken() !== event.data?.token
    ) {
      return;
    }

    store.dispatch(onBroadcastMessage(event.data));
  });
}
export const chatbotBroadcastSync = () => {
  chatbotBroadcastChannel.postMessage({
    type: broadcastActionTypes.sync,
    id: getTabId(),
    token: TokenHelper.getWebchatProjectToken(),
  });
};

export const closeTimeoutBroadcast = () => {
  chatbotBroadcastChannel.postMessage({
    type: broadcastActionTypes.closeTimeout,
    id: getTabId(),
    token: TokenHelper.getWebchatProjectToken(),
  });
};
