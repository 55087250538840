import { safeProduce as produce } from "~helpers/immer";

import * as actionTypes from "./actionTypes";

/**
 * @typedef {{
 *   id: number;
 *   member_data_format: any;
 *   name: string;
 *   display_name: any;
 *   is_required: boolean;
 *   identifier: boolean;
 *   is_manager: boolean;
 *   field_type: string;
 *   project: number;
 * }} MemberField
 */

/**
 * @typedef {{
 *   id: number;
 *   member_data_fields: {
 *     id: number;
 *     name: string;
 *     display_name: string;
 *     is_required: boolean;
 *     identifier: boolean;
 *     is_manager: boolean;
 *     field_type: string;
 *     visibility_type: string;
 *     member_data_format: number;
 *     project: number;
 *   }[];
 *   secret_keys: {
 *     id: number;
 *     name: string;
 *     expires_in: string;
 *     project: number;
 *   }[];
 *   project: number;
 * }} MemberFieldFormat
 */

/**
 * @typedef {{
 *   id: number;
 *   survey: number;
 *   question: string;
 *   question_type: typeof organizationQuestionType.CHOICE;
 *   options: string[];
 *   project: number;
 *   result: {
 *     count: number;
 *     histogram: {
 *       [key: string]: number;
 *     };
 *     top_choice: string;
 *   };
 * }} ChoiceAnswer
 *
 * @typedef {{
 *   id: number;
 *   survey: number;
 *   question: string;
 *   question_type: typeof organizationQuestionType.RANGE;
 *   interval: [number, number];
 *   project: number;
 *   result: {
 *     mean: number;
 *     median: number;
 *     count: string;
 *   };
 * }} RangeAnswer
 *
 * @typedef {{
 *   id: number;
 *   survey: number;
 *   question: string;
 *   question_type: typeof organizationQuestionType.TEXT;
 *   project: number;
 *   result: {
 *     responses: string[];
 *   };
 * }} TextAnswer
 *
 * @typedef {{
 *   status: string;
 *   answers: (ChoiceAnswer | RangeAnswer | TextAnswer)[];
 *   total_count: number;
 *   waiting_count: number;
 *   started_count: number;
 *   finished_count: number;
 * }} SurveyResult
 */

/**
 * @typedef {{
 *   id: number;
 *   name: string;
 *   resource_type: "PUBLIC" | "INSIDE";
 *   member_filter: number;
 *   reservation_status: {
 *     past: {
 *       reservation_count: number;
 *       available_count: number;
 *       slot_count: number;
 *     };
 *     future: {
 *       reservation_count: number;
 *       available_count: number;
 *       slot_count: number;
 *     };
 *     all: {
 *       reservation_count: number;
 *       available_count: number;
 *       slot_count: number;
 *     };
 *   };
 *   project: number;
 * }} ResourceItem
 */
export const initState = Object.freeze({
  organization: {
    // /** @type {MemberField[]} */
    // memberFields: [],
    /** @type {MemberFieldFormat} */
    memberFieldFormat: [],
    //TODO: Split filters and actions to individual reducers
    filters: [],
    filter: {},
    actionList: [],
    action: {},
    announcement: {
      name: null,
      survey: null,
      statistics: null,
      versions: null,
      histories: null,
    },
    announcementVersion: {},
    /** @type {ResourceItem[]} */
    resources: [],
    /** @type {ResourceItem} */
    resource: {},
    slots: [],
    slot: {},
    reservations: [],
    members: [],
    /**
     * @type {{
     *   id: number;
     *   project: number;
     *   identifier: {};
     *   safe_information: {};
     * }}
     */
    member: {},
    survey: {},
    /** @type {SurveyResult} */
    surveyResult: {},
    surveyList: [],
  },
  validate: {},
  loading: false,
  saving: false,
  secretKey: null,
  secretKeyList: [],
});

const scenarioManager = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.RESET_FORM:
        draft.organization = JSON.parse(JSON.stringify(initState.organization));
        break;
      case actionTypes.SET_FORM_LOADING:
        draft.loading = action.payload;
        break;
      case actionTypes.SET_FORM_SAVING:
        draft.saving = action.payload;
        break;
      case actionTypes.SET_MEMBER_FIELD_FORMAT:
        draft.organization.memberFieldFormat = action.payload;
        break;
      case actionTypes.SET_FILTERS:
        draft.organization.filters = action.payload;
        break;
      case actionTypes.SET_FILTER:
        draft.organization.filter = action.payload;
        break;
      case actionTypes.SET_ACTION:
        draft.organization.action = action.payload;
        break;
      case actionTypes.SET_ACTION_LIST:
        draft.organization.actionList = action.payload;
        break;
      case actionTypes.SET_RESOURCES:
        draft.organization.resources = action.payload;
        break;
      case actionTypes.SET_RESOURCE:
        draft.organization.resource = action.payload;
        break;
      case actionTypes.SET_MEMBERS:
        draft.organization.members = action.payload;
        break;
      case actionTypes.SET_MEMBER:
        draft.organization.member = action.payload;
        break;
      case actionTypes.SET_SURVEY:
        draft.organization.survey = action.payload;
        break;
      case actionTypes.SET_SLOT:
        draft.organization.slot = action.payload;
        break;
      case actionTypes.SET_SURVEY_LIST:
        draft.organization.surveyList = action.payload;
        break;
      case actionTypes.SET_SLOT_LIST:
        draft.organization.slots = action.payload;
        break;
      case actionTypes.SET_RESERVATION_LIST:
        draft.organization.reservations = action.payload;
        break;
      case actionTypes.SET_SURVEY_RESULT:
        draft.organization.surveyResult = action.payload;
        break;
      case actionTypes.SET_ANNOUNCEMENT:
        draft.organization.announcement = action.payload;
        break;
      case actionTypes.SET_ANNOUNCEMENT_VERSION:
        draft.organization.announcementVersion = action.payload;
        break;
      case actionTypes.SET_SECRET_KEY:
        draft.secretKey = action.payload;
        break;
      case actionTypes.SET_SECRET_KEY_LIST:
        draft.secretKeyList = action.payload;
        break;
    }
  });
export default scenarioManager;
