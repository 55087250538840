import { put, select } from "redux-saga/effects";

import ChatTimeoutCountdownModal from "~components/WebChat/Body/ChatTimeoutCountdownModal";

import { resumeChatSession, setInteractionModal } from "../actions";
import { selectIsLogged, selectSessionStatus } from "../selectors";

export const broadcastActionTypes = {
  sync: "chatbot_sync",
  closeTimeout: "chatbot_close_timeout",
};

export default function* broadcastActionsHandler({ payload }) {
  switch (payload.type) {
    case broadcastActionTypes.sync:
      //   yield call(notificationHandler, payload);
      const isLogged = yield select(selectIsLogged);
      const sessionStatus = yield select(selectSessionStatus);
      if (!isLogged && ["init", "closed"].includes(sessionStatus)) {
        yield put(resumeChatSession());
        console.log("resumeChatSession after sync broadcast");
      }

      break;
    case broadcastActionTypes.closeTimeout:
      yield put(setInteractionModal(false));
      ChatTimeoutCountdownModal.hide();
      break;
  }
}
