import ConstantHelper from "~helpers/ConstantHelper";

export const userRole = {
  /** @type {"Admin"} */
  admin: "Admin",
  /** @type {"ChatbotModerator"} */
  chatbotModerator: "ChatbotModerator",
  /** @type {"CallCenterAgent"} */
  callCenterAgent: "CallCenterAgent",
  /** @type {"TicketAgent"} */
  ticketAgent: "TicketAgent",
  /** @type {"OrganizationManager"} */
  organizationManager: "OrganizationManager",
  /** @type {"AgentManager"} */
  AgentManager: "AgentManager",
};

export const userRoleMap = {
  [userRole.admin]: "Admin",
  [userRole.chatbotModerator]: "Chatbot Moderator",
  [userRole.callCenterAgent]: "Call Center Agent",
  [userRole.ticketAgent]: "Ticket Agent",
  [userRole.organizationManager]: "Organization Manager",
  [userRole.AgentManager]: "Agent Manager",
};

export const userRoleOptions = [
  { value: userRole.admin, label: userRoleMap[userRole.admin] },
  { value: userRole.chatbotModerator, label: userRoleMap[userRole.chatbotModerator] },
  { value: userRole.callCenterAgent, label: userRoleMap[userRole.callCenterAgent] },
  { value: userRole.ticketAgent, label: userRoleMap[userRole.ticketAgent] },
  { value: userRole.organizationManager, label: userRoleMap[userRole.organizationManager] },
  { value: userRole.AgentManager, label: userRoleMap[userRole.AgentManager] },
];

export const [userAuthenticationMethod, userAuthenticationMethodOptions, userAuthenticationMethodMap] =
  ConstantHelper.typify({
    // none: "None",
    email: "Email",
    authenticator: "Authenticator",
  });
