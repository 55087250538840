import * as actionTypes from "./actionTypes";

export const setTheme = (payload) => ({
  type: actionTypes.SET_THEME,
  payload,
});

export const setWebchatTheme = (payload) => ({
  type: actionTypes.SET_WEBCHAT_THEME,
  payload,
});
