import * as Sentry from "@sentry/react";
import i18next from "i18next";
import { cloneDeep, debounce } from "lodash";
import { call, delay, put, race, select } from "redux-saga/effects";

import JoinedMessage from "~components/Generic/PRChatMessage/InlineMessages/NotificationMessage/JoinedMessage";
import ChatTimeoutCountdownModal from "~components/WebChat/Body/ChatTimeoutCountdownModal";
import { LS_WEBCHAT_OPTIONS, LS_WEBCHAT_TOKEN, LS_WEBCHAT_TOKEN_EXPIRE, audioList } from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import AudioHelper from "~helpers/AudioHelper";
import DateHelper from "~helpers/DateHelper";
import IFrameHelper from "~helpers/IFrameHelper";
import StorageHelper from "~helpers/StorageHelper";
import TokenHelper from "~helpers/TokenHelper";
import store from "~store";

import { socketMessageToState } from "../../SocketUtils";
import {
  appendMessage,
  appendRawMessage,
  changeSender,
  changeSenderData,
  disconnectDone,
  setButtonSelectLoading,
  setChatDisabledStatus,
  setConnectionOptions,
  setFeedbackInfo,
  setInitialized,
  setInputRequestInfo,
  setInteractionModal,
  setIsLogged,
  setLastOutgoingMsg,
  setMessageList,
  setPong,
  setQueueStatus,
  setRemoteInteractionStatus,
  setSeenMessageInfo,
  setSessionStatus,
  setSubmitValidationResult,
  setTicketForm,
  setViewModeDelayed,
  updateMessage,
  wsActionChannelReceived,
  wsIn,
  wsLoginFail,
  wsLoginSuccess,
  wsOut,
} from "../actions";
import * as at from "../actionTypes";
import { closeTimeoutBroadcast } from "../broadcastActions/broadcast";
import { getFailedWaitMs, setFailedWaitMs } from "../saga";
import {
  selectInteractionStatus,
  selectMessages,
  selectMinimized,
  selectOnlineStatus,
  selectQueueStatus,
  selectRawMessages,
  selectRemoteInteractionStatus,
  selectSeenMessageInfo,
  selectSender,
  selectSenderData,
  selectSocketInstance,
} from "../selectors";

let userInteracted = false;

// Wait initial click to check availability of vibration
window.addEventListener("click", function () {
  userInteracted = true;
});
const enableChatAndSetInitializedDebounce = debounce((socketMessage) => {
  store.dispatch(setInitialized(true));
  // store.dispatch(setChatDisabledStatus(false));

  // const sender = yield select(selectSender);
  const state = store.getState();
  const sender = selectSender(state);
  if (socketMessage?.sender !== "CUSTOMER" || sender === "AGENT") {
    // yield put(setChatDisabledStatus(false));
    store.dispatch(setChatDisabledStatus(false));
  }
}, 750);

let internalSystemErrorCounter = 0;

/**
 * Convert incoming raw socket message to redux based action
 *
 * @param {any} requestObj
 * @param {"in" | "out"} direction
 */
export function socketMessageToAction(requestObj, direction = "in") {
  const { type, payload } = requestObj;
  let action = null;

  const directionAction = direction === "in" ? wsIn : wsOut;
  if (type === "notification") {
    action = directionAction.notification(requestObj);
  } else if (type === "pong") {
    action = directionAction.pong(requestObj);
  } else if (type === "action" && payload) {
    const payloadType = payload?.type;
    if (payloadType === "bot_connect") {
      action = directionAction.action.botConnect(requestObj);
    } else if (payloadType === "bot_disconnect") {
      action = directionAction.action.botDisconnect(requestObj);
    } else if (payloadType === "agent_connect") {
      action = directionAction.action.agentConnect(requestObj);
    } else if (payloadType === "agent_disconnect") {
      action = directionAction.action.agentDisconnect(requestObj);
    } else if (payloadType === "terminate_session") {
      action = directionAction.action.terminateSession(requestObj);
    } else if (payloadType === "inactivity_timeout") {
      action = directionAction.action.inactivityTimeout?.(requestObj);
    } else if (payloadType === "redirect_to_ticket" || payloadType === "direct_to_ticket") {
      action = directionAction.action.redirectToTicket(requestObj);
    } else if (payloadType === "redirect_to_queue" || payloadType === "direct_to_queue") {
      action = directionAction.action.redirectToQueue(requestObj);
    } else if (payloadType === "redirect_to_feedback" || payloadType === "direct_to_feedback") {
      action = directionAction.action.redirectToFeedback(requestObj);
    }
    //  else if (payloadType === "direct_to_offline") {
    //   action = directionAction.action?.directToOffline?.(requestObj);
    // }
    else if (payloadType === "message_feedback") {
      action = directionAction.action.messageFeedback(requestObj);
    } else if (payloadType === "submit_message_feedback") {
      action = directionAction.action.submitMessageFeedback(requestObj);
    } else if (payloadType === "input_request") {
      action = directionAction.action.inputRequest(requestObj);
    } else if (payloadType === "submit_input_request") {
      action = directionAction.action.submitInputRequest(requestObj);
    } else if (payloadType === "button_select") {
      action = directionAction.action?.buttonSelect?.(requestObj);
    } else if (payloadType === "submit_ticket") {
      action = directionAction.action?.submitTicket?.(requestObj);
    } else if (payloadType === "submit_feedback") {
      action = directionAction.action?.submitFeedback?.(requestObj);
    } else if (payloadType === "cancel_ticket") {
      action = directionAction.action?.cancelTicket?.(requestObj);
    } else if (payloadType === "cancel_queue") {
      action = directionAction.action?.cancelQueue?.(requestObj);
    } else if (payloadType === "validate_input") {
      action = directionAction.action?.submitValidation?.(requestObj);
    } else if (payloadType === "input_validation_result") {
      action = directionAction.action?.submitValidationResult?.(requestObj);
    } else if (payloadType === "change_language") {
      action = directionAction.action?.changeLanguage?.(requestObj);
    } else if (payloadType === "transfer_agent") {
      action = directionAction.action?.transferAgent?.(requestObj);
    } else if (payloadType === "return_to_home") {
      action = directionAction.action?.returnToHome?.(requestObj);
    }
  } else if (type === "queue") {
    action = directionAction.queue(requestObj);
  } else if (type === "message") {
    const newMsg = {
      ...requestObj,
      payload: {
        ...requestObj.payload,
        text: requestObj?.payload?.text?.payload?.text || requestObj?.payload?.text, //TODO: Sometimes nested payload coming due backend issue and should be removed `requestObj?.payload?.text?.payload?.text` after backend fix
      },
    };
    action = directionAction.message(newMsg);
  } else if (type === "history") {
    action = directionAction.history(requestObj);
  } else if (type === "interaction_status") {
    action = directionAction.interactionStatus(requestObj);
  }
  return action;
}

const incomingNotificationDebounced = debounce(() => {
  Promise.all([AudioHelper.get(audioList.customerNewMessage)]).then(async ([audio]) => {
    audio.replay();
  });
}, 250);

const socketTestDelay = 0;

export function* handleSocketIncoming({ type, payload: payloadProp, history }) {
  try {
    const payload = { ...payloadProp };

    if (socketTestDelay > 0) {
      yield delay(socketTestDelay);
    }
    //Notification Sound

    if ([at.WS_IN_MESSAGE, at.WS_IN_ACTION_INPUT_REQUEST, at.WS_IN_ACTION_AGENT_CONNECT].includes(type)) {
      //determine if not focused
      const isChatVisible = document.visibilityState === "visible";

      const minimized = yield select(selectMinimized);
      const projectToken = TokenHelper.getWebchatProjectToken();
      const alias = TokenHelper.getChatbotAlias();

      let options = yield call(StorageHelper.get, LS_WEBCHAT_OPTIONS.format(projectToken || alias));
      options ??= {};

      const isNotificationsEnabled = options?.sound_notification;

      if (!history) {
        const messages = yield select(selectMessages);
        const incomingMessages = messages.filter((msg) => msg.position === "left");
        const messageLength = incomingMessages?.length;
        const newMessageLength = incomingMessages?.length + 1; //Message is not added to state yet in current codeblock so +1
        if ((IFrameHelper.getMode() !== "inline" && minimized) || !isChatVisible) {
          const currentSeenMessageInfo = yield select(selectSeenMessageInfo);
          yield put(
            setSeenMessageInfo({
              new: newMessageLength - currentSeenMessageInfo?.current,
            })
          );
          if (isNotificationsEnabled) {
            if (type === at.WS_IN_ACTION_AGENT_CONNECT || type === at.WS_IN_ACTION_BOT_CONNECT) {
              Promise.all([AudioHelper.get(audioList.customerAgentJoined)]).then(async ([audio]) => {
                audio.replay();
              });
            } else if (
              type === at.WS_IN_ACTION_INPUT_REQUEST ||
              (type === at.WS_IN_MESSAGE && payload?.payload?.sender_type !== "CUSTOMER") ||
              type === at.WS_IN_ACTION_REDIRECT_TO_TICKET ||
              type === at.WS_IN_ACTION_REDIRECT_TO_QUEUE ||
              type === at.WS_IN_ACTION_REDIRECT_TO_FEEDBACK
            ) {
              incomingNotificationDebounced();
            }
          }
        } else {
          yield put(
            setSeenMessageInfo({
              current: messageLength,
              new: 0,
            })
          );
        }
      }
    }

    yield put(setPong(DateHelper.getDateTime()));
    if (type === at.WS_IN_PONG) {
      // yield put(setPong(DateHelper.getDateTime()));
    } else if (type === at.WS_IN_NOTIFICATION) {
      const { payload: { source, status, status_message, duration, data } = {} } = payload;

      // const welcomeOptions = yield select(selectWelcomeOptions);
      if (source === "login") {
        if (status === "success") {
          internalSystemErrorCounter = 0;
          setFailedWaitMs(1000);
          yield put(
            setConnectionOptions({
              ping_interval: data.ping_interval,
              expires_in: data.expires_in,
              time_until_expiration: data.time_until_expiration,
            })
          );

          const messages = yield select(selectMessages);
          if (!messages?.length) {
            //TODO: Backend sending multiple times same history when open in new tab. Remove this after fix on backend
            yield put(setMessageList(data.messages || []));
          }

          yield put(setSessionStatus(data.session_status === "new" ? "new" : "resume"));
          yield put(setIsLogged(true));

          let onlineStatus = yield select(selectOnlineStatus);

          if (onlineStatus === "closed") {
            //only redirect to page at initial login, reconnecting will not redirect
            yield put(setViewModeDelayed("chat"));
          }
          yield put(wsLoginSuccess());
        } else {
          const isCompleted = status_message === "Session completed";
          const isInternalError = status_message === "Internal system error";
          if (isInternalError) {
            internalSystemErrorCounter++;
            if (internalSystemErrorCounter > 3) {
              AlertHelper.show(status_message, "error");
              Sentry.captureMessage(JSON.stringify(payload), "login internal error");
            }
          }
          if (isCompleted) {
            const palmateTkn = TokenHelper.getWebchatProjectToken();
            const alias = TokenHelper.getChatbotAlias();
            StorageHelper.remove(LS_WEBCHAT_TOKEN.format(palmateTkn || alias));
            StorageHelper.remove(LS_WEBCHAT_TOKEN_EXPIRE.format(palmateTkn || alias));
          }
          yield put(setSessionStatus("closed"));
          yield put(setIsLogged(false));
          yield put(wsLoginFail());
          if (getFailedWaitMs() < 10000) {
            setFailedWaitMs(getFailedWaitMs() * 1.5);
          }
        }
      } else {
        if (status === "error") {
          AlertHelper.show(status_message, "error", {
            autoHideDuration: duration || 7000,
          });
          Sentry.captureMessage(JSON.stringify(payload), "error");
        } else {
          AlertHelper.show(status_message, status, {
            autoHideDuration: duration || 7000,
          });
        }
      }
    } else if (type === at.WS_IN_MESSAGE) {
      yield put(appendRawMessage(payload));
      const activeSenderData = yield select(selectSenderData);
      const newPayload = {
        ...payload.payload,
        type: payload.payload?.type?.toLowerCase(),
        time: payload.time,
        senderData: cloneDeep(activeSenderData),
        ...(payload?.historyId && { historyId: payload.historyId }),
      };
      const socketMessage = socketMessageToState(newPayload);

      if (socketMessage?.position === "left" || socketMessage?.type === "inputRequest") {
        // Disable typing when message received
        const remoteInteractionStatus = yield select(selectRemoteInteractionStatus);
        yield put(
          setRemoteInteractionStatus({
            ...remoteInteractionStatus,
            updateTime: DateHelper.getDateTime(),
            typing: false,
          })
        );
        //Vibrate only when message not sent by customer
        if ("vibrate" in navigator && userInteracted) {
          navigator.vibrate(10);
        }
      }
      // const previousViewMode = yield select(selectPreviousViewMode);
      // const viewMode = yield select(selectViewMode);

      // const messages = yield select(selectMessages);
      yield put(appendMessage(socketMessage));
      // const lastMessage = messages?.[messages.length - 1];
      // if (lastMessage?.type === "inputRequest" && socketMessage?.type === "text") {
      //   yield put(
      //     appendMessage({
      //       type: "notification",
      //       position: "center",
      //       messageTime: DateHelper.getDateTime(payload.time),
      //       text:
      //         lastMessage?.data === null
      //           ? i18next.t("chatbot.actionInfo.inputRequest.cancel")
      //           : i18next.t("chatbot.actionInfo.inputRequest.submit"),
      //     })
      //   );
      // }
      yield put(setLastOutgoingMsg(null));
      // if (viewMode !== "chat") {
      yield put(setViewModeDelayed("chat"));
      // }
      enableChatAndSetInitializedDebounce(socketMessage);
    } else if (type.startsWith(at.WS_IN_ACTION)) {
      yield put(appendRawMessage(payload));
      const { payload: actionPayload, type: actionType, id, sender_id, sender_name, sender_type } = payload.payload;

      if (type === at.WS_IN_ACTION_MESSAGE_FEEDBACK) {
        const remoteInteractionStatus = yield select(selectRemoteInteractionStatus);

        if (actionPayload.value > -1) {
          const messages = yield select(selectMessages);
          // const lastMessage = messages[messages.length - 1];
          let msgItem = messages.find((msg) => msg?.id === actionPayload.message_id);

          if (msgItem) {
            yield put(
              updateMessage(msgItem, {
                ...msgItem,
                likeStatus: actionPayload.value,
              })
            );
          }
        }

        yield put(
          setRemoteInteractionStatus({
            ...remoteInteractionStatus,
            updateTime: DateHelper.getDateTime(),
            typing: false,
          })
        );
      } else if (type === at.WS_IN_ACTION_REDIRECT_TO_FEEDBACK) {
        yield put(setInteractionModal(false));
        ChatTimeoutCountdownModal.hide();
        yield put(setFeedbackInfo(actionPayload));

        // Disable typing when direct to feedback
        const remoteInteractionStatus = yield select(selectRemoteInteractionStatus);
        yield put(
          setRemoteInteractionStatus({
            ...remoteInteractionStatus,
            updateTime: DateHelper.getDateTime(),
            typing: false,
          })
        );
      } else if (type === at.WS_IN_ACTION_TERMINATE_SESSION) {
        const onlineStatus = yield select(selectOnlineStatus);
        if (onlineStatus !== "closed") {
          // const welcomeOptions = yield select(selectWelcomeOptions);
          // yield put(setViewModeDelayed(welcomeOptions.start_anonymous_chat ? "chat" : "welcome"));
          // yield put(
          //   appendMessage({
          //     messageTime: DateHelper.getDateTime(payload.time),
          //     type: "notification",
          //     position: "center",
          //     text: `${i18next.t("chatbot.chatSceneTexts.sessionClosed")}`,
          //   })
          // );

          yield put(disconnectDone());
        }
      } else if (type === at.WS_IN_ACTION_REDIRECT_TO_QUEUE) {
        yield put(changeSender(actionPayload?.bot_joined ? "BOT" : null));

        const queueStatus = yield select(selectQueueStatus);
        yield put(setQueueStatus({ ...queueStatus, ...actionPayload }));
        if (
          !actionPayload.available_agents &&
          !actionPayload.ticket_enabled &&
          !actionPayload.direct_to_ticket_enabled &&
          !actionPayload?.bot_joined &&
          actionPayload?.queue_type !== "BOT"
        ) {
          yield put(setViewModeDelayed("offline"));
        } else {
          yield put(setViewModeDelayed("queue"));
        }
      } else if (type === at.WS_IN_ACTION_INPUT_REQUEST) {
        const type = actionPayload?.input_format?.type;
        yield put(
          appendMessage({
            id: id,
            senderId: sender_id,
            type: "inputRequest",
            position: type === "form" ? "center" : "right",
            text: payload.msg,
            // inputType: "file", //TODO: set type by server response
            inputFormat: actionPayload?.input_format,
            messageTime: DateHelper.getDateTime(payload.message_time) || DateHelper.getDateTime(),
            volatile: true,
            sender: sender_type,
          })
        );

        // yield put(setChatDisabledStatus(false));
        // const messages = yield select(selectMessages);
        // const lastMessage = messages[messages.length - 1];
        // const twoLastMessage = messages[messages.length - 2];
        // if (!["inputRequest", "notification"].includes(twoLastMessage?.type) && lastMessage?.type === "inputRequest") {
        //   yield put(
        //     appendMessage({
        //       type: "notification",
        //       position: "center",
        //       messageTime: DateHelper.getDateTime(payload.time),
        //       text: i18next.t("chatbot.actionInfo.inputRequest"),
        //     })
        //   );
        // }
        yield put(setInputRequestInfo(actionPayload));
        if (actionPayload?.input_format?.type === "form") {
          yield put(setViewModeDelayed("form"));
        }
        if ("vibrate" in navigator && userInteracted) {
          navigator.vibrate(10);
        }
      } else if (type === at.WS_IN_ACTION_SUBMIT_INPUT_REQUEST) {
        const messages = yield select(selectMessages);
        // const lastMessage = messages[messages.length - 1];
        let lastReceivedInputRequest = null;
        let lastReceivedInputRequestIndex;
        for (let i = messages.length - 1; i >= 0; i--) {
          if (messages[i]?.type === "inputRequest") {
            lastReceivedInputRequest = messages[i];
            lastReceivedInputRequestIndex = i;

            // Consider to remove this after backend fix,
            //
            // // Check if input request has multiple fields and check if all fields are included in the submit_input_request's field IDs
            // if (lastReceivedInputRequest?.inputFormat?.fields?.length) {
            //   // Iterate through each submit_input_request's field IDs and check if they are included in the input_request field IDs
            //   // TODO: Backend should be fixed message order.
            //   const followingSubmitInputRequestFieldIds = lastReceivedInputRequest?.inputFormat?.fields.map(
            //     (f) => f.format.id
            //   );

            //   const dataKeys = Object.keys(actionPayload?.data || {});
            //   const dataKeysIncluded = dataKeys.every((k) => followingSubmitInputRequestFieldIds.includes(k));
            //   if (!dataKeysIncluded) {
            //     continue;
            //   }
            // }

            break;
          }
        }

        if (lastReceivedInputRequest) {
          yield put(
            updateMessage(lastReceivedInputRequest, {
              ...lastReceivedInputRequest,
              inputFormat: { ...lastReceivedInputRequest.inputFormat, ...actionPayload },
              sender: sender_type,
            })
          );
        }

        yield put(setButtonSelectLoading(false));

        const previousMessageForLastReceivedInputRequest = messages?.[lastReceivedInputRequestIndex - 1];
        if (
          lastReceivedInputRequest?.inputFormat?.type === "form" &&
          previousMessageForLastReceivedInputRequest?.type !== "inputRequest" &&
          actionPayload?.data === null
        ) {
          yield put(
            appendMessage({
              id: id,
              type: "notification",
              position: "center",
              messageTime: DateHelper.getDateTime(payload.time),
              text: i18next.t("chatbot.actionInfo.inputRequest.cancel"),
            })
          );
        }

        const lastMessage = messages[messages.length - 1];
        if (lastMessage?.inputFormat?.type !== "form") {
          const remoteInteractionStatus = yield select(selectRemoteInteractionStatus);
          yield put(
            setRemoteInteractionStatus({
              ...remoteInteractionStatus,
              updateTime: DateHelper.getDateTime(),
              typing: true,
            })
          );
        }

        // const remoteInteractionStatus = yield select(selectRemoteInteractionStatus);
        // yield put(
        //   setRemoteInteractionStatus({ ...remoteInteractionStatus, updateTime: DateHelper.getDateTime(), typing: false })
        // );
      } else if (type === at.WS_IN_ACTION_BOT_CONNECT || type === at.WS_IN_ACTION_AGENT_CONNECT) {
        if (type === at.WS_IN_ACTION_AGENT_CONNECT) {
          yield put(changeSender("AGENT"));
        } else {
          yield put(changeSender("BOT"));
        }
        yield put(changeSenderData(actionPayload));

        yield put(setViewModeDelayed("chat"));
        yield put(
          appendMessage({
            id: id,
            type: "notification",
            position: "center",
            messageTime: DateHelper.getDateTime(payload.time),
            text: function () {
              return (
                <JoinedMessage
                  isAgent={actionType === "agent_connect"}
                  messageTime={DateHelper.getDateTime(payload.time)}
                  name={actionPayload.name}
                />
              );
            },
          })
        );

        const queueStatus = yield select(selectQueueStatus);
        if (!queueStatus?.done) {
          yield put(setQueueStatus({ ...queueStatus, done: true }));
        }

        // const messages = yield select(selectMessages);
        // if (messages?.length > 0) {
        //   yield put(setChatDisabledStatus(false));
        // }
        if (type === at.WS_IN_ACTION_AGENT_CONNECT) {
          yield put(setChatDisabledStatus(false));

          //disable typing when agent connected
          const remoteInteractionStatus = yield select(selectRemoteInteractionStatus);
          yield put(
            setRemoteInteractionStatus({
              ...remoteInteractionStatus,
              updateTime: DateHelper.getDateTime(),
              typing: false,
            })
          );
        }
      } else if (type === at.WS_IN_ACTION_REDIRECT_TO_TICKET) {
        yield put(setTicketForm(actionPayload));
        yield put(setViewModeDelayed("ticket"));
        yield put(
          appendMessage({
            id: id,
            messageTime: DateHelper.getDateTime(payload.time),
            type: "notification",
            position: "center",
            text: i18next.t("chatbot.actionInfo.ticketOpen"),
          })
        );
      } else if (type === at.WS_IN_ACTION_AGENT_DISCONNECT) {
        yield put(
          appendMessage({
            id: id,
            messageTime: DateHelper.getDateTime(payload.time),
            type: "notification",
            position: "center",
            text: i18next.t("chatbot.chatSceneTexts.agentLeavedChat").format(actionPayload.name),
          })
        );
      } else if (type === at.WS_IN_ACTION_SUBMIT_TICKET) {
        // const sender = yield select(selectSender);
        const queueStatus = yield select(selectQueueStatus);
        if (!queueStatus?.done) {
          yield put(setViewModeDelayed("queue"));
        } else {
          yield put(setViewModeDelayed("chat"));
        }
        yield put(
          appendMessage({
            id: id,
            messageTime: DateHelper.getDateTime(payload.time),
            type: "notification",
            position: "center",
            text: i18next.t("chatbot.actionInfo.ticketSaved"),
          })
        );

        const remoteInteractionStatus = yield select(selectRemoteInteractionStatus);
        yield put(
          setRemoteInteractionStatus({
            ...remoteInteractionStatus,
            updateTime: DateHelper.getDateTime(),
            typing: false,
          })
        );
        yield put(setChatDisabledStatus(false));
      } else if (type === at.WS_IN_ACTION_BUTTON_SELECT) {
        yield put(setButtonSelectLoading(false));

        let lastButtonSelect;
        let choice;
        const messages = yield select(selectMessages);
        for (let i = messages.length - 1; i >= 0; i--) {
          choice = messages[i]?.choices?.find((item) => item?.value === actionPayload.value);
          if (choice) {
            lastButtonSelect = messages[i];
            break;
          }
        }

        if (lastButtonSelect) {
          yield put(
            appendMessage({
              id: id,
              senderId: sender_id,
              type: "text",
              position: "right",
              text: choice.text,
              format: lastButtonSelect.format?.toLowerCase(),
              messageTime: DateHelper.getDateTime(payload.message_time),
              actionType: "button_select",
            })
          );
          const newMessage = {
            ...lastButtonSelect,
            choices: lastButtonSelect.choices.map((item) => ({ ...item, isSelected: item.value === choice.value })),
            isSent: true,
          };
          yield put(updateMessage(lastButtonSelect, newMessage));
        }

        // const messages = yield select(selectMessages);
        // let lastReceivedButtonSelect = null;
        // for (let i = messages.length - 1; i >= 0; i--) {
        //   if (messages[i]?.actionType === "button_select") {
        //     lastReceivedButtonSelect = messages[i];
        //     break;
        //   }
        // }
        // if (lastReceivedButtonSelect) {
        //   yield put(
        //     updateMessage(lastReceivedButtonSelect, {
        //       ...lastReceivedButtonSelect,
        //       isSent: true,
        //     })
        //   );
        // }
      } else if (type === at.WS_IN_ACTION_CANCEL_TICKET) {
        yield put(
          appendMessage({
            id: id,
            senderId: sender_id,
            messageTime: DateHelper.getDateTime(payload.time),
            type: "notification",
            position: "center",
            text: i18next.t("chatbot.actionInfo.ticketCancel"),
          })
        );
        //TODO : consider to show loading until debounce cancel ticket action
        const remoteInteractionStatus = yield select(selectRemoteInteractionStatus);
        yield put(
          setRemoteInteractionStatus({
            ...remoteInteractionStatus,
            updateTime: DateHelper.getDateTime(),
            typing: false,
          })
        );
        yield put(setChatDisabledStatus(false));
      } else if (type === at.WS_IN_ACTION_SUBMIT_FEEDBACK) {
        yield put(
          appendMessage({
            id: id,
            senderId: sender_id,
            messageTime: DateHelper.getDateTime(payload.time),
            type: "notification",
            position: "center",
            text: i18next.t("chatbot.actionInfo.feedbackCreated"),
          })
        );
      } else if (type === at.WS_IN_ACTION_CANCEL_QUEUE) {
        const remoteInteractionStatus = yield select(selectRemoteInteractionStatus);
        yield put(
          setRemoteInteractionStatus({
            ...remoteInteractionStatus,
            updateTime: DateHelper.getDateTime(),
            typing: false,
          })
        );
        yield put(setChatDisabledStatus(false));
      } else if (type === at.WS_IN_ACTION_INACTIVITY_TIMEOUT) {
        if (history) return;
        yield put(setInteractionModal(false));
        ChatTimeoutCountdownModal.hide();
        const timeoutMs = Math.ceil(Math.abs(actionPayload.remaining_time) * 60 * 1000);
        if (actionPayload.timeout_level === 1) {
          const date = DateHelper.getDateTime();
          const ceilMs = Math.ceil(timeoutMs / 1000) * 1000;

          const timeoutDate = date.add(ceilMs, "ms");
          const diffSecond = timeoutDate.diff(DateHelper.getDateTimeLocal(), "seconds");
          const formattedTimeoutText = DateHelper.formatMoment(diffSecond, "s", "h:mm");
          yield call(
            AlertHelper.show,
            i18next.t("chatbot.actionInfo.inactivityTimeoutLevel1").format(formattedTimeoutText),
            "warning"
          );
        } else if (actionPayload.timeout_level > 1) {
          yield put(setInteractionModal(true));
          const { dialog, timeout } = yield race({
            dialog: call(ChatTimeoutCountdownModal.show, { timeMs: timeoutMs }),
            timeout: delay(timeoutMs * 1000),
          });
          // If timeout occurred or dialog terminated by user
          // if (timeout || dialog) {
          //   const welcomeOptions = yield select(selectWelcomeOptions);
          //   const isLogged = yield select(selectIsLogged);
          //   if (!isLogged) return;

          //   // Send double disconnect request to ignore feedback if needed
          //   // yield put(disconnectRequest("timeout-click"));
          //   yield put(disconnectRequest("timeout-click"));
          //   yield call(DialogHelper.close);
          //   if (!welcomeOptions?.start_anonymous_chat && timeout) {
          //     AlertHelper.show(i18next.t("chatbot.chatSceneTexts.inactiveMessageInfo"), "warning", {
          //       autoHideDuration: 5000,
          //     });
          //   }
          //   // Cancel timeout countdown
          // } else
          if (!dialog) {
            const currentInteractionStatus = yield select(selectInteractionStatus);
            //send current interaction status to server to reset timeout
            yield put(wsOut.interactionStatus(currentInteractionStatus));
            yield put(setInteractionModal(false));
            ChatTimeoutCountdownModal.hide();

            // Close timeout modal from other tabs if exists
            yield call(closeTimeoutBroadcast);
          }
        }
      } else if (type === at.WS_IN_ACTION_SUBMIT_VALIDATION_RESULT) {
        yield put(setSubmitValidationResult(actionPayload));
      } else if (type === at.WS_IN_ACTION_CHANGE_LANGUAGE) {
        if (actionPayload?.language) {
          i18next.changeLanguage(actionPayload?.language.toLowerCase());
        }
        const remoteInteractionStatus = yield select(selectRemoteInteractionStatus);
        yield put(
          setRemoteInteractionStatus({
            ...remoteInteractionStatus,
            updateTime: DateHelper.getDateTime(),
            typing: false,
          })
        );
        yield put(setChatDisabledStatus(false));
      } else if (type === at.WS_IN_ACTION_TRANSFER_AGENT) {
        // const testPayload = {
        //   name: "Mstf",
        //   agent_id: 5,
        //   profile_image: "",
        //   previous_process_note: "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
        // };
        // const remoteInteractionStatus = yield select(selectRemoteInteractionStatus);
        // yield put(
        //   setRemoteInteractionStatus({ ...remoteInteractionStatus, updateTime: DateHelper.getDateTime(), typing: false })
        // );
        // yield put(setChatDisabledStatus(false));
        yield put(changeSender("AGENT"));
        yield put(changeSenderData(actionPayload));
        yield put(setViewModeDelayed("chat"));
        yield put(
          appendMessage({
            id: id,
            type: "notification",
            position: "center",
            messageTime: DateHelper.getDateTime(payload.time),
            text: function () {
              return (
                <JoinedMessage
                  isAgent={true}
                  messageTime={DateHelper.getDateTime(payload.time)}
                  name={actionPayload.name}
                />
              );
            },
          })
        );
      }
      //  else if (type === at.WS_IN_ACTION_DIRECT_TO_OFFLINE) {
      //   yield put(changeSender(null));
      //   yield put(setViewModeDelayed("offline"));
      // }
      yield put(setLastOutgoingMsg(null));
    } else if (type === at.WS_IN_QUEUE) {
      yield put(appendRawMessage(payload));
      const queueStatus = yield select(selectQueueStatus);
      yield put(setQueueStatus({ ...queueStatus, ...payload.payload }));
      if (payload.payload.done) {
        yield put(setViewModeDelayed("chat"));
      }
    } else if (type === at.WS_IN_INTERACTION_STATUS) {
      // const interactionStatus = yield select(selectInteractionStatus);
      const newInteractionStatus = {
        //  ...interactionStatus,
        ...payload.payload,
        updateTime: DateHelper.getDateTime(),
      };
      yield put(setRemoteInteractionStatus(newInteractionStatus));
    } else if (type === at.WS_IN_HISTORY) {
      let historyList = payload.payload;

      // #region Back compatibility backend bug fix. Remove after fix on backend
      if (Array.isArray(historyList?.[0]) && !isNaN(historyList?.[1]) && historyList?.length === 2) {
        historyList = historyList[0];
      }
      // #endregion

      historyList = historyList.map((item) => ({
        ...item,
        messageTime: DateHelper.getDateTime(item.created),
      }));

      // const messages = yield select(selectMessages);
      const rawMessages = yield select(selectRawMessages);
      const latestMessageId = rawMessages?.[rawMessages?.length - 1]?.payload?.id;
      const latestTimeStr = rawMessages?.[rawMessages?.length - 1]?.time;

      const latestHistoryId = historyList?.[historyList?.length - 1]?.history_id;
      const latestHistoryObjectId = historyList?.[historyList?.length - 1]?.history_object?.id;
      const latestHistoryTimeStr = historyList?.[historyList?.length - 1]?.created;

      if (
        (latestMessageId && [latestHistoryObjectId, latestHistoryId].includes(latestMessageId)) ||
        (latestTimeStr && latestTimeStr === latestHistoryTimeStr)
      ) {
        //TODO: Backend sending multiple times same history when open in new tab. Remove this after fix on backend
        return;
      }

      for (const socketMsg of historyList) {
        //TODO: remove `inactivity_timeout` from history to avoid show timeout dialog on resume chat. This message could be removed from server side
        if (socketMsg?.history_object?.type === "inactivity_timeout") continue;

        const newPayload = {
          historyId: socketMsg.history_id,
          time: socketMsg.created,
          message_time: socketMsg.created,
          type: socketMsg.history_type?.toLowerCase(),
          payload: socketMsg.history_object,
          id: socketMsg.history_id || socketMsg.id,
        };
        const socketAction = socketMessageToAction(newPayload);
        if (!socketAction) {
          console.warn("Incoming message action is null and not handled when restoring history. Raw Data:", newPayload);
          continue;
        }
        socketAction.history = true;
        store.dispatch(wsActionChannelReceived(socketAction));
      }
    }
  } catch (error) {
    console.error("Error on handleSocketIncoming", error);
  }
}

export function* handleSocketOutgoing({ type, resendMessage, history, payload }) {
  /** @type {import("../../ChatSocket").default} */
  const ws = yield select(selectSocketInstance);
  if (socketTestDelay > 0) {
    yield delay(socketTestDelay);
  }
  // if (process.env.NODE_ENV === "development") {
  //   if (type !== at.WS_OUT_PING && type !== at.WS_OUT_INTERACTION_STATUS) {
  //     console.log("Socket Outgoing 🔹 =", type, payload);
  //   }
  // }

  if (resendMessage) {
    yield put(setLastOutgoingMsg(null));

    const remoteInteractionStatus = yield select(selectRemoteInteractionStatus);
    yield put(
      setRemoteInteractionStatus({ ...remoteInteractionStatus, updateTime: DateHelper.getDateTime(), typing: false })
    );
  } else if (type.startsWith(at.WS_OUT_ACTION) || type === at.WS_OUT_MESSAGE) {
    yield put(setLastOutgoingMsg({ type, payload }));
  }

  if (type === at.WS_OUT_MESSAGE) {
    const pendingMessage = {
      ...payload?.payload,
      message_time: DateHelper.getDateTime(),
      sender_type: "CUSTOMER",
      format: "plain",
      id: 0,
      isSent: false,
    };

    const socketMessage = socketMessageToState(pendingMessage);
    yield put(appendMessage(socketMessage));
  } else if (type === at.WS_OUT_ACTION_SUBMIT_INPUT_REQUEST) {
    yield put(setButtonSelectLoading(true));
    yield put(setChatDisabledStatus(true));
  } else if (type === at.WS_OUT_ACTION_TERMINATE_SESSION) {
    // const welcomeOptions = yield select(selectWelcomeOptions);
    // yield put(setViewModeDelayed(welcomeOptions.start_anonymous_chat ? "chat" : "welcome"));
    // yield put(
    //   appendMessage({
    //     id: payload?.id,
    //     messageTime: DateHelper.getDateTime(payload.time),
    //     type: "notification",
    //     position: "center",
    //     text: `${i18next.t("chatbot.chatSceneTexts.sessionClosed")}`,
    //   })
    // );
    // yield put(disconnectDone());
  } else if (type === at.WS_OUT_ACTION_BUTTON_SELECT) {
    // const messages = yield select(selectMessages);
    // const lastMessage = messages[messages.length - 1];
    // //TODO: Remove redundant lines except last when backend fix
    // const choiceValue = payload?.payload?.payload?.value?.payload?.payload?.value || payload?.payload?.payload?.value;
    // if (lastMessage?.choices) {
    //   // let selectedItem;
    //   const newChoices = lastMessage.choices.map((choice) => {
    //     if (choice.value === choiceValue) {
    //       // selectedItem = choice;
    //       return {
    //         ...choice,
    //         isSelected: true,
    //       };
    //     }
    //     return choice;
    //   });
    //   yield put(updateMessage(lastMessage, { ...lastMessage, choices: newChoices }));
    // }

    yield put(setButtonSelectLoading(true));
  } else if (type === at.WS_OUT_INTERACTION_STATUS) {
    yield put(setInteractionModal(false));
    ChatTimeoutCountdownModal.hide();
    yield call(closeTimeoutBroadcast);
  }
  if (!history) {
    ws?.send(payload);
  } else {
    console.log("Skipped sending message due to history mode", payload);
  }
}
