import ConstantHelper from "~helpers/ConstantHelper";
import Utils from "~helpers/Utils";

export const organizationMemberFields = {
  STRING: "STR",
  INTEGER: "INT",
  STRING_ARRAY: "STR_ARR",
  INTEGER_ARRAY: "INT_ARR",
  USER: "USER",
  DATE: "DATE",
  EMAIL: "EMAIL",
  FLOAT: "FLOAT",
  PHONE_NUMBER: "PHONE_NUMBER",
  BOOL: "BOOL",
};

export const organizationMemberVisibility = {
  MEMBER_ONLY: "MEMBER_ONLY",
  PROJECT_OWNER: "PROJECT_OWNER",
  AGENT: "AGENT",
  COMPANY: "COMPANY",
  PUBLIC: "PUBLIC",
};

export const organizationComparisonTypes = {
  GTE: "GTE",
  GT: "GT",
  LTE: "LTE",
  LT: "LT",
  EQ: "EQ",
  EXACT: "EXACT",
  STR_CONTAINS: "CONTAINS",
  ICONTAINS: "ICONTAINS",
  ISTARTSWITH: "ISTARTSWITH",
  STARTSWITH: "STARTSWITH",
  ARRAY_CONTAINS: "IN",
};

// export const organizationActionTypes = {
//   TELEGRAM: "TEL",
//   WHATSAPP: "WP",
//   TWILIO: "TWL",
//   SMS: "SMS",
//   EMAIL: "EMAIL",
//   PUSH_NOTIFICATION: "PUSH_NOTIFICATION",
// };

export const [organizationActionTypes, organizationActionTypesOptions, organizationActionTypesMap] =
  ConstantHelper.typify({
    EMAIL: "Email",
    SMS: "SMS",
    // TWL: "Twilio",
    WHATSAPP: "WhatsApp",
    TELEGRAM: "Telegram",
    PUSH_NOTIFICATION: "Push Notification",
  });

export const organizationResource = {
  INSIDE: "INSIDE",
  PUBLIC: "PUBLIC",
};

export const organizationFilterType = {
  AND: "AND",
  OR: "OR",
  COMPARISON: "COMPARISON",
};

export const organizationComparisonOptions = [
  // { label: "Exact", value: organizationComparisonTypes.EXACT },
  { label: "Equals", value: organizationComparisonTypes.EQ },
  { label: "Contains", value: organizationComparisonTypes.STR_CONTAINS },
  { label: "Contains [i]", value: organizationComparisonTypes.ICONTAINS },
  { label: "Starts with", value: organizationComparisonTypes.STARTSWITH },
  { label: "Starts with [i]", value: organizationComparisonTypes.ISTARTSWITH },
  { label: "Less Than", value: organizationComparisonTypes.LT },
  { label: "Less Than or Equal", value: organizationComparisonTypes.LTE },
  { label: "Greater Than", value: organizationComparisonTypes.GT },
  { label: "Greater Than or Equals", value: organizationComparisonTypes.GTE },
  { label: "Array Contains", value: organizationComparisonTypes.ARRAY_CONTAINS },
];

export const organizationVisibilityType = {
  MEMBER_ONLY: "MEMBER_ONLY",
  PROJECT_OWNER: "PROJECT_OWNER",
  AGENT: "AGENT",
  COMPANY: "COMPANY",
  PUBLIC: "PUBLIC",
};

export const organizationQuestionType = {
  /** @type {"TEXT"} */
  TEXT: "TEXT",
  /** @type {"RANGE"} */
  RANGE: "RANGE",
  /** @type {"CHOICE"} */
  CHOICE: "CHOICE",
};

export const organizationQuestionTypeMap = {
  [organizationQuestionType.TEXT]: "Text",
  [organizationQuestionType.RANGE]: "Range",
  [organizationQuestionType.CHOICE]: "Choice",
};

export const [organizationSurveyType, organizationSurveyTypeOptions, organizationSurveyTypeMap] = ConstantHelper.typify(
  {
    ANONYMOUS: "Anonymous",
    PUBLIC: "Familiar",
    IDENTIFIED: "Authenticated",
  }
);

export const organizationResourceType = {
  INSIDE: "INSIDE",
  PUBLIC: "PUBLIC",
};

export const organizationResourceTypeOptions = [
  { label: Utils.keyToCapital(organizationResourceType.INSIDE), value: organizationResourceType.INSIDE },
  { label: Utils.keyToCapital(organizationResourceType.PUBLIC), value: organizationResourceType.PUBLIC },
];

export const organizationDayOptions = [
  { label: "Monday", value: 0 },
  { label: "Tuesday", value: 1 },
  { label: "Wednesday", value: 2 },
  { label: "Thursday", value: 3 },
  { label: "Friday", value: 4 },
  { label: "Saturday", value: 5 },
  { label: "Sunday", value: 6 },
];

export const [
  organizationAnnouncementActionType,
  organizationAnnouncementActionTypeOptions,
  organizationAnnouncementActionTypeMap,
] = ConstantHelper.typify({
  TELEGRAM: "Telegram",
  WHATSAPP: "WhatsApp",
  TWILIO: "Twilio",
  SMS: "SMS",
  EMAIL: "Email",
  PUSH_NOTIFICATION: "Push Notification",
});

export const [
  organizationAnnouncementFilterType,
  organizationAnnouncementFilterTypeOptions,
  organizationAnnouncementFilterTypeMap,
] = ConstantHelper.typify({
  ID_LIST: "Customer List",
  LOW_CODE: "Low Code",
});

// export const [
//   organizationAnnouncementStatus,
//   organizationAnnouncementStatusOptions,
//   organizationAnnouncementStatusMap,
// ] = ConstantHelper.typify({
//   SCHEDULED: "Scheduled",
//   SENDING: "Sending",
//   PUBLISHED: "Published",
// });

export const [
  organizationAnnouncementStatus,
  organizationAnnouncementStatusOptions,
  organizationAnnouncementStatusMap,
] = ConstantHelper.typify({
  PROCESSING: "Processing",
  DONE: "Done",
  SCHEDULED: "Scheduled", //cancel
  DRAFT: "Draft",
  WAITING: "Waiting", //cancel
});

export const [
  organizationAnnouncementPeriodicStatus,
  organizationAnnouncementPeriodicStatusOptions,
  organizationAnnouncementPeriodicStatusMap,
] = ConstantHelper.typify({
  ACTIVE: "Active",
  INACTIVE: "Inactive",
});
