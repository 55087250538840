import { put, select } from "redux-saga/effects";

import { chatbotSender } from "~constants";
import DateHelper from "~helpers/DateHelper";
import LoadingHelper from "~helpers/LoadingHelper";

import {
  getTokenAndStartChatSession,
  setChatDisabledStatus,
  setMinimizedStatus,
  setQueryOptions,
  wsOut,
} from "../actions";
import {
  selectInitialized,
  selectMessages,
  selectMinimized,
  selectOnlineStatus,
  selectQueryOptions,
  selectRemoteInteractionStatus,
  selectSender,
  selectSessionStatus,
  selectViewMode,
} from "../selectors";

export default function* domHandler({ type: _, payload }) {
  const event = payload?.event;
  const args = payload?.args;
  if (event === "postText") {
    const queryOptions = yield select(selectQueryOptions);
    const minimizedStatus = yield select(selectMinimized);
    const viewMode = yield select(selectViewMode);
    const initialized = yield select(selectInitialized);
    const msgPayload = args[0];
    const sender = yield select(selectSender);

    //#region check if typing status should be sent or not
    const sessionStatus = yield select(selectSessionStatus);
    const onlineStatus = yield select(selectOnlineStatus);
    const remoteInteractionStatus = yield select(selectRemoteInteractionStatus);
    const messages = yield select(selectMessages);
    const getMessages = () => {
      const sortedMessages = [...messages];
      sortedMessages?.sort((a, b) => {
        const dateA = DateHelper.getDateTime(a.messageTime);
        const dateB = DateHelper.getDateTime(b.messageTime);
        return dateA.isAfter(dateB) ? 1 : -1;
      });
      return sortedMessages;
    };
    const mappedMessages = getMessages();
    const lastMessage = mappedMessages[mappedMessages.length - 1];

    const showTypingStatus =
      !["closed", "connecting"].includes(sessionStatus) &&
      onlineStatus !== "disconnected" &&
      onlineStatus !== "closed" &&
      !!messages?.length &&
      remoteInteractionStatus.typing !== false &&
      lastMessage?.inputFormat?.type !== "form";

    if (showTypingStatus) return;
    //#endregion

    if (minimizedStatus) {
      yield put(setMinimizedStatus(false));
    }
    if (viewMode === "disconnected") {
      yield put(
        getTokenAndStartChatSession(
          queryOptions.token,
          {
            identifier: queryOptions.identifier,
          },
          queryOptions.dynamicOptions
        )
      );
      //   setButtonDisabled(true);
      LoadingHelper.open();
    }
    // else {
    //   yield put(setViewMode("welcome"));
    // }
    //   return;

    if (initialized && viewMode !== "disconnected") {
      yield put(wsOut.message(msgPayload));
    } else {
      yield put(
        setQueryOptions({
          ...queryOptions,
          question: msgPayload,
        })
      );
    }

    if (sender === chatbotSender.Bot) {
      yield put(setChatDisabledStatus(true));
    }
  }
}
