import {
  LS_WEBCHAT_TOKEN,
  LS_WEBCHAT_TOKEN_EXPIRE,
  popupChatHeightDefault,
  popupIconMarginDefault,
  popupIconSizeDefault,
  popupIconTypeMap,
  popupLottieOffsetXDefault,
  popupLottieOffsetYDefault,
  popupPosition,
  popupTriggerType,
} from "~constants";
import { safeProduce as produce } from "~helpers/immer";
import StorageHelper from "~helpers/StorageHelper";
import TokenHelper from "~helpers/TokenHelper";

import * as at from "./actionTypes";

/**
 * @typedef {{
 *   type: "text" | "image" | "file" | "choice" | "inputRequest";
 *   text: string;
 *   imageUrl?: string;
 *   fileUrl?: string;
 *   fileName?: string;
 *   uuid?: string;
 *   fileType?: "oth" | "doc" | "vid" | "img" | "aud";
 *   fileList?: File[];
 *   inputType?: "file" | "image" | "pdf";
 *   inputFormat?: {
 *     type: "file" | "date" | "date_range" | "form" | "dropdown";
 *     format: "date" | "datetime" | "time";
 *     range: [[], []];
 *     payload: any;
 *     data?: any;
 *     disabled?: boolean;
 *   };
 *   choices?: { button_type: "select" | "link"; text: string; type: "button"; value: number }[];
 *   position: "left" | "right" | "center";
 *   messageTime: string | moment.Moment;
 *   disconnect?: boolean;
 *   volatile?: boolean; // This represents the file newly created by the user and will be overwritten by some of response props like the fileUrl once the file is uploaded
 *   source?: "history" | null;
 *   sender?: "BOT" | "AGENT";
 * }} ChatMessage
 */

/**
 * @typedef {{
 *   id: number;
 *   session_id?: number;
 *   type: "text" | "image" | "file" | "choice" | "date";
 *   sender_type: "agent" | "bot" | "system" | "customer";
 *   sender: number;
 *   sender_name: string;
 *   profile_image: string;
 *   format: "markdown" | "html" | "plain";
 *   text: string;
 *   choice_value?: number;
 *   feedback: Feedback;
 *   images: Image[];
 *   files: SocketFile[];
 *   choices: Choice[];
 * }} WsChatMessage
 */

/**
 * @typedef {{
 *   enable: boolean;
 *   type: "thumbs" | "stars" | "smileys";
 *   value: number;
 * }} Feedback
 */

/**
 * @typedef {{
 *   id: number;
 *   url: string;
 *   name: string;
 * }} Image
 */

/**
 * @typedef {{
 *   id: number;
 *   type: "image" | "file";
 *   url: string;
 *   name: string;
 * }} SocketFile
 */

/**
 * @typedef {{
 *   value: number;
 *   text: string;
 * }} Choice
 */

/**
 * @typedef {{
 *   title: string;
 *   data: FeedbackQuestion[];
 * }} FeedbackInfo
 */

/**
 * @typedef {{
 *   id: number;
 *   question: string;
 *   type: string;
 * }} FeedbackQuestion
 */
/**
 * @typedef {{
 *   input_format: {
 *     type: string;
 *     id: string;
 *     options: any[];
 *     inline: boolean;
 *     description: string;
 *     fields: InputRequestField[];
 *     current_value: any;
 *   };
 * }} InputRequestRoot
 */

/**
 * @typedef {{
 *   question: string;
 *   format: InputRequestFormat;
 * }} InputRequestField
 */

/**
 * @typedef {{
 *   type: string;
 *   id: string;
 *   options: InputRequestOption[];
 *   current_value: InputRequestOption;
 * }} InputRequestFormat
 */

/**
 * @typedef {{
 *   machine_data: string;
 *   human_data: string;
 * }} InputRequestOption
 */

/** @typedef {"welcome" | "chat" | "queue" | "ticket" | "disconnected" | "maintenance" | "form" | "offline"} ViewMode */
export const initState = {
  welcomeMsgShowed: false,
  minimized: true,
  minimizedDone: true,
  welcomeOptions: {
    show_queue_order: true,
    show_thumps_up: true,

    form_items: [],
    popup_title: "", //
    popup_primary_color: "", //#2baae1
    // popup_primary_color_optimized: "", // Calculated value from popup_primary_color
    /** @deprecated */
    popup_button_type: "", //DF(default), IC(icon)
    popup_button_icon_path: null,

    /** @deprecated */
    popup_header_icon_type: popupIconTypeMap.default,
    popup_header_icon_path: "", //https://public.palmate.ai/api/media/popup_files/15/Bo%C4%9Fazi%C3%A7i_%C3%9Cniversitesi_Logo_256.png

    popup_bot_icon_type: popupIconTypeMap.default,
    popup_bot_icon_path: "",

    welcome_msg: "", //minified popup hint message
    popup_welcome_text: "", //card title
    start_anonymous_chat: false,

    chatbot_info: {
      avatar_visibility: null,
      footer_mode: null,
      speech_recognition_mode: null,
      speech_recognition_auto_send: false,
    },
    system_chatbot_info: {
      enable_footer_customization: false,
    },

    /**
     * @type {{
     *   code: string;
     *   name: string;
     *   default: boolean;
     * }[]}
     */
    languages: [],
    popup_style_info: {
      contrast_color: "#fffff",
      title_color: "#ffffff",
      primary_light: null,
      primary_dark: null,

      /** @type {keyof typeof import("~constants").popupIconTypeMap} */
      popup_header_icon_type: popupIconTypeMap.default,
      /** @type {keyof typeof import("~constants").popupIconTypeMap} */
      popup_icon_type: popupIconTypeMap.default,
      /** @type {keyof typeof import("~constants").popupPositionMap} */
      popup_position: popupPosition.bottomRight,

      popup_icon_size: popupIconSizeDefault,

      /** @deprecated Use popup_margin_x and popup_margin_y instead and remove popup_margin on save */
      popup_margin: popupIconMarginDefault,
      popup_margin_x: popupIconMarginDefault,
      popup_margin_y: popupIconMarginDefault,
      chat_height: popupChatHeightDefault,
      lottie_width: popupIconSizeDefault,
      lottie_height: popupIconSizeDefault,
      lottie_offset_x: popupLottieOffsetXDefault,
      lottie_offset_y: popupLottieOffsetYDefault,
      lottie_loop_delay: 0,
      lottie_loop_speed: 1,
      popup_ballon_timeout: 0,
      popup_trigger_type: popupTriggerType.instant,
      popup_trigger_type_payload: 0,
    },

    /**
     * @type {{
     *   key: keyof typeof import("~constants").projectTranslateKey;
     *   platform: keyof typeof import("~constants").projectTimeoutTimes | "default";
     *   language: keyof typeof import("~constants").projectLanguage;
     *   value: string;
     *   format: keyof typeof import("~components/Generic/PRInput").PRTextAreaFormat;
     * }[]}
     */
    static_messages: null, //dynamic field
  },
  welcomeFormData: null,
  dropzone: {
    allowDrop: false,
    fileList: [],
    inputRef: null,
  },
  // #region Socket Related States
  /** @type {typeof ChatSocket} */
  socketInstance: null,
  remoteInteractionStatus: {
    updateTime: null,
    typing: false,
    timeout: 0,
  },
  interactionStatus: {
    typing: false,
    focused: false,
    location: "",
    time: 0,
  },
  interactionModal: false,
  isLogged: false,
  hasTimeout: false,
  maintenanceMode: false,

  loginTryCount: 0,
  /** @type {"closed" | "connected" | "reconnected" | "disconnected"} */
  onlineStatus: "closed",
  session: {
    token: "",
    expire: "",
  },
  /** @type {"init" | "connecting" | "new" | "resume" | "closing" | "closed"} */
  sessionStatus: "init",
  // login: {
  //   /** @deprecated */
  //   msg: "",
  //   /** @deprecated */
  //   type: "",

  //   /** @type {"new" | "DC"} */
  //   session_status: "",
  //   /** @type {"SUCCESS" | "FAIL"} */
  //   status: "",
  //   ping_interval:0,
  //   time_until_expiration:0,

  // },
  connectionOptions: {
    expires_in: 900, //900
    ping_interval: 10, //5
    time_until_expiration: 60, //60 14min + 60s warning
  },
  queueStatus: {
    order: 0,
    eta: 0,
    done: false,
    sender_type: null, // "AGENT" | "BOT"
    bot_joined: false,
    ticket_enabled: true,
    direct_to_ticket_enabled: true,
    available_agents: false,
  },
  agentStatus: {
    online_agent: 0,
  },
  /** @type {FeedbackInfo} */
  feedbackInfo: null,
  botInfo: null,
  agentInfo: null,
  /** @type {ChatMessage[]} */
  messages: [],
  rawMessages: [],
  /** @type {"BOT" | "AGENT" | null} */
  sender: null,
  senderData: {
    id: 0,
    agent_id: 0,
    name: "",
    profile_image: "",
    previous_process_note: "",
  },
  ticketForm: null,
  /** @type {"mobile" | "desktop"} */
  displayMode: "desktop",
  /** @type {ViewMode} */
  viewMode: "chat",
  /** @type {ViewMode} */
  previousViewMode: "chat",
  queryOptions: {
    previewMode: true,
    disableScroll: false,
    identifier: "",
    token: "",
    /** @type {"inline" | ""} */
    mode: "",
    disableHeader: false,
    disableFooter: false,
    disableMicrophone: false,
    disableAttachment: false,
    mobileViewWidth: undefined, //default 768
    dynamicOptions: {},
  },
  lastMessageTime: null,
  lastPongTime: null, // TODO: create a saga task to check if the connection is still alive
  /** @type {"token_and_start" | "resume"} */
  startChatSessionReason: null,
  chatDisabled: false,
  lastOutgoingMsg: null,
  submitValidationResult: {},
  buttonSelectLoading: false,
  seenMessageInfo: {},
  /** @type {InputRequestRoot} */
  inputRequestInfo: null,
  initialized: false, // true after bot initial message receives (debounced) finished on reconnect or new session
  // #endregion
};
let id = 1;
function getId() {
  return id++;
}
const chatbot = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.WS_CONNECT:
        // draft.socketInstance = null;
        break;
      case at.WS_CONNECT_FAIL:
        // draft.socketInstance = null;
        draft.onlineStatus = "disconnected";
        break;
      case at.WS_CONNECT_SUCCESS:
        // draft.socketInstance = action.payload;
        // if (state.onlineStatus === "disconnected") {
        //   draft.onlineStatus = "reconnected";
        // } else {
        //   draft.onlineStatus = "connected";
        // }
        break;
      case at.WS_DISCONNECT:
        // draft.socketInstance = null;
        draft.onlineStatus = "closed";
        break;
      case at.DISCONNECT_DONE: {
        draft.feedbackInfo = null;
        draft.login = initState.login;
        draft.isLogged = false;
        draft.hasTimeout = false;
        draft.sessionStatus = "closed";
        draft.onlineStatus = "closed";
        draft.chatDisabled = false;
        draft.agentInfo = null;
        draft.sender = null;
        const palmateTkn = TokenHelper.getWebchatProjectToken();
        const alias = TokenHelper.getChatbotAlias();
        StorageHelper.remove(LS_WEBCHAT_TOKEN.format(palmateTkn || alias));
        StorageHelper.remove(LS_WEBCHAT_TOKEN_EXPIRE.format(palmateTkn || alias));
        break;
      }
      case at.SET_INITIALIZED:
        draft.initialized = action.payload;
        break;
      case at.SET_ONLINE_STATUS:
        draft.onlineStatus = action.payload;
        break;
      case at.SET_WELCOME_OPTIONS:
        // draft.welcomeOptions = popupSettingsMapper(action.payload);
        draft.welcomeOptions = action.payload;
        // if (action.payload?.popup_primary_color) {
        //   draft.welcomeOptions.popup_primary_color_optimized = Utils.getColorAsContrastOptimized(
        //     action.payload.popup_primary_color
        //   );
        // }
        // window.popup ??= {};
        // window.popup.popup_style_info = draft.welcomeOptions.popup_style_info;

        break;
      case at.SET_DISPLAY_MODE:
        draft.displayMode = action.payload;
        break;
      case at.SET_VIEWMODE:
        if (draft.viewMode !== action.payload) {
          draft.previousViewMode = draft.viewMode;
        }
        draft.viewMode = action.payload;
        if (draft.maintenanceMode && !draft.queryOptions.previewMode) {
          draft.viewMode = "maintenance";
        }
        break;
      case at.SET_SOCKET_INSTANCE:
        draft.socketInstance = action.payload;
        break;

      case at.APPEND_RAW_MESSAGE:
        draft.rawMessages.push(action.payload);
        break;
      case at.APPEND_MESSAGE:
        let newMessage = {
          id: getId(),
          ...action.payload,
          isSent: action.payload.isSent !== false,
        };
        let lastMessage = draft.messages[draft.messages.length - 1];

        if (lastMessage?.isSent === false) {
          draft.messages.pop();
          newMessage = {
            ...lastMessage,
            ...newMessage,
            id: lastMessage.id || newMessage.id || getId(),
          };
        }
        draft.messages.push(newMessage);
        break;
      case at.APPEND_MESSAGE_BEGIN:
        const messageItem = {
          id: getId(),
          ...action.payload,
        };
        draft.messages.unshift(messageItem);
        break;
      case at.UPDATE_MESSAGE:
        const { old, newly } = action.payload;
        draft.messages = state.messages.map((msg) => {
          if (msg === old) {
            return newly;
          }
          return msg;
        });
        break;
      case at.SET_MESSAGE_LIST:
        const msgList = action.payload.map((msg) => {
          return {
            id: getId(),
            ...msg,
          };
        });
        draft.messages = msgList;
        break;
      case at.APPEND_NOTIFICATION:
        draft.notifications.push(action.payload);
        break;
      case at.CHANGE_SENDER:
        draft.sender = action.payload;
        break;
      case at.CHANGE_SENDER_DATA:
        draft.senderData = action.payload;
        break;
      case at.SET_LAST_MESSAGE_TIME:
        draft.lastMessageTime = action.payload;
        break;
      case at.SET_SEEN_MESSAGE_INFO:
        draft.seenMessageInfo = { ...draft.seenMessageInfo, ...action.payload };
        break;
      // case at.SET_CHATBOT_INFO:
      //   draft.botInfo = action.payload;
      //   break;
      // case at.SET_AGENT_INFO:
      //   draft.agentInfo = action.payload;
      //   break;
      // case at.SET_AGENT_STATUS:
      //   draft.agentStatus = action.payload;
      //   break;
      case at.SET_TICKET_FORM:
        draft.ticketForm = action.payload;
        break;
      case at.CLEAR_TICKET_FORM:
        draft.ticketForm = null;
        break;
      case at.SET_TIMEOUT_STATUS:
        draft.hasTimeout = action.payload;
        break;
      case at.SET_QUEUE_STATUS:
        draft.queueStatus = action.payload;
        break;
      case at.SET_PONG:
        draft.lastPongTime = action.payload;
        break;
      case at.START_CHAT_SESSION: {
        // const palmateTkn = TokenHelper.getWebchatProjectToken();
        // StorageHelper.set(LS_WEBCHAT_TOKEN.format(palmateTkn), action.payload?.session_token);
        // StorageHelper.set(LS_WEBCHAT_TOKEN_EXPIRE.format(palmateTkn), action.payload?.expires_in);
        // draft.session = {
        //   token: action.payload?.session_token,
        //   expire: action.payload?.expires_in,
        // };
        draft.startChatSessionReason = action.payload?.reason;
        break;
      }
      case at.SET_CHAT_DISABLED_STATUS:
        draft.chatDisabled = action.payload;
        break;
      case at.SET_MINIMIZED_STATUS:
        draft.minimized = action.payload;
        break;
      case at.SET_MINIMIZED_DONE_STATUS:
        draft.minimizedDone = action.payload;
        break;
      case at.SET_WELCOME_MSG_SHOWED_STATUS:
        draft.welcomeMsgShowed = action.payload;
        break;
      case at.SET_SESSION_STATUS:
        draft.sessionStatus = action.payload;
        break;
      case at.SET_QUERY_OPTIONS:
        draft.queryOptions = action.payload;
        break;
      case at.SET_MAINTENANCE_STATUS:
        draft.maintenanceMode = action.payload;
        break;
      case at.SET_CONNECTION_OPTIONS:
        draft.connectionOptions = action.payload;
        break;
      // case at.SEND_MSG:
      //   draft.lastSocketDirection = "out";
      //   draft.lastOutgoingMsg = action.payload;
      //   break;
      case at.SET_LAST_OUTGOING_MSG:
        draft.lastOutgoingMsg = action.payload;
        break;
      // case at.ON_MESSAGE:
      //   draft.lastSocketDirection = "in";
      //   if (action.payload?.type === "reply_message") {
      //     draft.lastOutgoingMsg = null;
      //   }
      //   break;
      case at.SET_FEEDBACK_INFO:
        draft.feedbackInfo = action.payload;
        break;
      case at.SET_WELCOME_FORM_DATA:
        draft.welcomeFormData = action.payload;
        break;
      case at.DISCONNECT_REQUEST:
        draft.sessionStatus = "closing";
        break;
      case at.SET_DROPZONE_ALLOW_STATUS:
        draft.dropzone.allowDrop = action.payload;
        break;
      case at.SET_DROPZONE_FILES:
        draft.dropzone.fileList = action.payload;
        break;
      case at.SET_DROPZONE_REF:
        draft.dropzone.inputRef = action.payload;
        break;
      case at.SET_IS_LOGGED:
        draft.isLogged = action.payload;
        break;
      case at.SET_INTERACTION_STATUS:
        draft.interactionStatus = action.payload;
        break;
      case at.SET_REMOTE_INTERACTION_STATUS:
        draft.remoteInteractionStatus = action.payload;
        break;
      case at.SET_INPUT_REQUEST_INFO:
        draft.inputRequestInfo = action.payload;
        break;
      case at.SET_SUBMIT_VALIDATION_RESULT:
        const { request_id, ...rest } = action.payload;
        const data = rest;

        data.options =
          data?.options?.map((item) => ({ label: item.human_data, value: item.machine_data })) ?? data.options;

        draft.submitValidationResult[request_id] = data;
        break;
      case at.SET_BUTTON_SELECT_LOADING:
        // draft.socketInstance = null;
        draft.buttonSelectLoading = action.payload;
        break;
      case at.SET_INTERACTION_MODAL:
        draft.interactionModal = action.payload;
        break;
    }
  });

export default chatbot;
