import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

export const initState = {
  statisticChatbot: null,
  weeklySessions: null,
  weeklyTickets: null,
  agentStatistics: null,
  agentTicketStatistics: null,
  ticketStatistics: null,
  genericStatistics: {},
  statistics: {},
  statisticsFilter: {
    beginDate: "2024-08-01",
    endDate: "2024-08-10",
  },
  agentSpesificStatistics: {},
  agents: [],
};

const statistics = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_CHATBOT_STATISTICS:
        draft.statisticChatbot = action.payload;
        break;
      case at.SET_WEEKLY_SESSIONS:
        draft.weeklySessions = action.payload;
        break;
      case at.SET_WEEKLY_TICKETS:
        draft.weeklyTickets = action.payload;
        break;
      case at.SET_AGENT_STATISTICS:
        draft.agentStatistics = action.payload;
        break;
      case at.SET_AGENT_TICKET_STATISTICS:
        draft.agentTicketStatistics = action.payload;
        break;
      case at.SET_TICKET_STATISTICS:
        draft.ticketStatistics = action.payload;
        break;
      case at.SET_GENERIC_STATISTICS:
        const { data = [] } = action.payload || {};
        for (const stat of data) {
          const key = stat.key;
          draft.genericStatistics[key] = stat;
        }
        break;
      case at.SET_STATISTICS:
        const data2 = action.payload || {};
        console.log(data2);
        for (const key in data2) {
          draft.statistics[key] = data2[key];
        }
        break;
      case at.SET_STATISTICS_FILTER:
        draft.statisticsFilter = action.payload;
        break;
      case at.SET_AGENT_SPESIFIC_STATISTICS:
        const { id, data: data3 } = action.payload || {};
        for (const key in data3) {
          if (!draft.agentSpesificStatistics[id]) {
            draft.agentSpesificStatistics[id] = {};
          }
          draft.agentSpesificStatistics[id][key] = data3[key];
        }

        break;
      case at.SET_AGENT_FILTER_IDS:
        draft.agents = action.payload;
        break;
    }
  });

export default statistics;
