import * as Sentry from "@sentry/react";
import _ from "lodash";
import { all, call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  audioList,
  chatbotLivechatPageRefreshIntervalMs,
  chatbotLivechatPageSize,
  chatbotLivechatPageThrottleMs,
  chatbotPingPongIntervalMs,
  chatbotSessionPlatform,
  chatbotSessionStatus,
  getChatbotPingPongMaxTimeoutMs,
  livechatCallcenterType,
  userRole,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import AudioHelper from "~helpers/AudioHelper";
import DateHelper from "~helpers/DateHelper";
import TokenHelper from "~helpers/TokenHelper";
import { clearIntervalW, setIntervalW } from "~helpers/WebworkerTimers";
import store from "~store";
import { selectCurrentProject } from "~store/user/selectors";

import {
  getAgentInfo,
  onMessage,
  sendListenAction,
  setPong,
  setLivechatLoadingStatus,
  setOnlineStatus,
  setSessions,
  setSessionStatus,
  wsAskSessionInfo,
  wsConnect,
  wsConnectFail,
  wsConnectSuccess,
  wsLogin,
  wsReconnect,
  wsSendListen,
  wsSendLogin,
  wsSendPing,
  wsSendStopListen,
} from "./actions";
import * as at from "./actionTypes";
import broadcastActionsHandler from "./broadcastActions";
import {
  selectAgentInfo,
  selectCallcenterSettings,
  selectConnectionOptions,
  selectIsLogged,
  selectLastPongTime,
  selectLivechatOptions,
  selectOnlineStatus,
  selectOtherSessionsInfo,
  selectSessions,
  selectViewStatus,
} from "./selectors";
import socketActionsHandler from "./socketActions";
import ChatSocket from "../ChatSocket";

const throttledOnFocusRefreshOtherSession = _.throttle(function () {
  const viewStatus = selectViewStatus(store.getState());
  const callcenterSettings = selectCallcenterSettings(store.getState());
  const currentProject = selectCurrentProject(store.getState());
  const isAdminLiveAgent = [userRole.admin].some((role) => currentProject?.permissions?.includes(role));
  const livechatOptions = selectLivechatOptions(store.getState());

  const livechatMode = (() => {
    if (isAdminLiveAgent) {
      return livechatOptions?.type || callcenterSettings?.callcenter_type || livechatCallcenterType.FIFO;
    }
    return callcenterSettings?.callcenter_type || livechatCallcenterType.FIFO;
  })();

  if (livechatMode === livechatCallcenterType.FIFO) return;

  if (viewStatus === "visible") {
    const { count, page } = selectOtherSessionsInfo(store.getState());
    const limit = chatbotLivechatPageSize;
    const calculatedPage = Math.ceil(count / limit);
    const minPage = Math.min(calculatedPage, page) || 1;
    const offset = (minPage - 1) * limit;

    store.dispatch(wsAskSessionInfo(limit, offset));
  }
}, chatbotLivechatPageThrottleMs);
export const throttleReloadOtherSessions = throttledOnFocusRefreshOtherSession;

let livechatFailCounter = 0;
let livechatFailCounterAlertLimit = 3;
/** @type {ChatSocket} */
let websocketInstance = null;
let initialLivechatSoundPlayed = false;
function* handleConnect({ payload } = {}) {
  const livechatOptions = yield select(selectLivechatOptions);
  if (livechatOptions.alert && !initialLivechatSoundPlayed) {
    //Play initial blank sounds to enable sound while tab is not focused.
    Promise.all([AudioHelper.get(audioList.noSound)]).then(async ([audio]) => {
      audio.replay();
      initialLivechatSoundPlayed = true;
    });
  }

  const onlineStatus = yield select(selectOnlineStatus);
  console.log("wsConnect reason:", payload);
  if (["connected", "reconnected"].includes(onlineStatus)) {
    console.log("Live Chat connect request skipped. Current status: ", onlineStatus);
    return;
  }

  try {
    const onMessageCallback = (data = {}) => {
      if (!websocketInstance) return;
      if (data.time && data.json) {
        data.json.time = data.json.message_time = DateHelper.getDateTime(data.time);
      }
      store.dispatch(onMessage(data.json || data));
    };

    const onClosedCallback = () => {
      if (!websocketInstance) return;
      store.dispatch(setSessionStatus("closed"));
    };
    const onErrorCallback = (error) => {
      console.log("Live Chat connection error", error);
      store.dispatch(wsConnectFail());
      setTimeout(() => {
        store.dispatch(wsConnect("initialErrorReconnect"));
      }, 10000);
    };
    websocketInstance?.forceClose?.();
    websocketInstance = new ChatSocket("agent", onMessageCallback, onClosedCallback, onErrorCallback);
    yield call(websocketInstance.connect);
    yield put(wsConnectSuccess());
  } catch (error) {
    yield put(wsConnectFail());
    console.log("Live Chat connect failed", error);
  }
}

function* handleDisconnect() {
  if (websocketInstance) {
    websocketInstance.close();
    websocketInstance = null;
  }
  yield put(setSessionStatus("closed"));
  console.log("LiveChat closed");
}

function* handleConnectSuccess() {
  livechatFailCounter = 0;
  yield put(wsLogin());
}

function* handleLogin() {
  const token = yield call(TokenHelper.getJwtIfValid);
  const currentProject = yield select(selectCurrentProject);
  const livechatOptions = yield select(selectLivechatOptions);
  const livechatFilters = (livechatOptions?.filters?.[currentProject?.id] || []).reduce((acc, item) => {
    if (item.type === "text") {
      acc[item.key] = item.value[0];
    } else {
      acc[item.key] = item.value;
    }
    return acc;
  }, {});

  yield put(wsSendLogin(token, "all", livechatFilters));
}

const livechatLoginAlertKey = "livechatLoginAlert";

function* handleConnectFail() {
  if (livechatFailCounter > livechatFailCounterAlertLimit) {
    AlertHelper.show("Live Chat connection failed", "error", {
      preventDuplicate: true,
      persist: true,
      key: livechatLoginAlertKey,
    });
  }

  websocketInstance = null;
  const onlineStatus = yield select(selectOnlineStatus);
  // Only try to reconnect on initial connection due ping pong task wont be started
  if (onlineStatus === "closed") {
    yield delay(5000);
    yield put(wsConnect("connectFail"));
    livechatFailCounter++;
  }
}

function* handleSendMessage({ payload }) {
  if (websocketInstance) {
    if (payload.type === "message") {
      store.dispatch(onMessage({ ...payload, payload: { ...payload.payload, sender_type: "AGENT" }, isSent: false }));
    }
    yield call(websocketInstance.send, payload);
  }
}

function* handleSendMessageListenSession({ payload }) {
  if (payload.listenType === "offline") {
    yield put(wsSendStopListen(payload.idList, payload.listenType));
  } else {
    yield put(wsSendListen(payload.idList, payload.listenType));
  }
}

let pingInterval;
function pingPongTimeoutTask() {
  pingInterval?.();
  store.dispatch(wsSendPing());

  const onlineStatus = selectOnlineStatus(store.getState());

  if (onlineStatus === "closed") {
    store.dispatch(setOnlineStatus("connected"));
  } else if (onlineStatus === "disconnected") {
    AlertHelper.close(livechatLoginAlertKey);
    AlertHelper.show("Live Chat connection established", "success", {
      preventDuplicate: true,
    });
    store.dispatch(setOnlineStatus("reconnected"));
    Sentry.captureMessage("Live Chat connection re-established", {
      level: "warning",
      extra: {
        lastPongTime: selectLastPongTime(store.getState()),
        currentTime: DateHelper.getDateTime(),
      },
    });
  }

  const connectionOptions = selectConnectionOptions(store.getState());
  const pingIntervalSec =
    connectionOptions?.ping_interval > 0 ? connectionOptions?.ping_interval : chatbotPingPongIntervalMs / 1000;
  const chatbotPingPongMaxTimeoutMs = getChatbotPingPongMaxTimeoutMs(pingIntervalSec);

  function loop() {
    const isLogged = selectIsLogged(store.getState());
    const viewStatus = selectViewStatus(store.getState());
    const lastPongTime = selectLastPongTime(store.getState());
    const onlineStatus = selectOnlineStatus(store.getState());

    if (viewStatus === "hidden") {
      AlertHelper.close(livechatLoginAlertKey);
      // return;
    }

    const previousPongDiff = DateHelper.getDateTime().diff(lastPongTime, "milliseconds");

    if (previousPongDiff > chatbotPingPongMaxTimeoutMs && !["closed"].includes(onlineStatus)) {
      store.dispatch(setOnlineStatus("disconnected"));
      AlertHelper.show("Live Chat connection failed", "error", {
        preventDuplicate: true,
        persist: true,
        key: livechatLoginAlertKey,
      });
      console.log("timeout occurred", previousPongDiff, chatbotPingPongMaxTimeoutMs);

      Sentry.captureMessage("Live Chat timeout occurred due to ping pong. Reconnecting...", {
        level: "warning",
        extra: {
          lastPongTime: selectLastPongTime(store.getState()),
          currentTime: DateHelper.getDateTime(),
        },
      });

      store.dispatch(wsReconnect());
      return;
    }

    if (isLogged) {
      store.dispatch(wsSendPing());
    }
  }

  pingInterval = setIntervalW(loop, pingIntervalSec * 1000);
}

function* loginSuccessPinPongTask() {
  //Initial pong response time
  yield put(setPong(DateHelper.getDateTime()));
  console.log("loginSuccessPinPongTask");
  pingPongTimeoutTask();
  try {
    while (true) {
      yield delay(1000);
      //break when logged out
      const isLogged = selectIsLogged(store.getState());
      //break if saga is cancelled
      // const cancelled = yield cancelled();
      if (!isLogged) {
        console.log("loginSuccessPinPongTask ERR due not logged in");
        break;
      }
    }
  } catch (error) {
    console.log("loginSuccessPinPongTask ERR", error);
  }
  clearIntervalW(pingInterval);
}

function* loginSuccessGetOtherSocketsTask() {
  //TASK:project_based_livechat
  // const currentProject = yield select(selectCurrentProject);
  // yield put(wsSetProject(currentProject.id));
  while (true) {
    throttledOnFocusRefreshOtherSession();
    yield delay(chatbotLivechatPageRefreshIntervalMs);
  }
}

function* handleReconnect() {
  const sessions = yield select(selectSessions);

  const newSessions = sessions.map((session) => ({ ...session, listen_type: "offline" }));
  yield put(setSessions(newSessions));

  yield call(handleConnect, { payload: "wsReconnect" });
}

function* handleWsLoginSuccess({ payload }) {
  yield put(getAgentInfo());
}

/*
 *  selected session                                      -> listen_type: all
 *  chatbotSessionStatus.AGENT_CHAT + assigned to me      -> listen_type: all
 *  chatbotSessionStatus.AGENT_CHAT + not assigned to me  -> listen_type: status_only
 *  other sessions                                        -> listen_type: status_only
 */

function* handleSelectedSession({ payload }) {
  let selectedSession = { ...payload };
  if (!selectedSession?.id) return;
  const sessions = yield select(selectSessions);
  const agentInfo = yield select(selectAgentInfo);

  const toBeFullListened = [];
  const toBeStatusOnlyListened = [];

  /*
    TODO: Sometimes selectedSession is coming as listen_type: all.
    but it should be status_only.
    Need to check why and enable this line again
  */

  if (selectedSession.listen_type !== "all") {
    toBeFullListened.push(selectedSession.id);
  }

  for (const session of sessions) {
    if (session.id === selectedSession?.id) continue;
    if ([chatbotSessionStatus.AGENT_WAIT, chatbotSessionStatus.BOT_CHAT_AGENT_WAIT].includes(session.session_status)) {
      if (session.listen_type !== "all") {
        toBeFullListened.push(session.id);
      }
    } else if (session.session_status === chatbotSessionStatus.AGENT_CHAT) {
      if (agentInfo?.id === session?.agent?.id) {
        if (session.listen_type !== "all") {
          toBeFullListened.push(session.id);
        }
      } else if (agentInfo?.id !== session?.agent?.id) {
        if (session.listen_type !== "status_only") {
          toBeStatusOnlyListened.push(session.id);
        }
      }
    } else if (session.listen_type !== "status_only") {
      toBeStatusOnlyListened.push(session.id);
    }
  }

  if (toBeFullListened.length) {
    yield put(sendListenAction(toBeFullListened, "all"));
  }
  if (toBeStatusOnlyListened.length) {
    yield put(sendListenAction(toBeStatusOnlyListened, "status_only"));
  }
}

function* handleSetViewStatus({ payload: status }) {
  if (["visible"].includes(status)) {
    const onlineStatus = yield select(selectOnlineStatus);
    const callcenterSettings = selectCallcenterSettings(store.getState());
    const currentProject = selectCurrentProject(store.getState());
    const isAdminLiveAgent = [userRole.admin].some((role) => currentProject?.permissions?.includes(role));
    const livechatOptions = selectLivechatOptions(store.getState());

    const livechatMode = (() => {
      if (isAdminLiveAgent) {
        return livechatOptions?.type || callcenterSettings?.callcenter_type || livechatCallcenterType.FIFO;
      }
      return callcenterSettings?.callcenter_type || livechatCallcenterType.FIFO;
    })();

    if (["connected", "reconnected"].includes(onlineStatus) && livechatMode === livechatCallcenterType.Pool) {
      yield put(setLivechatLoadingStatus(true));
      yield call(throttledOnFocusRefreshOtherSession);
    }
  }
}
function* continuousAlertChecker() {
  while (true) {
    yield delay(10000);
    const isLogged = yield select(selectIsLogged);

    if (!isLogged) {
      break;
    }

    const livechatOptions = yield select(selectLivechatOptions);
    if (livechatOptions.alert && livechatOptions.continuousAlert) {
      const sessions = yield select(selectSessions);
      const waitingSessions = sessions.filter(
        (session) =>
          session.platform !== chatbotSessionPlatform.WHAT &&
          [chatbotSessionStatus.AGENT_WAIT, chatbotSessionStatus.BOT_CHAT_AGENT_WAIT].includes(session.session_status)
      );

      if (waitingSessions.length > 0) {
        Promise.all([AudioHelper.get(audioList.agentWaitingAlert)]).then(async ([audio]) => {
          audio.replay();
          initialLivechatSoundPlayed = true;
        });
      }
    }
  }
}

function* livechatSaga() {
  yield all([
    takeEvery(at.WS_RECONNECT, handleReconnect),
    takeEvery(at.WS_CONNECT, handleConnect),
    takeEvery(at.WS_DISCONNECT, handleDisconnect),
    takeEvery(at.WS_CONNECT_SUCCESS, handleConnectSuccess),
    takeEvery(at.WS_CONNECT_FAIL, handleConnectFail),
    takeEvery(at.WS_LOGIN, handleLogin),
    takeEvery(at.WS_LOGIN_SUCCESS, handleWsLoginSuccess),
    takeEvery(at.SET_SELECTED_SESSION, handleSelectedSession),
    takeEvery(at.SET_VIEW_STATUS, handleSetViewStatus),

    // Tasks
    takeLatest(at.WS_LOGIN_SUCCESS, loginSuccessPinPongTask),
    takeLatest(at.WS_LOGIN_SUCCESS, continuousAlertChecker),
    takeLatest(at.WS_LOGIN_SUCCESS, loginSuccessGetOtherSocketsTask),

    //Message In/Out
    takeEvery(at.WS_SEND_MESSAGE, handleSendMessage),
    takeEvery(at.ON_MESSAGE, socketActionsHandler),

    takeEvery(at.WS_SEND_MESSAGE_LISTEN_SESSION, handleSendMessageListenSession),

    // Broadcast sync between tabs
    takeEvery(at.ON_BROADCAST_MESSAGE, broadcastActionsHandler),
  ]);
}

export default livechatSaga;
