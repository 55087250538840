import { createSelector } from "reselect";

/** @type {() => typeof import("./reducer").initState} */
export const selectLivechat = (state) => state.socket.livechat;

export const selectAgentInfo = createSelector(selectLivechat, (root) => root.agentInfo);

export const selectSessions = createSelector(selectLivechat, (root) => root.sessions);
export const selectSelectedSession = createSelector(selectLivechat, (root) => root.selectedSession);
export const selectSessionId = createSelector(selectSelectedSession, (session) => session?.id);
export const selectSessionById = createSelector(
  [selectSessions, (_state, sessionId) => sessionId],
  (sessions, sessionId) => sessions.find((session) => session.id === sessionId)
);

export const selectOnlineStatus = createSelector(selectLivechat, (root) => root.onlineStatus);

export const selectIsLogged = createSelector(selectLivechat, (root) => root.isLogged);
export const selectViewStatus = createSelector(selectLivechat, (root) => root.viewStatus);
export const selectLastPongTime = createSelector(selectLivechat, (root) => root.lastPongTime);
export const selectProjectSettings = createSelector(selectLivechat, (root) => root.projectSettings);
export const selectLivechatOptions = createSelector(selectLivechat, (root) => root.livechatOptions);
export const selectConnectionOptions = createSelector(selectLivechat, (root) => root.connectionOptions);
export const selectOtherSessionsInfo = createSelector(selectLivechat, (root) => root.otherSessionsInfo);
export const selectInteractionStatus = createSelector(selectLivechat, (root) => root.interactionStatus);
export const selectOnlineAgents = createSelector(selectLivechat, (root) => root.onlineAgents);
export const selectLivechatLoadingStatus = createSelector(selectLivechat, (root) => root.livechatLoadingStatus);
export const selectCallcenterSettings = createSelector(selectLivechat, (root) => root.callcenterSettings);
export const selectLlmSettings = createSelector(selectLivechat, (root) => root.llmSettings);
export const selectSessionCountDelta = createSelector(selectLivechat, (root) => root.sessionCountDelta);
