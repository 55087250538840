import { put, select } from "redux-saga/effects";

import { selectCurrentProject } from "~store/user/selectors";

import { setLivechatOptions, wsSendSubscribeLevel } from "../actions";

export const broadcastActionTypes = {
  syncLivechatOptions: "syncLivechatOptions",
};

export default function* broadcastActionsHandler({ payload }) {
  switch (payload.type) {
    case broadcastActionTypes.syncLivechatOptions:
      const data = payload?.payload;
      if (data) {
        yield put(setLivechatOptions(data));
        const currentProject = yield select(selectCurrentProject);
        const filterArr = data?.filters?.[currentProject?.id] || [];
        const queryObj = filterArr.reduce((acc, item) => {
          if (item.type === "text") {
            acc[item.key] = item.value[0];
          } else {
            acc[item.key] = item.value;
          }
          return acc;
        }, {});

        yield put(wsSendSubscribeLevel("all", queryObj));
      }
      break;
    default:
  }
}
