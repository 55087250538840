export * from "./layout";
export * from "./cookies";
export * from "./localStorage";
export * from "./chatbot";
export * from "./organization";
export * from "./apiUrl";
export * from "./dialogComponents";
export * from "./ticket";
export * from "./user";
export * from "./project";
export * from "./helpers";
export * from "./table";
export * from "./settings";
export * from "./messageTemplate";
export * from "./module";
export * from "./monaco";
export * from "./statistics";
export * from "./flags";
export * from "./popup";
export * from "./webWorker";
export * from "./intentRecords";
export * from "./intentRecommend";
export * from "./appAnnouncement";
export * from "./lowcode";

export const WEBCHAT_REDIRECT_KEY = "psrc";
export const WEBCHAT_REDIRECT_VALUE = "pal";
