export const LS_LANGUAGE = "lang";
export const LS_LANGUAGE_CHATBOT = `${LS_LANGUAGE}_{0}`;

export const LS_WEBCHAT_TOKEN = "pr_tkn_{0}";
export const LS_WEBCHAT_BACKUP_TOKEN = "pr_btkn_{0}";
export const LS_WEBCHAT_UUID = "pr_id_{0}";
export const LS_WEBCHAT_TOKEN_EXPIRE = "pr_exp_{0}";
export const LS_WEBCHAT_OPTIONS = "pr_opt_{0}";
export const LS_WEBCHAT_COOKIE_ALIAS = "{0}_";

export const LS_TOKEN = "token";
export const LS_TOKEN_REFRESH = "token_refresh";

export const LS_LOCAL_STORAGE_VERSION = "lsv";
export const LS_LOCAL_STORAGE_VERSION_CHATBOT = `${LS_LOCAL_STORAGE_VERSION}_{0}`;

export const LS_LOWCODE_CONTEXT = "lc_ctx";
export const LS_LOWCODE_PRESET = "lc_pst";

export const LS_LIVECHAT_OPTIONS = "lct_opt";

export const LS_TEMPLATE_POPUP_HISTORY = "tp_h";
export const LS_APP_VERSION_CHECKED_VERSION = "av_c_v";
export const LS_ANNOUNCEMENT_ACK_ID_LIST = "an_ack";
