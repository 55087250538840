// export const WS_DATA_OUT = "@SOCKET/CHATBOT/WS_DATA_OUT";
// export const WS_DATA_IN = "@SOCKET/CHATBOT/WS_DATA_IN";

export const WS_MSG_RECEIVED = "@SOCKET/MESSAGE_RECEIVED";
export const WS_MSG_SENT = "@SOCKET/MESSAGE_SENT";

export const WS_CONNECT = "@SOCKET/CHATBOT/WS_CONNECT";
export const WS_CONNECT_SUCCESS = "@SOCKET/CHATBOT/WS_CONNECT_SUCCESS";
export const WS_CONNECT_FAIL = "@SOCKET/CHATBOT/WS_CONNECT_FAIL";
export const WS_DISCONNECT = "@SOCKET/CHATBOT/WS_DISCONNECT";

// export const WS_LOGIN = "@SOCKET/CHATBOT/WS_LOGIN";
export const WS_LOGIN_SUCCESS = "@SOCKET/CHATBOT/WS_LOGIN_SUCCESS";
export const WS_LOGIN_FAIL = "@SOCKET/CHATBOT/WS_LOGIN_FAIL";

export const WS_OUT = "@SOCKET/CHATBOT/WS_OUT_";
export const WS_OUT_ACTION = "@SOCKET/CHATBOT/WS_OUT_ACTION_";

export const WS_OUT_LOGIN = "@SOCKET/CHATBOT/WS_OUT_LOGIN";
export const WS_OUT_PING = "@SOCKET/CHATBOT/WS_OUT_PING";
export const WS_OUT_INTERACTION_STATUS = "@SOCKET/CHATBOT/WS_OUT_INTERACTION_STATUS";
export const WS_OUT_MESSAGE = "@SOCKET/CHATBOT/WS_OUT_MESSAGE";
export const WS_OUT_ACTION_RETURN_TO_HOME = "@SOCKET/CHATBOT/WS_OUT_ACTION_RETURN_TO_HOME";
export const WS_OUT_ACTION_CANCEL_TICKET = "@SOCKET/CHATBOT/WS_OUT_ACTION_CANCEL_TICKET";
export const WS_OUT_ACTION_CANCEL_QUEUE = "@SOCKET/CHATBOT/WS_OUT_ACTION_CANCEL_QUEUE";
export const WS_OUT_ACTION_TERMINATE_SESSION = "@SOCKET/CHATBOT/WS_OUT_ACTION_TERMINATE_SESSION";
export const WS_OUT_ACTION_START_INTENT = "@SOCKET/CHATBOT/WS_OUT_ACTION_START_INTENT";
export const WS_OUT_ACTION_SEND_TRANSACTION = "@SOCKET/CHATBOT/WS_OUT_ACTION_SEND_TRANSACTION";
export const WS_OUT_ACTION_BUTTON_SELECT = "@SOCKET/CHATBOT/WS_OUT_ACTION_BUTTON_SELECT";
export const WS_OUT_ACTION_SUBMIT_TICKET = "@SOCKET/CHATBOT/WS_OUT_ACTION_SUBMIT_TICKET";
export const WS_OUT_ACTION_SUBMIT_FEEDBACK = "@SOCKET/CHATBOT/WS_OUT_ACTION_SUBMIT_FEEDBACK";
export const WS_OUT_ACTION_SUBMIT_INPUT_REQUEST = "@SOCKET/CHATBOT/WS_OUT_ACTION_SUBMIT_INPUT_REQUEST";
export const WS_OUT_ACTION_SUBMIT_MESSAGE_FEEDBACK = "@SOCKET/CHATBOT/WS_OUT_ACTION_SUBMIT_MESSAGE_FEEDBACK";
export const WS_OUT_ACTION_SUBMIT_VALIDATION = "@SOCKET/CHATBOT/WS_OUT_ACTION_SUBMIT_VALIDATION";
export const WS_OUT_ACTION_CHANGE_LANGUAGE = "@SOCKET/CHATBOT/WS_OUT_ACTION_CHANGE_LANGUAGE";

export const WS_IN = "@SOCKET/CHATBOT/WS_IN_";
export const WS_IN_ACTION = "@SOCKET/CHATBOT/WS_IN_ACTION_";

export const WS_IN_NOTIFICATION = "@SOCKET/CHATBOT/WS_IN_NOTIFICATION";
export const WS_IN_PONG = "@SOCKET/CHATBOT/WS_IN_PONG";
export const WS_IN_QUEUE = "@SOCKET/CHATBOT/WS_IN_QUEUE";
export const WS_IN_MESSAGE = "@SOCKET/CHATBOT/WS_IN_MESSAGE";
export const WS_IN_HISTORY = "@SOCKET/CHATBOT/WS_IN_HISTORY";
export const WS_IN_INTERACTION_STATUS = "@SOCKET/CHATBOT/WS_IN_INTERACTION_STATUS";

export const WS_IN_ACTION_RETURN_TO_HOME = "@SOCKET/CHATBOT/WS_IN_ACTION_RETURN_TO_HOME";
export const WS_IN_ACTION_CANCEL_TICKET = "@SOCKET/CHATBOT/WS_IN_ACTION_CANCEL_TICKET";
export const WS_IN_ACTION_CANCEL_QUEUE = "@SOCKET/CHATBOT/WS_IN_ACTION_CANCEL_QUEUE";
export const WS_IN_ACTION_INPUT_REQUEST = "@SOCKET/CHATBOT/WS_IN_ACTION_INPUT_REQUEST";
export const WS_IN_ACTION_SUBMIT_INPUT_REQUEST = "@SOCKET/CHATBOT/WS_IN_ACTION_SUBMIT_INPUT_REQUEST";
export const WS_IN_ACTION_SUBMIT_TICKET = "@SOCKET/CHATBOT/WS_IN_ACTION_SUBMIT_TICKET";
export const WS_IN_ACTION_SUBMIT_FEEDBACK = "@SOCKET/CHATBOT/WS_IN_ACTION_SUBMIT_FEEDBACK";
export const WS_IN_ACTION_BUTTON_SELECT = "@SOCKET/CHATBOT/WS_IN_ACTION_BUTTON_SELECT";
export const WS_IN_ACTION_BOT_CONNECT = "@SOCKET/CHATBOT/WS_IN_ACTION_BOT_CONNECT";
export const WS_IN_ACTION_BOT_DISCONNECT = "@SOCKET/CHATBOT/WS_IN_ACTION_BOT_DISCONNECT";
export const WS_IN_ACTION_AGENT_CONNECT = "@SOCKET/CHATBOT/WS_IN_ACTION_AGENT_CONNECT";
export const WS_IN_ACTION_AGENT_DISCONNECT = "@SOCKET/CHATBOT/WS_IN_ACTION_AGENT_DISCONNECT";
export const WS_IN_ACTION_TERMINATE_SESSION = "@SOCKET/CHATBOT/WS_IN_ACTION_TERMINATE_SESSION";
export const WS_IN_ACTION_INACTIVITY_TIMEOUT = "@SOCKET/CHATBOT/WS_IN_ACTION_INACTIVITY_TIMEOUT";
export const WS_IN_ACTION_REDIRECT_TO_TICKET = "@SOCKET/CHATBOT/WS_IN_ACTION_REDIRECT_TO_TICKET";
export const WS_IN_ACTION_REDIRECT_TO_FEEDBACK = "@SOCKET/CHATBOT/WS_IN_ACTION_REDIRECT_TO_FEEDBACK";
// export const WS_IN_ACTION_DIRECT_TO_OFFLINE = "@SOCKET/CHATBOT/WS_IN_ACTION_DIRECT_TO_OFFLINE";
export const WS_IN_ACTION_REDIRECT_TO_QUEUE = "@SOCKET/CHATBOT/WS_IN_ACTION_REDIRECT_TO_QUEUE";
export const WS_IN_ACTION_MESSAGE_FEEDBACK = "@SOCKET/CHATBOT/WS_IN_ACTION_MESSAGE_FEEDBACK";
export const WS_IN_ACTION_SUBMIT_MESSAGE_FEEDBACK = "@SOCKET/CHATBOT/WS_IN_ACTION_SUBMIT_MESSAGE_FEEDBACK";
export const WS_IN_ACTION_SUBMIT_VALIDATION = "@SOCKET/CHATBOT/WS_IN_ACTION_SUBMIT_VALIDATION";
export const WS_IN_ACTION_SUBMIT_VALIDATION_RESULT = "@SOCKET/CHATBOT/WS_IN_ACTION_SUBMIT_VALIDATION_RESULT";
export const WS_IN_ACTION_CHANGE_LANGUAGE = "@SOCKET/CHATBOT/WS_IN_ACTION_CHANGE_LANGUAGE";
export const WS_IN_ACTION_TRANSFER_AGENT = "@SOCKET/CHATBOT/WS_IN_ACTION_TRANSFER_AGENT";

export const DOM_MSG = "@SOCKET/DOM/MESSAGE";
export const DOM_MSG_ACTION = "@SOCKET/DOM/MESSAGE_ACTION";

export const SET_ONLINE_STATUS = "@SOCKET/CHATBOT/SET_ONLINE_STATUS";
export const SET_VIEWMODE = "@SOCKET/CHATBOT/SET_VIEWMODE";
export const SET_VIEWMODE_DELAYED = "@SOCKET/CHATBOT/SET_VIEWMODE_DELAYED";
export const SET_WELCOME_OPTIONS = "@SOCKET/CHATBOT/SET_WELCOME_OPTIONS";
export const SET_SOCKET_INSTANCE = "@SOCKET/CHATBOT/SET_SOCKET_INSTANCE";
export const SET_INTERACTION_STATUS = "@SOCKET/CHATBOT/SET_INTERACTION_STATUS";
export const SET_REMOTE_INTERACTION_STATUS = "@SOCKET/CHATBOT/SET_REMOTE_INTERACTION_STATUS";
export const SET_INTERACTION_MODAL = "@SOCKET/CHATBOT/SET_INTERACTION_MODAL";

export const SET_DROPZONE_ALLOW_STATUS = "@SOCKET/CHATBOT/SET_DROPZONE_ALLOW_STATUS";
export const SET_DROPZONE_FILES = "@SOCKET/CHATBOT/SET_DROPZONE_FILES";
export const SET_DROPZONE_REF = "@SOCKET/CHATBOT/SET_DROPZONE_REF";

// export const CONNECT_REQUEST = "@SOCKET/CHATBOT/CONNECT_REQUEST";
// export const DISCONNECT_REQUEST = "@SOCKET/CHATBOT/DISCONNECT_REQUEST";
export const DISCONNECT_DONE = "@SOCKET/CHATBOT/DISCONNECT_DONE";
// export const PING = "@SOCKET/CHATBOT/PING";
// export const LOGIN = "@SOCKET/CHATBOT/LOGIN";
// export const LOGIN_SUCCESS = "@SOCKET/CHATBOT/LOGIN_SUCCESS";
// export const LOGIN_FAIL = "@SOCKET/CHATBOT/LOGIN_FAIL";
// export const REPLY_MESSAGE = "@SOCKET/CHATBOT/REPLY_MESSAGE";
// export const NOTIFICATION = "@SOCKET/CHATBOT/NOTIFICATION";
// export const ON_MESSAGE = "@SOCKET/CHATBOT/ON_MESSAGE";
export const ON_BROADCAST_MESSAGE = "@SOCKET/CHATBOT/ON_BROADCAST_MESSAGE";
export const UPDATE_MESSAGE = "@SOCKET/CHATBOT/UPDATE_MESSAGE";
export const APPEND_MESSAGE = "@SOCKET/CHATBOT/APPEND_MESSAGE";
export const APPEND_RAW_MESSAGE = "@SOCKET/CHATBOT/APPEND_RAW_MESSAGE";
export const APPEND_MESSAGE_BEGIN = "@SOCKET/CHATBOT/APPEND_MESSAGE_BEGIN";
export const SET_CHAT_DISABLED_STATUS = "@SOCKET/CHATBOT/SET_CHAT_DISABLED_STATUS";
export const SET_MESSAGE_LIST = "@SOCKET/CHATBOT/SET_MESSAGE_LIST";
export const APPEND_NOTIFICATION = "@SOCKET/CHATBOT/APPEND_NOTIFICATION";
export const CHANGE_SENDER = "@SOCKET/CHATBOT/CHANGE_SENDER";
export const CHANGE_SENDER_DATA = "@SOCKET/CHATBOT/CHANGE_SENDER_DATA";
export const SET_LAST_MESSAGE_TIME = "@SOCKET/CHATBOT/SET_LAST_MESSAGE_TIME";
export const SET_SEEN_MESSAGE_INFO = "@SOCKET/CHATBOT/SET_SEEN_MESSAGE_INFO";
export const SET_INITIALIZED = "@SOCKET/CHATBOT/SET_INITIALIZED";
// export const SET_CHATBOT_INFO = "@SOCKET/CHATBOT/SET_CHATBOT_INFO";
// export const SET_AGENT_INFO = "@SOCKET/CHATBOT/SET_AGENT_INFO";
// export const SET_AGENT_STATUS = "@SOCKET/CHATBOT/SET_AGENT_STATUS";
export const SET_QUEUE_STATUS = "@SOCKET/CHATBOT/SET_QUEUE_STATUS";
export const SET_TICKET_FORM = "@SOCKET/CHATBOT/SET_TICKET_FORM";
export const CLEAR_TICKET_FORM = "@SOCKET/CHATBOT/CLEAR_TICKET_FORM";
export const SET_TIMEOUT_STATUS = "@SOCKET/CHATBOT/SET_TIMEOUT_STATUS";
export const SET_PONG = "@SOCKET/CHATBOT/SET_PONG";
export const SET_QUERY_OPTIONS = "@SOCKET/CHATBOT/SET_QUERY_OPTIONS";
export const SET_SESSION_STATUS = "@SOCKET/CHATBOT/SET_SESSION_STATUS";
// export const SEND_MSG = "@SOCKET/CHATBOT/SEND_MSG";
export const SET_MINIMIZED_STATUS = "@SOCKET/CHATBOT/SET_MINIMIZED_STATUS";
export const SET_MINIMIZED_DONE_STATUS = "@SOCKET/CHATBOT/SET_MINIMIZED_DONE_STATUS";
export const SET_WELCOME_MSG_SHOWED_STATUS = "@SOCKET/CHATBOT/SET_WELCOME_MSG_SHOWED_STATUS";
export const RESUME_CHAT_SESSION = "@SOCKET/CHATBOT/RESUME_CHAT_SESSION";
export const SET_WELCOME_FORM_DATA = "@SOCKET/CHATBOT/SET_WELCOME_FORM_DATA";
export const SET_DISPLAY_MODE = "@SOCKET/CHATBOT/SET_DISPLAY_MODE";
export const SET_MAINTENANCE_STATUS = "@SOCKET/CHATBOT/SET_MAINTENANCE_STATUS";
export const SET_CONNECTION_OPTIONS = "@SOCKET/CHATBOT/SET_CONNECTION_OPTIONS";
export const SET_LAST_OUTGOING_MSG = "@SOCKET/CHATBOT/SET_LAST_OUTGOING_MSG";
export const SET_FEEDBACK_INFO = "@SOCKET/CHATBOT/SET_FEEDBACK_INFO";
export const SET_IS_LOGGED = "@SOCKET/CHATBOT/SET_IS_LOGGED";
export const SET_SUBMIT_VALIDATION_RESULT = "@SOCKET/CHATBOT/SET_SUBMIT_VALIDATION_RESULT";
export const SET_BUTTON_SELECT_LOADING = "@SOCKET/CHATBOT/SET_BUTTON_SELECT_LOADING";
export const SET_INPUT_REQUEST_INFO = "@SOCKET/CHATBOT/SET_INPUT_REQUEST_INFO";

export const DISCONNECT_REQUEST = "@SOCKET/CHATBOT/DISCONNECT_REQUEST";
//API
export const GET_WELCOME_OPTIONS = "@SOCKET/CHATBOT/GET_WELCOME_OPTIONS";
export const START_CHAT_SESSION = "@SOCKET/CHATBOT/START_CHAT_SESSION";
