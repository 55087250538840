import React, { useCallback, useEffect, useState } from "react";

import { withCardon } from "cardon";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import styled from "@emotion/styled";
import { Box, Grid, Typography } from "@mui/material";

import PalButton from "~components/mui/PalButton";
import PalModal from "~components/mui/PalModal";
import DateHelper from "~helpers/DateHelper";
import { selectMinimized } from "~store/socket/chatbot/selectors";

const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* margin: 0px 20px; */
  width: 390px;
  min-width: 260px;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 16px #00000029;
  border-radius: 24px;
  padding: 30px 25px 0px;

  padding-bottom: 73px;

  .title {
    text-align: center;
    font: normal normal 600 20px/32px MuseoModerno;
    letter-spacing: 0;
    color: #000000;
  }
  .desc {
    text-align: center;
    font: normal normal 100 14px/17px Museo Sans;
    letter-spacing: -0.14px;
    color: #000000;
  }

  .buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    .cancel-button {
      font-weight: 300;
    }
    .MuiButton-root {
      border-radius: 0px;
      border-color: #f2f2f2;
      height: 52.5px;
      font-size: 16px;
      letter-spacing: 0px;
    }
    & > :nth-child(1) {
      .MuiButton-root {
        border-bottom-left-radius: 24px;

        &:not(.Mui-disabled) {
          color: #535353;
        }
      }
    }
    & > :nth-child(2) {
      .MuiButton-root {
        border-bottom-right-radius: 24px;
        font-weight: 600;

        &:not(.Mui-disabled) {
          color: #000000;
        }
      }
    }
  }
`;

function TimeoutText({ onFinish, remainingTimeMs }) {
  const [seconds, setSeconds] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const date = DateHelper.getDateTime();
    const ceilMs = Math.ceil(remainingTimeMs / 1000) * 1000;

    const timeoutDate = date.add(ceilMs, "ms");
    const calcTime = () => {
      const diff = timeoutDate.diff(DateHelper.getDateTimeLocal(), "seconds");
      let diffText = DateHelper.formatMoment(diff, "s", "h:mm:ss");

      if (diff <= 0) {
        diffText = "0";
      }
      setSeconds(diffText);
      if (diff <= 0) {
        clearInterval(interval);
        onFinish();
      }
    };
    let interval = setInterval(calcTime, 1000);
    calcTime();
    return () => clearInterval(interval);
  }, [remainingTimeMs, onFinish]);

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: t("chatbot.chatSceneTexts.inactiveMessageQuestionByTime").format(`<b>${seconds}</b>`),
      }}
    />
  );
}

const ChatTimeoutCountdownModal = withCardon(
  function ChatTimeoutCountdownModalContent({ get, timeMs }) {
    const minimized = useSelector(selectMinimized);
    const { t } = useTranslation();

    const handleClickClose = useCallback(async () => {
      get(true)();
    }, [get]);

    if (minimized) return null;
    return (
      <PalModal open={true}>
        <StyledBox>
          <Typography className="title">{t("chatbot.closeText.timeoutChat")}</Typography>
          <Typography className="desc">
            <TimeoutText remainingTimeMs={timeMs} onFinish={handleClickClose} />
          </Typography>

          <Grid container className="buttons">
            <Grid item xs={6}>
              <PalButton fullWidth className="cancel-button" color="secondary" variant="outlined" onClick={get(false)}>
                {t("chatbot.ignore")}
              </PalButton>
            </Grid>
            <Grid item xs={6}>
              <PalButton fullWidth color="secondary" variant="outlined" onClick={handleClickClose}>
                {t("chatbot.chatSceneTexts.inActiveMessageEnd")}
              </PalButton>
            </Grid>
          </Grid>
        </StyledBox>
      </PalModal>
    );
  },
  { destroyOnHide: true }
);

export default ChatTimeoutCountdownModal;
