import * as Sentry from "@sentry/react";
import hljs from "highlight.js/lib/core";
import MarkdownIt from "markdown-it";
import MarkdownHighlight from "markdown-it-highlightjs";
import PlainText from "markdown-it-plain-text";
import MdTaskList from "markdown-it-task-lists";

import { WEBCHAT_REDIRECT_KEY, WEBCHAT_REDIRECT_VALUE } from "~constants";

import Utils from "./Utils";

hljs.registerLanguage("javascript", require("highlight.js/lib/languages/javascript"));
hljs.registerLanguage("python", require("highlight.js/lib/languages/python"));
hljs.registerLanguage("xml", require("highlight.js/lib/languages/xml"));
hljs.registerLanguage("json", require("highlight.js/lib/languages/json"));

export default class RenderHelper {
  /**
   * @param {string} text
   * @param {import("markdown-it").Options & {
   *   renderAsPlain: boolean;
   * }} options
   */
  static renderMd(text, options = {}) {
    const md = new MarkdownIt({ ...options, tables: true });
    const defaultRender =
      md.renderer.rules.link_open ||
      function (tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options);
      };
    const defaultImageRender =
      md.renderer.rules.image ||
      function (tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options);
      };

    const defaultTableRender =
      md.renderer.rules.table_open ||
      function (tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options);
      };

    md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
      const aIndex = tokens[idx].attrIndex("href");
      if (aIndex >= 0) {
        const url = tokens[idx].attrs[aIndex][1];
        let parsedUrl;
        try {
          parsedUrl = new URL(url);
        } catch {}
        if (parsedUrl) {
          const isHttpScheme = url?.startsWith("http://") || url?.startsWith("https://");
          const isSmsScheme = url?.startsWith("sms:");
          if (isHttpScheme) {
            tokens[idx].attrPush(["target", "_blank"]);
            tokens[idx].attrPush(["rel", "noopener"]);

            if (parsedUrl.searchParams.has("disable_redirect_query")) {
              parsedUrl.searchParams.delete("disable_redirect_query");
            } else {
              parsedUrl.searchParams.append(WEBCHAT_REDIRECT_KEY, WEBCHAT_REDIRECT_VALUE);
            }
          } else if (isSmsScheme) {
            const isIOSDevice = Utils.isIOSDevice();
            if (isIOSDevice && parsedUrl.searchParams.has("body")) {
              const body = parsedUrl.searchParams.get("body");
              parsedUrl.searchParams.delete("body");
              parsedUrl.href = parsedUrl.href + `&body=${body}`;
            }
          }
          tokens[idx].attrs[aIndex][1] = parsedUrl.href;
        }
      }

      return defaultRender(tokens, idx, options, env, self);
    };

    md.renderer.rules.image = function (tokens, idx, options, env, self) {
      const token = tokens[idx];

      token.attrPush(["md-image", "true"]);
      const styleIndex = token.attrIndex("style");
      if (styleIndex >= 0) {
        token.attrs[styleIndex][1] += "border-radius: 14px 14px 14px 0px;";
      } else {
        token.attrPush(["style", "border-radius: 14px 14px 14px 0px;"]);
      }

      return defaultImageRender(tokens, idx, options, env, self);
    };
    md.renderer.rules.table_open = function (tokens, idx, options, env, self) {
      const token = tokens[idx];

      token.attrPush(["md-table", "true"]);
      return defaultTableRender(tokens, idx, options, env, self);
    };

    if (options.renderAsPlain) {
      return md.use(PlainText).render(text);
    }
    md.use(MarkdownHighlight, {
      hljs,
    });
    md.use(MdTaskList, {
      enabled: true,
      label: true,
    });

    md.set({
      highlight: function (str, lang) {
        if (lang && hljs.getLanguage(lang)) {
          let highlighted = hljs.highlight(str, { language: lang }).value;
          let lines = highlighted.split("\n").slice(0, -1); // Son boş satırı atla
          let numberedLines = lines
            .map((line, index) => `<span class="code-line" data-line-number="${index + 1}">${line}</span>`)
            .join("\n");
          return `<pre class="hljs"><code>${numberedLines}</code></pre>`;
        }
        return `<pre class="hljs"><code>${md.utils.escapeHtml(str)}</code></pre>`;
      },
    });
    let renderedMd = md.render(text);

    //remove end of \n from markdown-it
    renderedMd = renderedMd.replace(/\n$/, "");
    return renderedMd;
  }

  static addBlankTargetToLinks(html) {
    // Find links in HTML and add target="_blank" to them
    const regex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"([^>]*)>/g;
    return html.replace(regex, (match, href, rest) => {
      const restExceptTargetAndRel = rest.replace(/target="[^"]*"/, "").replace(/rel="[^"]*"/, "");
      let hrefUrl;
      try {
        hrefUrl = new URL(href);
      } catch (error) {
        Sentry.captureException(
          "Error while parsing URL in RenderHelper.addBlankTargetToLinks.COrrupted HREF:" + href,
          "error"
        );
        return match;
      }
      if (hrefUrl.searchParams.has("disable_redirect_query")) {
        hrefUrl.searchParams.delete("disable_redirect_query");
      } else {
        hrefUrl.searchParams.append(WEBCHAT_REDIRECT_KEY, WEBCHAT_REDIRECT_VALUE);
      }
      return `<a href="${hrefUrl.href}" rel="noopener" target="_blank"${restExceptTargetAndRel.replace(/\s+/g, "")}>`;
    });
  }
}
