import i18next from "i18next";
import { call, put, select, takeEvery } from "redux-saga/effects";

import JoinedMessage from "~components/Generic/PRChatMessage/InlineMessages/NotificationMessage/JoinedMessage";
import { popupSettingsMapper } from "~constants";
import DateHelper from "~helpers/DateHelper";
import IFrameHelper from "~helpers/IFrameHelper";
import {
  setMessageList,
  setViewModeDelayed,
  setWelcomeMsgShowedStatus,
  setWelcomeOptions,
} from "~store/socket/chatbot/actions";

import { SET_POPUP_SETTING_STATE } from "./actionTypes";
import { selectPopupSettingsState } from "./selectors";

function* setChangesToPopupPreview() {
  const settingState = yield select(selectPopupSettingsState);

  const botName = (settingState.chatbot_list || Object.values(settingState.chatbot_status || {}))[0]?.name;
  yield put(
    setMessageList([
      {
        type: "notification",
        position: "center",
        messageTime: DateHelper.getDateTime(),
        text: function () {
          return <JoinedMessage isAgent={false} messageTime={DateHelper.getDateTime()} name={botName} />;
        },
        unformattedText: function () {
          return <JoinedMessage unformatted isAgent={false} messageTime={DateHelper.getDateTime()} name={botName} />;
        },
      },
      {
        type: "text",
        position: "left",
        text: i18next.t("chatbot.previewMode.welcomeMessage"),
        messageTime: DateHelper.getDateTime().add(1, "minute"),
      },
      {
        type: "text",
        position: "right",
        text: i18next.t("chatbot.previewMode.welcomeMessageRight"),
        messageTime: DateHelper.getDateTime().add(2, "minute"),
      },
      {
        type: "text",
        position: "left",
        format: "markdown",
        text: "Markdown<br/>**bold**<br/>*italic*<br/>`code`<br/>[link](https://www.google.com)",
        messageTime: DateHelper.getDateTime().add(3, "minute"),
      },
      {
        type: "text",
        position: "left",
        format: "html",
        text: "<b>HTML</b><br/><i>italic</i><br/><code>code</code><br/><a href='https://www.google.com'>link</a>",
        messageTime: DateHelper.getDateTime().add(4, "minute"),
      },
      {
        type: "choice",
        position: "left",
        messageTime: DateHelper.getDateTime().add(5, "minute"),
        text: i18next.t("chatbot.previewMode.choiceMessage"),
        choices: [
          {
            text: i18next.t("chatbot.previewMode.yes"),
            value: "yklnvbtsuihk",
            disabled: true,
            isSelected: true,
          },
          {
            text: i18next.t("chatbot.previewMode.no"),
            value: "xgwamusveoue",
            disabled: true,
            isSelected: false,
          },
        ],
      },
      {
        type: "text",
        position: "right",
        text: i18next.t("chatbot.previewMode.yes"),
        messageTime: DateHelper.getDateTime().add(6, "minute"),
      },
      {
        type: "choice",
        position: "left",
        messageTime: DateHelper.getDateTime().add(7, "minute"),
        text: i18next.t("chatbot.previewMode.choiceMessage"),
        choices: [
          {
            text: i18next.t("chatbot.previewMode.yes"),
            value: "yklnvbtsuihk",
          },
          {
            text: i18next.t("chatbot.previewMode.no"),
            value: "xgwamusveoue",
          },
        ],
      },
      {
        type: "notification",
        position: "center",
        messageTime: DateHelper.getDateTime().add(8, "minute"),
        text: function () {
          return (
            <JoinedMessage isAgent={true} messageTime={DateHelper.getDateTime().add(8, "minute")} name={"Selin Y."} />
          );
        },
      },
      {
        type: "text",
        position: "left",
        text: i18next.t("chatbot.previewMode.howCanIHelp"),
        messageTime: DateHelper.getDateTime().add(9, "minute"),
        sender: "AGENT",
        senderData: {
          name: "Selin Y.",
        },
      },
    ])
  );
  const welcomeFormBodyForPopup = {
    form_items: settingState.welcome_form?.form_items,
    popup_button_icon_path: settingState.popup_button_icon_path,
    popup_button_type: settingState.popup_button_type,
    popup_header_icon_path: settingState.popup_header_icon_path,
    popup_header_icon_type: settingState.popup_header_icon_type,
    popup_bot_icon_type: settingState.popup_bot_icon_type,
    popup_bot_icon_path: settingState.popup_bot_icon_path,
    popup_primary_color: settingState.popup_primary_color,
    popup_title: settingState.popup_title,
    popup_welcome_text: settingState.popup_welcome_text,
    start_anonymous_chat: settingState.welcome_form?.optional,
    welcome_msg: settingState.welcome_msg,
    popup_style_info: settingState.popup_style_info,
    chatbot_info: settingState.chatbot_info,
    system_chatbot_info: settingState.system_chatbot_info,
  };

  const mappedData = popupSettingsMapper(welcomeFormBodyForPopup);
  IFrameHelper.setPropertyToRoot([
    ["--webchat-icon-margin-x", `${mappedData.popup_style_info.popup_margin_x}px`],
    ["--webchat-icon-margin-y", `${mappedData.popup_style_info.popup_margin_y}px`],
    ["--webchat-icon-size", `${mappedData.popup_style_info.popup_icon_size}px`],
    ["--webchat-height", `${mappedData.popup_style_info.chat_height}px`],
  ]);
  yield put(setWelcomeOptions(mappedData));
  yield put(setViewModeDelayed(settingState.welcome_form?.optional ? "chat" : "welcome"));
  yield put(setWelcomeMsgShowedStatus(false));

  const latestSizeMode = IFrameHelper.getLatestPopupSizeMode();

  yield call(
    IFrameHelper.setPopupSizeMode,
    latestSizeMode,
    true,
    "desktop",
    mappedData?.popup_style_info?.popup_position
  );
}

function* chatbotSaga() {
  yield takeEvery(SET_POPUP_SETTING_STATE, setChangesToPopupPreview);
}

export default chatbotSaga;
