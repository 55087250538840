import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

/**
 * @typedef {object} Announcement
 * @property {number} id
 * @property {string} title
 * @property {string} body
 * @property {string} start
 * @property {string} end
 * @property {string} announcement_type
 */

/**
 * @typedef {object} AppVersion
 * @property {number} id
 * @property {string} title
 * @property {string} frontend_release_number
 * @property {string} backend_release_number
 * @property {string} app_version
 * @property {object[]} change_logs
 * @property {number} change_logs.id
 * @property {number} change_logs.version
 * @property {string} change_logs.title
 * @property {string} change_logs.body
 */

export const initState = {
  sidebar: {
    open: true,
    minimized: false,
    mobile: false,
  },

  /** @type {AppVersion} */
  latestAppVersion: null,
  /** @type {AppVersion[]} */
  allAppVersions: [],
  /** @type {Announcement[]} */
  announcements: [],
};
const layout = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_SIDEBAR:
        draft.sidebar = {
          ...draft.sidebar,
          ...action.payload,
        };
        break;

      case at.SET_ALL_APP_VERSIONS:
        draft.allAppVersions = action.payload;
        break;

      case at.SET_LATEST_APP_VERSION:
        draft.latestAppVersion = action.payload;
        break;

      case at.SET_APP_ANNOUNCEMENTS:
        draft.announcements = action.payload;
        break;
    }
  });
export default layout;
