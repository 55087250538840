export default class FileHelper {
  /**
   * @param {{ [key: string]: FileList }} keyFileList
   * @returns {any} Returns FormData
   */
  static getFormDataByFileObj(keyFileList) {
    const formData = new FormData();
    for (const key in keyFileList) {
      const fileList = keyFileList[key];
      for (let i = 0; i < fileList.length; i++) {
        const fileName = fileList[i].name;
        const fileParts = fileName.split(".");
        const fileExt = fileParts.pop();
        const fileBaseName = fileParts.join(".");
        formData.append(key, fileList[i], `${fileBaseName.normalizeAccent("_")}_${i}.${fileExt}`);
      }
    }
    return formData;
  }
  /**
   * Exports data as an Excel file.
   *
   * @example
   *   // Example data
   *   const data = [
   *     { name: "John Doe", age: 30, email: "john@example.com" },
   *     { name: "Jane Smith", age: 25, email: "jane@example.com" },
   *   ];
   *
   *   // Export data as Excel
   *   await exportAsExcel(data, "users");
   *
   * @param {object[]} data - The data to export as Excel.
   * @param {string} fileName - The name of the exported file.
   * @returns {Promise<void>} - A promise that resolves when the file is exported.
   */
  static exportAsExcel = async (data, fileName) => {
    const ExcelJS = (await import("exceljs")).default;
    const FileSaver = (await import("file-saver")).default;

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");

    let orderedKeys = [];

    data.forEach((obj) => {
      let tempKeys = Object.keys(obj);
      tempKeys.forEach((key, index) => {
        if (!orderedKeys.includes(key)) {
          let inserted = false;
          for (let i = index - 1; i >= 0 && !inserted; i--) {
            if (orderedKeys.includes(tempKeys[i])) {
              orderedKeys.splice(orderedKeys.indexOf(tempKeys[i]) + 1, 0, key);
              inserted = true;
            }
          }
          if (!inserted) {
            orderedKeys.push(key);
          }
        }
      });
    });

    worksheet.columns = orderedKeys.map((key) => ({ header: key, key: key }));

    data.forEach((item) => {
      worksheet.addRow(item);
    });

    worksheet.columns.forEach((column) => {
      const maxLength = data.reduce((max, row) => Math.max(max, row[column.key]?.toString().length || 0), 0);
      column.width = maxLength < 10 ? 10 : maxLength;
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(blob, `${fileName}.xlsx`);
  };
  /**
   * Export an array of data as a CSV file.
   *
   * @example
   *   // Example usage:
   *   const data = [
   *     { name: "John Doe", age: 30, city: "New York" },
   *     { name: "Jane Smith", age: 25, city: "San Francisco" },
   *     { name: "Bob Johnson", age: 35, city: "Chicago" },
   *   ];
   *
   *   FileHelper.exportAsCSV(data, "users");
   *
   * @param {object[]} dataArray - The array of data to export.
   * @param {string} fileName - The name of the exported file.
   * @returns {Promise<void>} - A promise that resolves when the file is saved.
   */
  static exportAsCSV = async (dataArray, fileName) => {
    const { default: FileSaver } = await import("file-saver");
    const fileType = "text/csv;charset=utf-8;";
    const fileExtension = ".csv";

    const keysSet = new Set();

    dataArray.forEach((item) => {
      Object.keys(item).forEach((key) => {
        keysSet.add(key);
      });
    });

    let orderedKeys = [];

    dataArray.forEach((obj) => {
      let tempKeys = Object.keys(obj);
      tempKeys.forEach((key, index) => {
        if (!orderedKeys.includes(key)) {
          let inserted = false;
          for (let i = index - 1; i >= 0 && !inserted; i--) {
            if (orderedKeys.includes(tempKeys[i])) {
              orderedKeys.splice(orderedKeys.indexOf(tempKeys[i]) + 1, 0, key);
              inserted = true;
            }
          }
          if (!inserted) {
            orderedKeys.push(key);
          }
        }
      });
    });

    let csvContent = dataArray.reduce((acc, item) => {
      const row = orderedKeys.map((key) => {
        const value = item[key];
        if (value instanceof Date) {
          return value.toLocaleString();
        }
        return value;
      });

      return `${acc}${row.join(",")}\n`;
    }, "");
    csvContent = `${orderedKeys.join(",")}\n${csvContent}`;

    const blobData = new Blob([csvContent], { type: fileType });
    FileSaver.saveAs(blobData, fileName + fileExtension);
  };

  static saveAs = async (data, fileName = "", fileType = "text/plain;charset=utf-8") => {
    let saveFileName = fileName;
    const { default: FileSaver } = await import("file-saver");

    let blobData = data;
    if (!(data instanceof Blob)) {
      blobData = new Blob([data], { type: fileType });
    }
    const mimeDb = await import("~constants/mimeDb.json");

    const firstExt = mimeDb?.[blobData.type]?.extensions?.[0];
    // const blobExt = mime.extension(blobData.type);

    if (firstExt && !saveFileName.endsWith(`.${firstExt}`)) {
      saveFileName += `.${firstExt}`;
    }

    FileSaver.saveAs(blobData, saveFileName);
  };

  static getImage = async (urlOrFile) => {
    const resultImg = await new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(img);
      };
      img.onerror = (error) => {
        reject(error);
      };
      if (urlOrFile instanceof File) {
        img.src = URL.createObjectURL(urlOrFile);
      } else {
        img.src = urlOrFile;
      }
    });
    return resultImg;
  };

  static getJSZip = async () => {
    const JSZip = (await import("jszip")).default;
    return JSZip;
  };
}
