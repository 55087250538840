import { memo } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";

import styled from "@emotion/styled";

import { ReactComponent as AgentSvg } from "~assets/images/webchat/chat-agent.svg";
import { ReactComponent as BotSvg } from "~assets/images/webchat/chat-bot.svg";
import PalTooltip from "~components/mui/PalTooltip";
import DateHelper from "~helpers/DateHelper";

import { usePRChatOptionsContext } from "../../context";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  text-align: center;
  .icon-circle {
    height: 52px;
    width: 52px;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    box-shadow: 2px 2px 0px #0000000a;
    border: 1px solid #e8e8e8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &__bot {
      width: 30px;
      z-index: 2;
      height: 33px;
    }
    &__bg {
      overflow: hidden;
      /* background-color: ${(props) => props.theme.palette.primary.main}; */
      /* &::after {
        content: "";
        position: absolute;
        width: 42px;
        height: 42px;
        background-color: #fff;
        border-radius: 50%;
        z-index: 1;
      } */
    }
    &__logo {
      /* width: 36px;
      max-height: 36px; */
      width: 52px;
      height: 52px;
      max-width: 52px;
      max-height: 52px;
      z-index: 2;
    }
    &__agent {
      width: 35px;
      height: 33px;
    }
  }
  .label {
    font: normal normal bold 18px/20px Museo Sans !important;
    letter-spacing: 0px;
    color: ${(props) => props.theme.palette.secondary.dark};
    &-note {
      font-size: 14px !important;
      font-weight: 300 !important;
    }
  }
  .empty-note {
    //italic
    font-style: italic !important;
  }
`;
const JoinedMessage = memo(
  function JoinedMessage({ isTransfer, note, isAgent, name, messageTime, unformatted }) {
    const { t } = useTranslation();
    const { botJoinedLogo } = usePRChatOptionsContext() || {};

    if (unformatted) {
      return <span>{t("chatbot.chatSceneTexts.joinedChat").format(name)}</span>;
    }
    const transferText = isTransfer
      ? t("chatbot.chatSceneTexts.transferedChat").format(name)
      : t("chatbot.chatSceneTexts.joinedChat").format(name);
    const isBotLogoVisible = !isAgent && !!botJoinedLogo;
    return (
      <StyledDiv>
        <div
          className={classNames("icon-circle", {
            "icon-circle__bg": isBotLogoVisible,
          })}
        >
          {isAgent ? (
            <AgentSvg className="icon-circle__agent" />
          ) : botJoinedLogo ? (
            <img className="icon-circle__logo" src={botJoinedLogo} />
          ) : (
            <BotSvg className="icon-circle__bot" />
          )}
          {/* {isAgent ? <AgentSvg className="icon-circle__agent" /> : <BotSvg className="icon-circle__bot" />} */}
        </div>
        <div className="label">
          <PalTooltip
            placement="bottom"
            title={messageTime ? DateHelper.getDateTimeLocal(messageTime).format("HH:mm") : ""}
          >
            <span>{transferText}</span>
          </PalTooltip>
        </div>
        {isTransfer && (
          <div
            className={classNames("label label-note", {
              "empty-note": !note,
            })}
          >
            {note || "- Empty -"}
          </div>
        )}
      </StyledDiv>
    );
  },
  (prevProps, nextProps) => {
    const { messageTime: prevMessageTime, ...prevRest } = prevProps;
    const { messageTime: nextMessageTime, ...nextRest } = nextProps;

    const areRestEqual = Object.keys(prevRest).every((key) => prevRest[key] === nextRest[key]);
    const areMessageTimeEqual = DateHelper.getDateTime(prevMessageTime).isSame(DateHelper.getDateTime(nextMessageTime));
    return areRestEqual && areMessageTimeEqual;
  }
);

export default JoinedMessage;
